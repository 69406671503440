import React from "react";
import { useTranslation } from "react-i18next";

export default function Nogame() {

	const { t } = useTranslation('translations');

    return (
        <div className="nogame-container">
            <span>{t("Login to play")}</span>
        </div>
    );
}
