import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ThemeContext } from "../../ThemeContext";
import { useTranslation } from "react-i18next";

function Wallet(props) {

	const { t } = useTranslation('translations');

	const { balance, promoBalance, fctclose } = props;
	const [cuuTheme, setcurrTheme] = useState('dark')

	const theme = useContext(ThemeContext);
	const darkMode = theme.state.darkMode;

	useEffect(() => {
		if (darkMode) {
			setcurrTheme('dark');
		} else {
			setcurrTheme('light');
		}

	}, [darkMode]);

	return (
		<div className={"header-wallet__balance container " + cuuTheme}>
			<div className="header-wallet__block">
				<span>{t("Balance")}</span>
				<h3>{balance} {process.env.REACT_APP_CURRENCY} </h3>
			</div>
			<div className="header-wallet__block">
				<span>{t("Promotion Balance")}</span>
				<h3>{promoBalance} {process.env.REACT_APP_CURRENCY} </h3>
			</div>
			<div className="header-wallet__btnContainer">
				<Link className="header-wallet__btn deposit" to="/account?page=deposit">
					{t("Deposit")}
				</Link>
				<Link className="header-wallet__btn cashout" to="/account?page=cashout">
					{t("Cashout")}
				</Link>
				<FontAwesomeIcon className="header-wallet__btn close" icon={faXmark} onClick={fctclose} />
			</div>
		</div>
	);
}

export default Wallet;
