import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import mojasport from "../../../assets/images/mojasport-logo-banner.png";
import { Link } from "react-router-dom";
import gbet from "../../../assets/images/banners/gbet-logo.png";
import chance from "../../../assets/images/menu-chance.png";
import AfricanExplorer from "../../../assets/images/ae-logo.png";
import AfricanFruit from "../../../assets/images/af-logo.png";
import SpinAndWin from "../../../assets/images/sw-logo.png";
import Lucky7 from "../../../assets/images/l7-logo.png";
import { withTranslation } from "react-i18next";

class HomepageSlider extends Component {

	render() {
		const { t } = this.props;
		const settings = {
			dots: false,
			infinite: true,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1, autoplay: false,
		};
		return (
			<section className="hp-slider">
				<Carousel autoPlay={true} showArrows={false} showStatus={false} swipeable={true}>
					<div className="hp-slider__slide">
						<Link to="/mojasports" className="hp-slider__link">
							<div className="slider-sports">
								<div className="slider-sports__container">
									<img src={mojasport} className="mojasportslogo" />
									<div className="hp-slider__label white">{t("Play Now")}</div>
								</div>
							</div>
						</Link>
					</div>

					<div className="hp-slider__slide">
						<Link to="/virtuals" className="hp-slider__link">
							<div className="slider-sports gbet">
								<div className="slider-sports__container">
									{/* <img src={gbet} className="gbetlogo" /> */}
									<h2 className="gbetheader">{t("Virtuals")}</h2>
									<div className="hp-slider__label white">{t("Play Now")}</div>
								</div>
							</div>
						</Link>
					</div>
					<div className="hp-slider__slide">
						<div className="slider-loto">
							<div className="middle-align-container">
								<div className="loto-container">
									<Link className="loto-link instalink" to="/instaloto">
										Instalotto
									</Link>
									<Link className="loto-link pick3link" to="/pick3">
										Pick 3
									</Link>
									<Link className="loto-link cash5link" to="/cash5">
										Cash 5
									</Link>
								</div>
								<div className="loto-paragraph">
									<p className="loto-paragraph-head">{t("Instant lotto games")}</p>
									<span className="loto-paragraph-sub">{t("Play and win big")}</span>
								</div>
							</div>
						</div>
					</div>
					<div className="hp-slider__slide">
						<div className="slider-chance">
							<img src={chance} className="chance-logo" />
							<h2 className="chance-header">{t("Chance Games")}</h2>
							<div className="chance-container">

								<div className="chance-container-game">
									<Link className="chance-container-link" to="/africanExplorer">
										<img
											src={AfricanExplorer}
											className="chance-container-image ae"
										/>
										<div className="chance-container-title ">
											{t("African Explorer")}
										</div>
									</Link>
								</div>
								<div className="chance-container-game">
									<Link className="chance-container-link" to="/africanFruit">
										<img
											src={AfricanFruit}
											className="chance-container-image af"
										/>
										<div className="chance-container-title ">
											{t("Africa Fruits")}
										</div>
									</Link>
								</div>
								<div className="chance-container-game">
									<Link className="chance-container-link" to="/spinWin">
										<img
											src={SpinAndWin}
											className="chance-container-image sw"
										/>
										<div className="chance-container-title ">
											{t("Spin & Win")}
										</div>
									</Link>
								</div>
								<div className="chance-container-game">
									<Link className="chance-container-link" to="/lucky7">
										<img
											src={Lucky7}
											className="chance-container-image l7"
										/>
										<div className="chance-container-title ">
											{t("Lucky 7")}
										</div>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</Carousel>
			</section>
		);
	}
}

export default (withTranslation("translations")(HomepageSlider));