import React, { useEffect } from 'react';
import Sidemenu from '../../components/Sidemenu/Sidemenu';
import Footer from '../../components/Footer/Footer';
import HeaderOld from '../../components/Header/Header-old';
import Play from './Play';
import HowToPlay from './Howtoplay';
import Howtowin from "./Howtowin";
import Tickets from '../../components/Tickets/Tickets';
import Funplay from './Funplay';
import { useSearchParams } from 'react-router-dom';
import ReactGA from "react-ga";
const gameType = 'scratch';


const pages = [{ title: 'play', name: 'play', content: <Play /> },
{ title: 'play for fun', name: 'play for fun', content: <Funplay /> },
{ title: 'how to play', name: 'howtoplay', content: <HowToPlay /> },
{ title: 'how to win', name: 'howtowin', content: <Howtowin /> },
{ title: 'purchases', name: 'purchases', content: <Tickets gameType={gameType} gameId={6} pageName="purchases" /> },
{ title: 'my winnings', name: 'winnings', content: <Tickets gameType={gameType} gameId={6} pageName="winnings" /> },];


export default function AfricanExplorer(props) {
    useEffect(() => {
        ReactGA.pageview('/africanExplorer');
    }, [])

    let page;
    let [searchParams, setSearchParams] = useSearchParams();
    let tab = searchParams.get('page')
    if (tab) {
        switch (tab) {
            case 'play':
                page = <Play />;
                break;
            case 'funplay':
                page = <Funplay />;
                break;
            case 'howtoplay':
                page = <HowToPlay />;
                break;
            case 'howtowin':
                page = <Howtowin />;
                break;
            case 'purchases':
                page = <Tickets gameType={gameType} pageName="purchases" />;
                break;
            case 'winnings':
                page = <Tickets gameType={gameType} pageName="winnings" />;
                break;
            default:
                page = <Funplay />;
        }
    }
    return (
        <>
            <HeaderOld />
            <div className="flip-page">
                <Sidemenu page={page} pages={pages} gameType={gameType} />
            </div>
            <Footer />
        </>
    );
}


