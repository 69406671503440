import React, { useEffect, useState, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import HeaderOld from '../../components/Header/Header-old';
import Footer from '../../components/Footer/Footer';
import userImageEdit from '../../assets/images/user-image-edit.png';
import Profile from '../../components/Profile/Profile';
import Transactions from '../../components/Transactions/Transactions';
import Tickets from '../../components/Tickets/Tickets';
import Deposit from '../../components/Deposit/Deposit';
import Cashout from '../../components/Cashout/Cashout';
import PromoWallet from '../../components/PromoWallet/PromoWallet';
import * as actions from "../../store/actions/index";
import { userApi } from '../../Api'
import axios from 'axios';
import Nogame from '../../components/Nogame/Nogame';
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";

export default function AccountDashboard() {

    const { t } = useTranslation('translations');

    const isAuthenticated = useSelector(state => state.auth.token);
    const [activeId, setactiveid] = useState(['active', '', '', '', '', '']);

    let [searchParams, setSearchParams] = useSearchParams();
    let tab = searchParams.get('page')

    const dispatch = useDispatch();

    const inputRef = useRef(null);
    const avatar = useSelector(state => state.auth.avatar);
    const [userInfo, setUserInfo] = useState('');
    const [userPage, setUserPage] = useState('');
    const [userImage, setUserImage] = useState(null);
    const [userImageBase, setUserImageBase] = useState(null);

    useEffect(() => {
        setUserImage(localStorage.getItem('avatar'));
    }, [avatar]);

    useEffect(async () => {
        const response = await axios(userApi(localStorage.getItem('token')));
        setUserInfo(response.data.user);
        localStorage.setItem('operator', response.data.user.mobileNumber.operator)
        setUserImage(localStorage.getItem('avatar'));
        ReactGA.pageview('/account');
    }, []);

    useEffect(() => {
        if (userPage == '' && userInfo != '') {
            setUserPage(<Profile userInfo={userInfo} />)
        }
    }, [userInfo]);

    function logout() {
        dispatch(actions.logout());
    }

    function openImageUpload() {
        inputRef.current.click();
    }

    function resetHeader() {
        activeId.map((value, id) => {
            activeId[id] = '';
        })
    }


    // const uploadImage = (evt) => {
    //     console.log("Uploading");
    // var reader = new FileReader();
    // var file = evt.target.files[0];
    //
    // reader.onload = function (upload) {
    //     let imageUrl = upload.target.result;
    //     let strImage = imageUrl.replace(/^data:image\/[a-z]+;base64,/, "");
    //
    //     console.log('imagfe',imageUrl);
    //     setUserImage(imageUrl);
    //     setUserImageBase(strImage);
    // };
    // console.log('imagfe Base 1111',userImageBase);
    //
    // reader.readAsDataURL(file);
    // setTimeout(function () {
    //     dispatch(actions.changeAvatar(userImage));
    // }, 1000);


    const uploadImage = async (e) => {
        const file = e.target.files[0];
        convertToBase64(file);

    };
    const onLoad = (fileString) => {
        let image = fileString.replace(/^data:image\/[a-z]+;base64,/, "");
        setUserImageBase(image);
        dispatch(actions.changeAvatar(image));
    }


    const convertToBase64 = (file) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            onLoad(reader.result)
        }
    };
    function customLink() {
        if (tab) {
            if (isAuthenticated) {
                switch (tab) {
                    case 'profile':
                        userInfo ? setUserPage(<Profile userInfo={userInfo} />) : setUserPage(<Nogame />);
                        setactiveid(['active', '', '', '', '', '']);
                        break;
                    case 'transactions':
                        setUserPage(<Transactions />);
                        setactiveid(['', 'active', '', '', '', '']);
                        break;
                    case 'tickets':
                        setUserPage(<Tickets pageName="tickets" />);
                        setactiveid(['', '', 'active', '', '', '']);
                        break;
                    case 'deposit':
                        userInfo ? setUserPage(<Deposit operator={userInfo.mobileNumber.operator} />) : setUserPage(<Nogame />);
                        setactiveid(['', '', '', 'active', '', '']);
                        break;
                    case 'cashout':
                        setUserPage(<Cashout />)
                        setactiveid(['', '', '', '', 'active', '']);
                        break;
                    case 'promowallet':
                        setUserPage(<PromoWallet />)
                        setactiveid(['', '', '', '', '', 'active']);
                        break;
                    default:
                        setUserPage(<Profile userInfo={userInfo} />);
                        setactiveid(['active', '', '', '', '', '']);
                }
            }
            else {
                setUserPage(<Nogame />);
            }
        }
    }

    useEffect(() => {
        customLink();
    }, [tab, userInfo]);

    return (
        <div className='account-dashboard'>
            <HeaderOld />

            <div className='account-container'>
                <div className='account-dashboard__userInfo'>
                    <div className='account-dashboard__userAccount-container'>
                        <h1 className='account-dashboard__userImageTitle'>{t("Account")}</h1>
                        <div className='account-dashboard__userImage-container'>
                            <div className='account-dashboard__userImage' onClick={openImageUpload}>
                                {userImage ? <img className='account-dashboard__userImageUploaded' src={userImage} /> : ''}
                                <input type="file" name="fileToUpload" id="fileToUpload" ref={inputRef} style={{ display: "none" }} onChange={uploadImage} />
                                <img className='account-dashboard__userImage__editUserIcon' src={userImageEdit} />
                            </div>
                        </div>
                        <div className='account-dashboard__userAccount'>
                            <span>{localStorage.getItem('msisdn')}</span>
                        </div>
                    </div>
                    <div className='account-dashboard__userActions'>
                        <Link to="/resetpassword" className="account-dashboard__button">{t("Edit Password")}</Link>
                        <Link to="/" className="account-dashboard__button" onClick={logout}>{t("Logout")}</Link>
                    </div>
                </div>

                <ul className='account-dashboard__navbar'>
                    <li className={'account-dashboard__navbarItem ' + activeId[0]} onClick={() => { resetHeader(); activeId[0] = 'active'; setUserPage(<Profile userInfo={userInfo} />) }}>{t("Profile")}</li>
                    <li className={'account-dashboard__navbarItem ' + activeId[1]} onClick={() => { resetHeader(); activeId[1] = 'active'; setUserPage(<Transactions />) }}>{t("Transactions")}</li>
                    <li className={'account-dashboard__navbarItem ' + activeId[2]} onClick={() => { resetHeader(); activeId[2] = 'active'; setUserPage(<Tickets pageName="tickets" />) }}>{t("Tickets")}</li>
                    <li className={'account-dashboard__navbarItem ' + activeId[3]} onClick={() => { resetHeader(); activeId[3] = 'active'; setUserPage(<Deposit operator={userInfo.mobileNumber.operator} />) }}>{t("Deposit")}</li>
                    <li className={'account-dashboard__navbarItem ' + activeId[4]} onClick={() => { resetHeader(); activeId[4] = 'active'; setUserPage(<Cashout />) }}>{t("Cashout")}</li>
                    <li className={'account-dashboard__navbarItem ' + activeId[5]} onClick={() => { resetHeader(); activeId[5] = 'active'; setUserPage(<PromoWallet />) }}>{t("Promo Wallet")}</li>
                </ul>

                <div className="account-dashboard__userPages">
                    {userPage}
                </div>

            </div>

            <Footer />
        </div>
    );
}