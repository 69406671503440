import React from 'react';
import { useTranslation } from "react-i18next";

export default function Balanceamount() {

	const { t, i18n } = useTranslation('translations');
	let userbalanceaccount = localStorage.getItem('balance') + " TZ";

	return (
		<div className="balance-container">
			<span className="balance-container-text">{t('Available Balance')}</span>
			<div className="balance-container-amount">{userbalanceaccount}</div>
		</div>
	)
}
