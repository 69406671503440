import i18n from './i18n';

let wallet = (token, lang) => `/wallet-transaction-api/tokens/${token}/wallets?language=${lang}`;
let walletTransactionApi = (token, pageSize, pageNb) => `/wallet-transaction-api/tokens/${token}/transactions?PageSize=${pageSize}&PageNumber=${pageNb}&Language=${i18n.language}`;
let walletticketsApi = (token, pageSize, pageNb) => `/wallet-transaction-api/tokens/${token}/tickets?PageSize=${pageSize}&PageNumber=${pageNb}&Language=${i18n.language}`;
let walletTransactionAmountApi = `/wallet-transaction-api/amounts?language=${i18n.language}`;
let operatorsApi = `/user-api/operators?language=${i18n.language}`;
let registerApi = `/user-api/users?language=${i18n.language}`;
let userApi = (token) => `/user-api/tokens/${token}?language=${i18n.language}`;
let userAvatarApi = (token) => `/user-api/tokens/${token}/avatars?language=${i18n.language}`;
let verifyMsisdnApi = (token) => `/user-api/tokens/${token}/msisdns?language=${i18n.language}`;
let resetPasswordApi = (token) => `/user-api/tokens/${token}/passwords?language=${i18n.language}`;
let changeMsisdnApi = '/userapi/changemsisdn';//might be incorrect - to check
let promoWalletApi = (token) => `/promo-wallet-transaction-api/tokens/${token}/wallets?language=${i18n.language}`;
let ticketsApi = (token, type, pageSize, pageNb) => `/scratch-api/tokens/${token}/tickets?Type=${type}&pageSize=${pageSize}&pageNumber=${pageNb}&Language=${i18n.language}`;
let scratchticketapi = (token, type, isWinning, pageNb, pageSize) => `/scratch-api/tokens/${token}/tickets?Type=${type}&IsWinning=${isWinning}&PageNumber=${pageNb}&PageSize=${pageSize}&Language=${i18n.language}`;
let scratchTypesApi = (gameType, isActive, pageSize, pageNb) => `/scratch-api/types/${gameType}?IsActive=${isActive}&pageSize=${pageSize}&pageNumber=${pageNb}&Language=${i18n.language}`;
let hotNumbersApi = `/pick-three-api/hot-numbers?language=${i18n.language}`;
let cash5PurchaseApi = (wallet) => `/mega-x-api/tickets?IsWallet=${wallet}&Language=${i18n.language}`;
let pick3PurchaseApi = (wallet) => `/pick-three-api/tickets?isWallet=${wallet}&Language=${i18n.language}`;
let cash5TicketsApi = (token, pageSize, pageNb, isWinning) => `/mega-x-api/tokens/${token}/tickets?pageSize=${pageSize}&pageNumber=${pageNb}&isWinning=${isWinning}&Language=${i18n.language}`;
let pick3TicketsApi = (token, pageSize, pageNb, isWinning) => `/pick-three-api/tokens/${token}/tickets?pageSize=${pageSize}&pageNumber=${pageNb}&isWinning=${isWinning}&Language=${i18n.language}`;
let instalottoTicketsApi = (token, pageSize, pageNb, isWinning) => `/instalotto-api/tokens/${token}/tickets?pageSize=${pageSize}&pageNumber=${pageNb}&isWinning=${isWinning}&Language=${i18n.language}`;
let pick3Draws = (pageSize, pageNb, isComplete) => `/pick-three-api/draws?pageNumber=${pageNb}&pageSize=${pageSize}&isComplete=${isComplete}&Language=${i18n.language}`;
let cash5Draws = (pageSize, pageNb, isComplete) => `/mega-x-api/draws?pageNumber=${pageNb}&pageSize=${pageSize}&isComplete=${isComplete}&Language=${i18n.language}`;
let instalottoDraws = (isComplete, pageNb, pageSize) => `/instalotto-api/draws?IsComplete=${isComplete}&PageNumber=${pageNb}&PageSize=${pageSize}&Language=${i18n.language}`;
let instalottoWinners = (isWinning, pageNb, pageSize) => `/instalotto-api/tickets?IsWinning=${isWinning}&PageNumber=${pageNb}&PageSize=${pageSize}&Language=${i18n.language}`;
let contactUsApi = `/web-api/contact-us-messages?language=${i18n.language}`;
// let TermsAndCondition='/web-api/pagetypes/privacy_policy?PageNumber=1&PageSize=1&Language=fr';
let pages_user = (pagetype) => `/web-api/pagetypes/${pagetype}?PageNumber=1&PageSize=1&Language=${i18n.language}`;
let globalBetApiToken = (token) => `/globalbet-api/hosts?token=${token}&language=${i18n.language}`;
let globalBetApi = `/globalbet-api/hosts?language=${i18n.language}`;
let getUserStats = (token, duration) => `/user-api/custominfo?token=${token}&language=${i18n.language}&days=${duration}`;
let resendVerificationCode = (token, msisdn) => `/user-api/tokens/${token}/msisdns/${msisdn}/codes?language=${i18n.language}`;
let getCash5Prices = (nbOfPicks, amount, numbers, isComb) => `/mega-x-api/prices?NumberOfPicks=${nbOfPicks}&amount=${amount}&Numbers=${numbers}&IsCombination=${isComb}&Language=${i18n.language}`;

export {
    wallet, hotNumbersApi, operatorsApi, userApi, promoWalletApi, ticketsApi, pick3TicketsApi, cash5TicketsApi, instalottoTicketsApi, walletTransactionApi,
    walletTransactionAmountApi, userAvatarApi, scratchTypesApi, cash5PurchaseApi, pick3PurchaseApi, registerApi, verifyMsisdnApi, changeMsisdnApi, resetPasswordApi,
    contactUsApi, pick3Draws, cash5Draws, instalottoDraws, instalottoWinners, pages_user, globalBetApiToken, globalBetApi, getUserStats, scratchticketapi,
    walletticketsApi, getCash5Prices, resendVerificationCode
};
