import React, { useEffect, useState } from 'react';
import axios from 'axios';
import * as actions from '../../store/actions/index';
import { connect } from 'react-redux';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { operatorsApi, resendVerificationCode } from '../../Api';
import { useTranslation } from "react-i18next";

function Register(props) {

    const { t } = useTranslation('translations');

    const { verify, loadingReg, hpRedirect, isAuthenticated, loading, verloading, regerror, vererror } = props;
    console.log(verify);
    const [verification, setVerification] = useState('closedVer');
    const [mobile, setMobile] = useState('');
    const [password, setPassword] = useState('');
    const [confirmpassword, setConfirmpassword] = useState('');
    const [operator, setOperator] = useState('');
    const [operatorDropdown, setOperatorDropdown] = useState(null);
    const [age, setAge] = useState(false);
    const [code, setCode] = useState('');

    const [operators, setOperators] = useState([]);
    const [operatorsVisibility, setOperatorsVisibility] = useState('hidden');
    const [visibility, setVisibility] = useState('');
    const [resendMessage, setResendMessage] = useState('');
    const [resendLoading, setResendLoading] = useState(false);

    // Gets Operators
    useEffect(async () => {
        const response = await axios(operatorsApi);
        setOperators(response.data.operators);
    }, []);

    function resendCode(event) {
        event.preventDefault();
        console.log('resend');
        setResendLoading(true);
        let msisdn = localStorage.getItem('unverified_msisdn');
        let token = localStorage.getItem('unverified_token');
        axios.put(resendVerificationCode(token, msisdn))
            .then(response => {
                setResendLoading(false);
                setResendMessage(<div className="alert success">{response.data.message}</div>)
            })
            .catch(err => {

                setResendLoading(false);
                setResendMessage(<div className="alert warning">{err.message}</div>)

            });
    };
    function onNumberChange(value) {
        setMobile(value);
        let valid = false;
        if (value.length >= 2) {
            operators.map((operator, key) => {
                operator.prefix.map((prefix) => {
                    if (value.substr(0, 2) == prefix) {
                        valid = true;
                        setOperator(operator.name);
                        setVisibility('visible');
                    }
                })
            })
        }
        else {
            setVisibility('');
            setOperatorsVisibility('hidden');
        }
        console.log(value.substr(0, 2));
    }

    function showOperators() {
        if (operatorsVisibility == 'hidden') {
            setOperatorsVisibility('visible');
        }
        else {
            setOperatorsVisibility('hidden');
        }
    }

    const options = [
        'TIGO', 'VODACOM', 'AIRTEL', 'HALOTEL'
    ];
    function selectAge() {
        setAge(!age);

    }
    const defaultOption = options[0];
    function submitRegistration(event) {
        event.preventDefault();
        props.onRegister(
            mobile,
            password,
            confirmpassword,
            operatorDropdown ? operatorDropdown : operator,
            age
        );
    };

    function submitVerification(event) {
        event.preventDefault();
        props.onVerify(code);
    }
    useEffect(() => {
        if (hpRedirect || isAuthenticated) {
            props.toggleRegister('', 'closed');
            setVerification('closedVer');
        }
    }, [hpRedirect, isAuthenticated]);

    useEffect(() => {
        if (verify && !loadingReg) {
            setVerification('openVer')
        }
    }, [verify]);

    return (
        <div className={"register-popup " + props.popup + " " + verification}>
            <div className="register-popup__header">
                <button className="register-popup__close" onClick={() => props.toggleRegister('', 'closed')}>x</button>
                <h2>{t("Sign Up")}</h2>
            </div>
            <div className="register-popup__body">
                <div className="register-popup__steps">
                    <div className="register-popup__step">
                        <span>1</span>
                        <label>{t("Information")}</label>
                    </div>
                    <div className="register-popup__step">
                        <span>2</span>
                        <label>{t("Verification")}</label>
                    </div>
                </div>
                <div className="register-popup__form register">
                    <form onSubmit={submitRegistration}>
                        <div className="register-popup__formblock">
                            <label>{t("Mobile")}*</label>
                            <div className="register-popup__phoneblock">
                                <span className="register-popup__code">+255</span>
                                <input type="number" className="register-popup__field" required="required" onChange={e => onNumberChange(e.target.value)} />
                            </div>
                            <div className={'register-popup__operator ' + visibility}>
                                <span className="register-popup__operator-label">{t("")}Operator:</span>
                                <span className="register-popup__operator-text">{operator}</span>
                                <a href='#' className="register-popup__operator-change" onClick={showOperators}>{t("Change")}</a>
                            </div>
                        </div>
                        <div className={"register-popup__formblock " + operatorsVisibility}>
                            <label>{t("Operator")}*</label>
                            <Dropdown options={options} className="register-popup__dropdown" onChange={e => setOperatorDropdown(e.value)} placeholder="Select an option" />
                        </div>
                        <div className="register-popup__formblock">
                            <label>{t("Password")}*</label>
                            <input type="password" className="register-popup__field" required="required" onChange={e => setPassword(e.target.value)} />
                        </div>
                        <div className="register-popup__formblock">
                            <label>{t("Confirm Password")}*</label>
                            <input type="password" className="register-popup__field" required="required" onChange={e => setConfirmpassword(e.target.value)} />
                        </div>
                        <div className="register-popup__formblock">
                            <div className="register-popup__checkcontainer">
                                <label htmlFor="todo" data-content="18 and aove">{t("18 and Above Tanzanian Resident and I have read and agree to the full Terms and Conditions")}.*</label>
                                <input type="checkbox" id="todo" name="age" required="required" onChange={selectAge} />
                            </div>
                        </div>
                        <div className="register-popup__formblock">
                            <input type="submit" value={t("Submit")} className="register-popup__btn" />
                        </div>
                        <br />
                        {loading ? <div className="loading-container">
                            <FontAwesomeIcon className="icon" icon={faSpinner} spin />
                        </div> : null}
                    </form>
                    {regerror ? <div className="alert warning">{regerror}</div> : null}
                </div>
                <div className="register-popup__form verify">

                    <form onSubmit={submitVerification}>
                        <div className="register-popup__formblock">
                            <label>{t("Pin")}*</label>
                            <input type="number" onChange={e => setCode(e.target.value)} className="register-popup__field" />
                        </div>

                        <div className="register-popup__formblock">
                            <div className="resendpin">
                                <a href="#" className="register-popup__link" onClick={resendCode}>{t("Resend pin")}</a>
                                {resendLoading ? <div className="loading-container">
                                    <FontAwesomeIcon className="icon" icon={faSpinner} spin />
                                </div> : null}
                                {resendMessage ? resendMessage : null}
                            </div>
                            <input type="submit" value="Verify" className="register-popup__btn" />


                        </div>
                        {verloading ? <div className="loading-container">
                            <FontAwesomeIcon className="icon" icon={faSpinner} spin />
                        </div> : null}
                    </form>
                </div>
                {vererror ? <div className="alert warning">{vererror}</div> : null}
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        loading: state.auth.regloading,
        regerror: state.auth.regerror,
        verify: state.auth.verify,
        isAuthenticated: state.auth.token !== null,
        verloading: state.auth.verloading,
        hpRedirect: state.auth.hpRedirect,
        vererror: state.auth.vererror,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onRegister: (
            mobile,
            password,
            confirmpassword,
            operator,
            age,
        ) => dispatch(actions.register(
            mobile,
            password,
            confirmpassword,
            operator,
            age)),
        onVerify: (code) => dispatch(actions.verifyMsisdn(code)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Register));