import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function Typeofplay(props) {

	const { t } = useTranslation('translations');

	const [textObj, setTextObj] = useState([]);
	useEffect(() => {
		let array = [];
		Object.values(props.text).forEach((val) => {
			array.push(val);
			setTextObj(array);
		});
	}, [props.text]);

	return (
		<>
			<div className="container-menuplay">
				<a
					className="container-menuplay-backbutton"
					href="# "
					onClick={() => props.handleClick(null)}
				>
					{t("Back")}
				</a>
				<ul className="container-menuplay-list">
					{textObj.map((data, index) => {
						return (
							<li className="list-info">
								{/* <i className="list-info-tag">{index + 1}</i> */}
								<span className="list-info-wrld">{t(data.string)}</span>
							</li>
						);
					})}
				</ul>
			</div>
		</>
	);
}
