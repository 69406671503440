import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import HeaderOld from "../../components/Header/Header-old";
import AfricanExplorer from "../../assets/images/ae-logo.png";
import AfricanFruit from "../../assets/images/af-logo.png";
import SpinAndWin from "../../assets/images/sw-logo.png";
import Lucky7 from "../../assets/images/l7-logo.png";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";

export default function ChanceGames() {

    const { t } = useTranslation('translations');

    useEffect(() => {
        ReactGA.pageview('/chancegames');
    }, [])

    return (
        <div className="chance-games">
            <HeaderOld />

            <div className="chance-games-container">
                <div className="container">
                    <div className="chance-games__title">
                        <span className="chance-games__title-label">{t("Chance Games")}</span>
                    </div>
                    <div className="chance-games__games">
                        <div className="chance-games__game">
                            <Link className="chance-games__game-link" to="/africanExplorer">
                                <img src={AfricanExplorer} className="chance-games__game-logo ae" />
                                <div className="chance-games__game-title">
                                    {t("African Explorer")}
                                </div>
                            </Link>
                        </div>
                        <div className="chance-games__game">
                            <Link className="chance-games__game-link" to="/africanFruit">
                                <img src={AfricanFruit} className="chance-games__game-logo af" />
                                <div className="chance-games__game-title">
                                    {t("African Fruit")}
                                </div>
                            </Link>
                        </div>
                        <div className="chance-games__game">
                            <Link className="chance-games__game-link" to="/spinWin">
                                <img src={SpinAndWin} className="chance-games__game-logo sw" />
                                <div className="chance-games__game-title">
                                    {t("Spin & Win")}
                                </div>
                            </Link>
                        </div>
                        <div className="chance-games__game">
                            <Link className="chance-games__game-link" to="/lucky7">
                                <img src={Lucky7} className="chance-games__game-logo l7" />
                                <div className="chance-games__game-title">
                                    {t("Lucky 7")}
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}