import React, { useState } from "react";
import Confetti from 'react-confetti';
import swLogo from "../../assets/images/sw-logo.png";
import wheel from '../../assets/images/wheel.png';
import { useTranslation } from "react-i18next";

const SPoptions = [
    { value: 'SP1', prize: '1000.00' },
    { value: 'SP2', prize: '1,900.00' },
    { value: 'SP3', prize: '2,800.00' },
    { value: 'SP4', prize: '3,700.00' },
    { value: 'SP5', prize: '4,600.00' },
    { value: 'losing1', prize: '0' },
    { value: 'losing2', prize: '0' },
    { value: 'losing3', prize: '0' },];

let option;
let random;
let previousRandom;

export default function DemoPlay() {

    const { t } = useTranslation('translations');

    const [winning, setWinning] = useState(false);
    const [winningValue, setWinningValue] = useState(0);
    // const [prize, setPrize] = useState(null);
    const [value, setValue] = useState('');
    const [disable, setDisable] = useState(null);
    const [winningExtra, setWinningExtra] = useState(false);
    const [played, setPlayed] = useState(false);

    const getTicket = () => {
        while (random === previousRandom) {
            random = Math.floor(Math.random() * SPoptions.length);
        }
        option = SPoptions[random];
        previousRandom = random;

        setValue(option.value);
        if (option.value == winningValue) {
            setWinningExtra(!winningExtra)
        }
        if (option.prize != '0') {
            setWinning(true);
            setWinningValue(option.value);
            // setPrize(option.prize);
        }
        else {
            setWinning(false);
            setWinningValue('losing');
            // setPrize(null);
        }
    }

    const spinWheel = (event) => {
        event.preventDefault();

        if (winningValue == 'losing') {
            setValue('random')
        } else {
            setValue(winningValue)
        }
        setDisable('disable')

        setTimeout(() => (setDisable(null), setPlayed(true)), 4000);
        getTicket();
    }

    let extra = '';
    if (winningExtra) {
        extra = 'extra';
    }
    return (
        <div className="flip spinwin">
            <h3 className="flip__subtitle">{t("Play for fun")}</h3>
            <div className="flip__logo">
                <img src={swLogo} alt="Spin and win logo" />
            </div>
            <>
                <h3 className="flip__subtitle">{t("Get lucky and hit the diamond to win big")}!</h3>
                <div className="flip__container">
                    <div className="wheel-container">
                        <div className="weel-circle">
                            <img src={wheel} className={value + ' ' + extra} />
                        </div>
                        <div className="weel-base"></div>
                    </div>
                    {played && !disable && winning ?
                        <>
                            <div className="flip__winnings">
                                <label className="flip__winningLabel demo">{t("You won")}</label>
                            </div>
                            <Confetti />
                        </>
                        : played && !disable && !winning ?
                            <div className="flip__losing">
                                <label className="flip__losingLabel">{t("Try your luck the next time")}!</label>
                            </div>
                            : ''
                    }
                    <div className="btn-container">
                        <button className="flip__btn" onClick={spinWheel}>{t("Play")}</button>
                    </div>
                </div>
                <div className="flip__ticketsInfo">
                    <a className="flip__btnreal" href="/spinwin">{t("Play for real")}</a>
                </div>
            </>
        </div>
    );
}