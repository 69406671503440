import React, { useState, useEffect } from "react";
import Datafile from "../../data";
import Wincriteria from "../../components/Typeofplay/Wincriteria";
import { useTranslation } from "react-i18next";

export default function HowToPlay() {

	const { t } = useTranslation('translations');

	const [text, setText] = useState({});

	useEffect(() => {
		Object.keys(Datafile.pick3howtowin).forEach(function (key) {
			var value = Datafile.pick3howtowin[key];
			setText(value)
		});
	}, [])


	return (
		<>
			<div className="howtowin-container">
				<div className="howtowin-container-title">
					{t("How to win")}!
				</div>
				<div className="howtowin-container-subtitle">
					{t("You get more than one chance to win in Mojaspesho")}!
				</div>
			</div>
			<Wincriteria text={text} />
		</>
	);
}