import React, { useEffect, useState } from "react";
import africanlogo from '../../assets/images/africa-fruit.png';
import Flippy, { FrontSide, BackSide } from 'react-flippy';
import * as actions from "../../store/actions";
import { connect } from 'react-redux';
import { ticketsApi } from '../../Api';
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import Confetti from 'react-confetti';
import HowToWinBox from "../../components/HowToWinBox/HowToWinBox";
import { usePrevious } from "../../components/Functions";
import Funplay from "./Funplay";
import { useTranslation } from "react-i18next";

function Play(props) {

    const { t } = useTranslation('translations');

    const [flipped, setFlipped] = useState([false, false, false, false, false, false, false, false, false]);
    const [unplayedTickets, setUnplayedTickets] = useState(props.unplayedTickets);
    const [loading, setLoading] = useState(false);
    const [buyLoading, setBuyLoading] = useState(false);
    const [ticketId, setTicketId] = useState(null);
    const [ticketValues, setTicketValues] = useState([]);
    const [serialNumber, setSerialNumber] = useState(null);
    const [winning, setWinning] = useState(false);
    const [prize, setPrize] = useState(null);
    const [allflipped, setAllflipped] = useState(false);
    const [tcktNbr, setTcktNbr] = useState(0);
    const [ticketMessage, setTicketMessage] = useState(null);
    const prevUnplayed = usePrevious(props.unplayedTickets);
    const gameid = 2;


    const addmapfct = () => {
        setTcktNbr(tcktNbr + 1);
    }
    const reducemapfct = () => {
        if (tcktNbr === 0)
            return;
        else
            setTcktNbr(tcktNbr - 1);
    }

    useEffect(() => {
        props.onGetScratch(2);
        getTicket();
    }, []);

    useEffect(() => {
        return (props.onUpdateMessageaf(null, 0), setTicketMessage(null))
    }, []);

    const getTicket = () => {
        let url = ticketsApi(localStorage.getItem('token'), 2, 1000, '0&isFinished=0');
        setLoading(true);
        axios.get(url)
            .then(response => {
                if (response.data.status === 1 && response.data.tickets.length > 0) {
                    setTicketId(response.data.tickets[0].id);
                    setTicketValues(response.data.tickets[0].ticketValues);
                    setSerialNumber(response.data.tickets[0].setSerialNumber);
                    setLoading(false);
                    if (response.data.tickets[0].prize != 0) {
                        setWinning(true);
                        setPrize(response.data.tickets[0].prize);
                    } else {
                        setWinning(false);
                    }
                } else {
                    setLoading(false);
                }

            })
            .catch(function (error) {
                setLoading(false);
            });
    };

    const buyScratch = (event) => {
        setTcktNbr(0);
        setBuyLoading(true);
        event.preventDefault();
        props.onBuyScratch(parseFloat(tcktNbr), 2);
    };

    const flipall = () => {
        setTicketMessage(null);
        setFlipped([true, true, true, true, true, true, true, true, true]);
        setAllflipped(true);
    };

    const flipIndex = (index) => {
        setTicketMessage(null);
        const updatedIndexs = [...flipped];
        updatedIndexs[index] = true;
        setFlipped(updatedIndexs);
    };

    const playAgain = () => {
        setTicketMessage(null);
        setFlipped([false, false, false, false, false, false, false, false, false]);
        setAllflipped(false);
        getTicket();
    };

    useEffect(() => {
        let checker = arr => arr.every(v => v === true);
        if (checker(flipped)) {
            setAllflipped(true);
            props.onPlayTicket(props.gameId, ticketId, serialNumber, 2);
        }
    }, [flipped]);

    useEffect(() => {
        setUnplayedTickets(props.unplayedTickets);
        if (prevUnplayed == 0 && prevUnplayed != props.unplayedTickets) {
            getTicket();
        }
    }, [props.unplayedTickets]);
    useEffect(() => {
        props.onTryAutoSignup();
    }, [props.isAuthenticated]);

    useEffect(() => {
        setTicketMessage(props.ticketMessageScratch);
    }, [props.ticketMessageScratch]);


    return (
        <>
            <div className="flip">
                {props.isAuthenticated ?
                    <>
                        <div className="flip__logo">
                            <img src={africanlogo} alt="African fruits logo" />
                        </div>

                        <h3 className="flip__subtitle">{t("Get 3 of the same fruits and win")}!</h3>
                        {!props.inactiveAf ?
                            <div className="flip__unplayed">
                                <span>{t("Available Tickets")}</span>
                                <span className="flip__unplayedNbr">{props.unplayedTickets}</span>
                            </div> :
                            ''}
                        {props.inactiveAf ?
                            <>
                                <h3 className="flip__subtitle">{t("Game unavailable at the moment")}!</h3>
                                <br />
                                <h3 className="flip__subtitle">{t("Sorry for the inconvenience, Please try again later")}!</h3>
                                <a className="flip__btnfun" href="/lucky7?page=demoplay">
                                    {t("Play for fun")}
                                </a>
                            </>
                            :
                            <div className="flip__container">
                                <HowToWinBox gameid={gameid} titledisplay="Get 3 of the same fruits and win" />
                                <div className={allflipped ? "flip__table winning " : "flip__table"}>
                                    {loading ? <Loader /> :
                                        ticketValues.map((item, index) => (
                                            <div className={item.isWinning ? "flip__item winningItem" : "flip__item"}
                                                key={index} onClick={() => flipIndex(index)}>
                                                <Flippy
                                                    flipDirection="horizontal"
                                                    isFlipped={flipped[index]}
                                                >
                                                    <FrontSide className="africanfruitsfront"> </FrontSide>
                                                    <BackSide>
                                                        <img src={item.image} />
                                                    </BackSide>
                                                </Flippy>
                                            </div>
                                        ))
                                    }
                                </div>
                                {allflipped && winning ?
                                    <>
                                        <div className="flip__winnings">
                                            <label className="flip__winningLabel">{t("You won")}</label>
                                            <span className="flip__winningValue">{prize} {process.env.REACT_APP_CURRENCY}</span>
                                        </div>
                                        <Confetti />
                                    </>
                                    : allflipped && !winning ?
                                        <div className="flip__losing">
                                            <label className="flip__losingLabel">{t("Try your luck the next time")}!</label>
                                        </div>
                                        :
                                        <div className="flip__losing-hidden">
                                            <label className="flip__losingLabel" />
                                        </div>
                                }
                                {unplayedTickets == 0 ?
                                    <span className="flip__purchase">{t("You have 0 tickets, purchase to play again")}</span> : ''}
                                {unplayedTickets !== 0 && allflipped ?
                                    <div className="btn-container">
                                        <button className="flip__btn" onClick={playAgain}>{t("Play")}</button>
                                    </div>
                                    : unplayedTickets !== 0 ?
                                        <div className="btn-container">
                                            <button className="flip__btn" onClick={flipall}>{t("Flip all")}</button>
                                        </div>
                                        : ''
                                }

                                <div className="flip__ticketsInfo">

                                    <div className="flip__ticketPrice">
                                        <label>{t("Ticket Price")}</label>
                                        <span>{props.af_ticketPrice} {process.env.REACT_APP_CURRENCY} </span>
                                    </div>
                                    <div className="flip__buy">

                                        <div
                                            className="flip__buy__buymapbtn"
                                            onClick={reducemapfct}
                                        >
                                            -
                                        </div>
                                        <input type="number" name="tcktNbr" value={tcktNbr}
                                            onChange={e => setTcktNbr(e.target.value)} placeholder="0"
                                            className="flip__buy__input" />
                                        <div
                                            className="flip__buy__buymapbtn"
                                            onClick={addmapfct}
                                        >
                                            +
                                        </div>


                                    </div>
                                    <button className="flip__buy__btn" onClick={(event) => buyScratch(event)}>
                                        {t("Buy")}
                                    </button>
                                    {props.loadingScratchBuy ? <Loader /> : ''}
                                    {ticketMessage && !props.loadingScratchBuy ?
                                        <span className="flip__buy__msg">{ticketMessage}</span> : ''}
                                    <a className="flip__btnfun" href="/africanfruit?page=funplay">{t("Play for fun")}</a>
                                </div>
                            </div>
                        }
                    </>
                    : <Funplay />}

            </div>
        </>
    );
}
const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null,
        wallet: state.auth.wallet,
        gameId: state.auth.afid,
        maxPrize: state.auth.af_maxPrize,
        unplayedTickets: state.auth.unplayed_af,
        statusaf: state.auth.statusaf,
        ticketMessageScratch: state.auth.ticketMessageaf,
        af_ticketPrice: state.auth.af_ticketPrice,
        af_prizeValues: state.auth.af_prizeValues,
        loadingScratchBuy: state.auth.loadingScratchBuy,
        inactiveAf: state.auth.inactiveAf
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onTryAutoSignup: () => dispatch(actions.authCheckState()),
        onGetScratch: (gametype) => dispatch(actions.getScratch(gametype)),
        onBuyScratch: (ticketNb, scratchType) => dispatch(actions.buyScratch(ticketNb, scratchType)),
        onPlayTicket: (gameId, ticketId, serialNumber, scratchType) => dispatch(actions.playTicket(gameId, ticketId, serialNumber, scratchType)),
        onUpdateMessageaf: (message, status) => dispatch(actions.updateMessageaf(message, status))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Play);

