import React, { useEffect, useState } from "react";
import axios from "axios";
import clock from '../../assets/images/clock.png';
import { sleep } from "../Functions";
import { useTranslation } from "react-i18next";

function Countdown(props) {

    const { t } = useTranslation('translations');
    const [[years, days, hours, mins, secs], setTime] = useState([0, 0, 0, 0, 0]);

    let interval;
    let result;

    async function getTimerDraw() {
        const res = await axios(props.url);
        props.gameType == 'Instaloto' ? result = res.data.draw.nextDrawTime : result = res.data.draw.drawTime;
        return result;
    }

    function calculateTime(endDate) {

        let diff = (Date.parse(new Date(endDate)) - Date.parse(new Date())) / 1000;

        // clear countdown when date is reached
        if (diff < 0) return false;

        // calculate time difference between now and expected date
        setTime([
            Math.floor(diff / (365.25 * 86400)),
            Math.floor(diff / 86400) % 365,
            Math.floor(diff / 3600) % 24,
            Math.floor(diff / 60) % 60,
            diff % 60,
        ]);


        return { diff: diff };
    }

    function updateTime() {
        getTimerDraw().then((res) => {
            interval = setInterval(() => {
                //This will run every second
                if (!calculateTime(res)) {
                    stop();
                }
            }, 1000);
            return () => {
                clearInterval(interval);
            }
        })
    }

    function stop() {
        clearInterval(interval);
        sleep(3000).then(() => {
            updateTime();
            return true;
        });
    };

    useEffect(() => {
        updateTime();
    }, []);

    return (

        <div className='countdown-container'>
            <div className='countdown'>
                <span className="countdown__label">
                    {t('Next draw in')}
                </span>
                <span className="countdown__timer">
                    {/*{years.toString().padStart(2,"0")} : {days.toString().padStart(2,"0")} :*/} {hours.toString().padStart(2, "0")}:{mins.toString().padStart(2, "0")}:{secs.toString().padStart(2, "0")}
                </span>
            </div>
            <img src={clock} className="countdown__icon" />
        </div>
    );
}

export default Countdown;
