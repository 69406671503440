import React, { Component } from 'react';
import l7Logo from "../../assets/images/l7-logo.png";
import Confetti from 'react-confetti'
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import * as actions from "../../store/actions";
import { connect } from 'react-redux';
import HowToWinBox from "../../components/HowToWinBox/HowToWinBox";
import { ticketsApi } from '../../Api';
import DemoPlay from "./DemoPlay";
import { withTranslation } from "react-i18next";

const gameid = 4;

class Play extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ticketId: null,
            serialNumber: null,
            ticketValues: [],
            nbOfTickets: 0,
            prize: null,
            loading: false,
            disable: '',
            ticketMessage: null,
            unplayedTickets: this.props.unplayedTickets,
            extraRotate: '',
            previousValue: '',
            winningValue: null,
            winningValues: null,
            celebrate: null
        };

        this.spinWheel = this.spinWheel.bind(this);
    }

    componentDidMount() {
        this.setState({
            buyingms: null
        })
        this.props.onGetScratch(4);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.unplayedTickets !== prevState.unplayedTickets) {
            return this.props.onGetScratch(4);
        }
        else {
            return null;
        }
    }

    spinWheel(event) {
        event.preventDefault();
        this.props.onUpdateMessagel7(null, 0)
        this.setState({
            disable: 'disable',
            buyingms: null,
            // previousValue: this.state.winningValues,
            celebrate: null
        });

        this.getTicket();

        setTimeout(() => this.setState({ disable: '' }), 7000);

    }

    getTicket() {
        var losingClasses = new Array("losing-1", "losing-2", "losing-3", "losing");
        var random = losingClasses[Math.floor(Math.random() * losingClasses.length)];
        let url = ticketsApi(localStorage.getItem('token'), 4, 1000, '0&isFinished=0');
        axios.get(url)
            .then(response => {

                console.log('BB get ticket', response);
                this.setState({
                    ticketId: response.data.tickets[0].id,
                    serialNumber: response.data.tickets[0].setSerialNumber,
                    ticketValues: response.data.tickets[0].ticketValues,
                    winningValue: response.data.tickets[0].winningValue,
                    prize: response.data.tickets[0].prize

                });

                this.props.onPlayTicket(this.props.gameId, this.state.ticketId, this.state.serialNumber, 4);

                if (response.data.tickets[0].winningValue == this.state.previousValue) {

                    if (this.state.extraRotate == '') {
                        this.setState({ extraRotate: 'extra' });
                    }
                    else {
                        this.setState({ extraRotate: '' });
                    }

                }
                if (this.state.winningValue != 'losing') {
                    this.setState({
                        winningValues: response.data.tickets[0].winningValue
                    })

                    setTimeout(() => this.setState({ celebrate: 1 }), 6500);
                }
                else {
                    this.setState({
                        winningValues: random,
                        prize: null
                    })

                    setTimeout(() => this.setState({ celebrate: 2 }), 6500);
                }

                this.setState({
                    previousValue: this.state.winningValue
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    addmapfct = () => {
        this.setState({ nbOfTickets: this.state.nbOfTickets + 1 });
    }
    reducemapfct = () => {
        if (this.state.nbOfTickets == 0)
            return;
        else
            this.setState({ nbOfTickets: this.state.nbOfTickets - 1 });
    }

    buyScratch = (event) => {
        event.preventDefault();
        this.setState({ buyingms: null, buyloading: true });

        this.props.onBuyScratch(parseFloat(this.state.nbOfTickets), 4);



        this.setState({ nbOfTickets: 0 });

    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.ticketMessageScratch !== prevState.ticketMessageScratch) {
            return { buyingms: nextProps.ticketMessageScratch };
        }
        else return null;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isAuthenticated !== this.props.isAuthenticated) {
            this.props.onGetScratch(4);
        }
    }

    componentWillUnmount() {
        this.setState({
            buyingms: null,
        });
        this.props.onUpdateMessagel7(null, 0)
    }

    render() {
        const { t } = this.props;

        return (
            <div className="flip lucky7">
                {this.props.isAuthenticated ?
                    <>
                        {!this.props.inactiveL7 ?
                            <div className="flip__unplayed">
                                <span>{t("Available Tickets")}</span>
                                <span className="flip__unplayedNbr">{this.props.unplayedTickets}</span>
                            </div> :
                            ''}
                        <div className="flip__logo">
                            <img src={l7Logo} alt={t("Spin and win logo")} />
                        </div>
                        {this.props.inactiveL7 ?
                            <>
                                <h3 className="flip__subtitle">{t("Game unavailable at the moment")}!</h3>
                                <br />
                                <h3 className="flip__subtitle">{t("Sorry for the inconvenience, Please try again later")}!</h3>
                                <a className="flip__btnfun" href="/lucky7?page=demoplay">
                                    {t("Play for fun")}
                                </a>
                            </>
                            :
                            <div className="flip__container">
                                <HowToWinBox gameid={gameid} titledisplay={t("Get lucky and hit the diamond to win big") + "!"}
                                    class="spinwin" />
                                <div className="slot-container">
                                    <div className="slot-machine">
                                        <div className={this.state.winningValues + ' slot-1 ' + this.state.extraRotate}></div>
                                        <div className={this.state.winningValues + ' slot-2 ' + this.state.extraRotate}></div>
                                        <div className={this.state.winningValues + ' slot-3 ' + this.state.extraRotate}></div>
                                    </div>
                                </div>
                                {this.state.celebrate == 1 ?
                                    <>
                                        <div className="flip__winnings">
                                            <label className="flip__winningLabel">{t("You won")}</label>
                                            <span
                                                className="flip__winningValue">{this.state.prize} {process.env.REACT_APP_CURRENCY}</span>
                                        </div>
                                        <Confetti />
                                    </>
                                    : this.state.celebrate == 2 ?
                                        <div className="flip__losing">
                                            <label className="flip__losingLabel">{t("Try your luck the next time")}!</label>
                                        </div>
                                        :
                                        <div className="flip__losing-hidden">
                                            <label className="flip__losingLabel" />
                                        </div>
                                }
                                {this.props.unplayedTickets !== 0 ?
                                    <div className="btn-container">
                                        <button className="flip__btn" disabled={this.state.disable ? "disable" : ''}
                                            onClick={this.spinWheel}>{t("Play")}
                                        </button>
                                    </div>
                                    : <span className="flip__purchase">{t("You have 0 tickets, purchase to play again")}</span>
                                }
                                <div className="flip__ticketsInfo">

                                    <div className="flip__ticketPrice">
                                        <label>{t("Ticket Price")}</label>
                                        <span>{this.props.ticketPrice} {process.env.REACT_APP_CURRENCY} </span>
                                    </div>
                                    <div className="flip__buy">

                                        <div
                                            className="flip__buy__buymapbtn"
                                            onClick={this.reducemapfct}
                                        >
                                            -
                                        </div>
                                        <input type="number" name="nbOfTickets" value={this.state.nbOfTickets}
                                            onChange={e => this.setState({ nbOfTickets: e.target.value })} placeholder="0"
                                            className="flip__buy__input" />
                                        <div
                                            className="flip__buy__buymapbtn"
                                            onClick={this.addmapfct}
                                        >
                                            +
                                        </div>


                                    </div>
                                    <button className="flip__buy__btn" onClick={(event) => this.buyScratch(event)}>
                                        {t("Buy")}
                                    </button>
                                    {this.props.loadingScratchBuy ? <Loader /> : ""}
                                    {this.state.buyingms && !this.props.loadingScratchBuy ?
                                        <span className="flip__buy__msg">{this.state.buyingms}</span> : ''}
                                    <a className="flip__btnfun" href="/lucky7?page=demoplay">
                                        {t("Play for fun")}
                                    </a>
                                </div>
                            </div>
                        }
                    </>
                    : <DemoPlay />}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null,
        wallet: state.auth.wallet,
        gameId: state.auth.l7id,
        maxPrize: state.auth.l7_maxPrize,
        unplayedTickets: state.auth.unplayed_l7,
        ticketMessageScratch: state.auth.ticketMessagel7,
        ticketPrice: state.auth.l7_ticketPrice,
        scratch_prizeValues: state.auth.l7_prizeValues,
        loadingScratchBuy: state.auth.loadingScratchBuy,
        inactiveL7: state.auth.inactiveL7
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onTryAutoSignup: () => dispatch(actions.authCheckState()),
        onGetScratch: (gametype) => dispatch(actions.getScratch(gametype)),
        onBuyScratch: (ticketNb, scratchType) => dispatch(actions.buyScratch(ticketNb, scratchType)),
        onPlayTicket: (gameId, ticketId, serialNumber, scratchType) => dispatch(actions.playTicket(gameId, ticketId, serialNumber, scratchType)),
        onUpdateMessagel7: (message, status) => dispatch(actions.updateMessagel7(message, status))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("translations")(Play));