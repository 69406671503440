import React, { useState, useEffect } from 'react';
import { globalBetApiToken, globalBetApi } from '../../Api';
import axios from 'axios';
import { connect } from "react-redux";
import Header from "../../components/Header/Header-old";
import Footer from "../../components/Footer/Footer";
import ReactGA from "react-ga";

function GlobalBet(props) {

    const [gbUrl, setGbUrl] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        ReactGA.pageview('/virtuals');
    }, [])

    useEffect(() => {
        setLoading(true);
        let url = globalBetApi;
        if (props.isAuthenticated) {
            url = globalBetApiToken(localStorage.getItem('token'));
        }
        axios.get(url)
            .then(response => {
                console.log('global bet', response.data)
                setGbUrl(response.data.redirectUrl);
            })
            .catch(function (error) {

            });
    }, [props.isAuthenticated]);

    return (
        <div className="minimal-page">
            <Header />
            <div className="global-bet">
                <iframe src={gbUrl} allowFullScreen="true" allow="autoplay; fullscreen" />
            </div>
        </div>

    );
}
const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null,
    };
};
export default connect(mapStateToProps)(React.memo(GlobalBet));