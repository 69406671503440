import React, { useEffect, useState } from "react";
import axios from "axios";
import HeaderOld from "../../components/Header/Header-old";
import Footer from "../../components/Footer/Footer";
import Staticpages from "../pages/Staticpages";
import Returnmessage from "../../components/Returnmessage/Returnmessage";
import Loader from "../../components/Loader/Loader";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";

export default function Forgotpassword() {

	const { t } = useTranslation('translations');

	const [phone, setphone] = useState("");
	const [loadingphone, setloadingphone] = useState(false);
	const [loading, setloading] = useState(false);
	const [password, setpassword] = useState("");
	const [confirmpassword, setconfirmpassword] = useState("");
	const [code, setcode] = useState("");
	const [visible, setvisible] = useState("noshow");
	const [phoneErrorSubmit, setphoneErrorSubmit] = useState("");
	const [status, setstatus] = useState("");
	const [responseMessage, setresponseMessage] = useState("");

	useEffect(() => {
		ReactGA.pageview('/forgotpassword');
	}, [])
	const handlechangephone = (e) => {
		e.preventDefault();
		const { name, value } = e.target;
		setphone(e.target.value);
	};
	const handlechangecode = (e) => {
		e.preventDefault();
		const { name, value } = e.target;
		setcode(e.target.value);
	};
	const handlechangepass = (e) => {
		e.preventDefault();
		const { name, value } = e.target;
		setpassword(e.target.value);
	};
	const handlechangeconfirmpass = (e) => {
		e.preventDefault();
		const { name, value } = e.target;
		setconfirmpassword(e.target.value);
	};


	const Submitphnmb = (e) => {
		e.preventDefault();
		let msisdn = phone;
		localStorage.setItem("forgotmsisdn", msisdn);
		let url = "/user-api/msisdns/" + msisdn + "/passwords";

		setloadingphone(true);
		axios
			.post(url)
			.then((response) => {
				if (response.data.status === 1) {
					setloadingphone(false);
					setvisible("show");
				} else {
					setloadingphone(false);
					setphoneErrorSubmit(response.data.message);
				}
			})
			.catch((err) => {
				setloadingphone(false);
				setphoneErrorSubmit(err.message);
			});

	};
	let phoneError = null;
	if (phoneErrorSubmit) {
		phoneError = (
			<Returnmessage content={phoneErrorSubmit} type={"error"} />
		);
	}
	let loadingphonee = false;
	if (loadingphone) {
		loadingphonee = <Loader />;
	}

	const forgetpassfct = (e) => {
		e.preventDefault();
		const authData = {
			password: password,
			confirmPassword: confirmpassword,
			code: code,
		};
		let url =
			"/user-api/msisdns/" +
			localStorage.getItem("forgotmsisdn") +
			"/passwords";
		setloading(true);
		axios
			.put(url, authData)
			.then((response) => {
				if (response.data.status === 1) {
					setloading(false);
					setstatus(response.data.status);
					setresponseMessage(response.data.message)
				} else {
					setloading(false);
					setstatus(response.data.status);
					setresponseMessage(response.data.message)
				}
			})
			.catch((err) => {
				setloading(false);
				setstatus(0);
				setresponseMessage(err.message)
			});
	};
	let message = null;
	if (status === 1) {
		message = <Returnmessage content={responseMessage} type={"success"} />;
	} else if (status === 0) {
		message = <Returnmessage content={responseMessage} type={"error"} />;
	}
	let loadingg = false;
	if (loading) {
		loadingg = <Loader />;
	}
	let phoneverification = (
		<section className="userpage-container">
			<form onSubmit={Submitphnmb}>
				<div></div>
				<h5 className="userpage-container-title">{t("Forgot Password")}</h5>
				<label className="userpage-container-subtitle">
					{t("Phone number")}
				</label>
				<br />
				<input
					name="phone"
					value={phone}
					onChange={handlechangephone}
					required
					type="number"
					className="userpage-container-inputfield"
				/>
				<button className="form-forgetbtn-submit" type="submit">
					{t("Submit")}
				</button>
			</form>
			{loadingphonee}
			{phoneError}
		</section>
	);
	if (visible === "show") {
		phoneverification = (
			<section className="userpage-container">
				<form onSubmit={forgetpassfct}>
					<h5 className="userpage-container-subtitle">{t("Reset Password")}</h5>
					<label className="userpage-container-inputfield-title">{t("Code")}</label>
					<br />
					<input
						name="code"
						value={code}
						onChange={handlechangecode}
						required
						type="text"
						className="userpage-container-inputfield"
					/>
					<label className="userpage-container-inputfield-title">
						{t("New Password")}
					</label>
					<br />
					<input
						name="password"
						value={password}
						onChange={handlechangepass}
						required
						type="password"
						className="userpage-container-inputfield"
					/>
					<label className="userpage-container-inputfield-title">
						{t("Confirm Password")}
					</label>
					<br />
					<input
						name="confirmpassword"
						value={confirmpassword}
						onChange={handlechangeconfirmpass}
						required
						type="password"
						className="userpage-container-inputfield"
					/>
					<button type="submit" className="form-forgetbtn-submit">{t("Submit")}</button>
				</form>
				{loadingg}
				{message}
			</section>
		);
	}

	return (
		<div className="staticpages-backgroundColor">
			<HeaderOld />
			<Staticpages title={t("account")} text={phoneverification} />
			<Footer />
		</div>
	);
}
