import React, { useState, useEffect } from "react";
import Datafile from "../../data";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Loader from "../Loader/Loader";
import Returnmessage from "../../components/Returnmessage/Returnmessage";
import * as actions from "../../store/actions/index";
import { walletTransactionAmountApi } from "../../Api";
import { useTranslation } from "react-i18next";

export default function Deposit(props) {
	const { t } = useTranslation('translations');
	const Navigate = useNavigate();

	const [walletdeposit, setwalletdeposit] = useState({
		depositAmount: null,
		message: null,
		loading: null,
		status: "",
		redirectUrl: "",
	});
	const handlechange = (e) => {
		const { name, value } = e.target;
		setwalletdeposit({
			...walletdeposit,
			[name]: value,
		});
		console.log(value);
	};

	const dispatch = useDispatch();

	const Depositamountfct = (e) => {
		e.preventDefault();
		const cashoutData = {
			token: localStorage.getItem("token"),
			amount: parseFloat(walletdeposit.depositAmount),
		};
		setwalletdeposit({
			...walletdeposit,
			loading: <Loader />,
		});
		axios
			.post(walletTransactionAmountApi, cashoutData)
			.then((response) => {
				setwalletdeposit({
					loading: null,
				});
				dispatch(actions.updateWallet());
				console.log(walletdeposit.redirectUrl);
				if (response.data.status === 1) {
					setwalletdeposit({
						...walletdeposit,
						message: response.data.message,
						redirectUrl: response.data.redirectUrl,
						loading: null,
						status: 1,
					});
					if (walletdeposit.redirectUrl) {
						console.log('walletdeposit.redirectUrl', walletdeposit.redirectUrl)
						window.location.assign(walletdeposit.redirectUrl)
					}
				} else {
					setwalletdeposit({
						...walletdeposit,
						message: response.data.message,
						loading: null,
						status: 0,
					});
				}
			})
			.catch((fresponse) => {
				setwalletdeposit({
					...walletdeposit,
					message: fresponse.data.message,
					loading: null,
				});
			});
	};

	const statechanger = (state) => {
		switch (state) {
			case "tigo":
				return (
					<>
						<div className="operator-background-photo tigo" />
						<div className="wallet-container">
							<div className="wallet-container-title">{t('Deposit')}</div>
							<form onSubmit={Depositamountfct}>
								<input
									name="depositAmount"
									value={walletdeposit.depositAmount}
									onChange={handlechange}
									required
									className="wallet-container-cashout"
									type="number"
									placeholder={"0.00 " + process.env.REACT_APP_CURRENCY}
								/>
								<div className="wallet-container-actionbtn">
									<button
										className="wallet-container-actionbtn-cancel"
										type="button"
										onClick={() => Navigate("/")}
									>
										{t('Cancel')}
									</button>
									<button
										className="wallet-container-actionbtn-save"
										type="submit"
									>
										{t('save')}
									</button>
								</div>
							</form>
							{walletdeposit.loading}
							{walletdeposit.status && walletdeposit.status == 1 ? (
								<Returnmessage content={walletdeposit.message} type={"success"} />
							) : walletdeposit.status == 0 ? (
								<Returnmessage content={walletdeposit.message} type={"error"} />
							) : (
								""
							)}
						</div>
					</>
				);

			case "vodacom":
				return (
					<>
						<div className="operator-background-photo vodacom" />

						<div className="depposit-operator">
							<ul className="depposit-operator-list">
								{Datafile.operatordeposit.vodacom.map((data, index) => {
									return (
										<li className="depposit-operator-list-info">
											<i className="list-info-tag">{index + 1}</i>
											<span className="list-info-wrld">{t(data.string)}</span>
										</li>
									);
								})}
							</ul>
						</div>
					</>
				);
			case "airtel":
				return (
					<>
						<div className="operator-background-photo airtel" />

						<div className="depposit-operator">
							<ul className="depposit-operator-list">
								{Datafile.operatordeposit.airtel.map((data, index) => {
									return (
										<li className="depposit-operator-list-info">
											<i className="list-info-tag">{index + 1}</i>
											<span className="list-info-wrld">{t(data.string)}</span>
										</li>
									);
								})}
							</ul>
						</div>
					</>
				);
			case "halotel":
				return (
					<>
						<div className="operator-background-photo halotel" />
						<div className="depposit-operator">
							<ul className="depposit-operator-list">
								{Datafile.operatordeposit.halotel.map((data, index) => {
									return (
										<li className="depposit-operator-list-info">
											<i className="list-info-tag">{index + 1}</i>
											<span className="list-info-wrld">{t(data.string)}</span>
										</li>
									);
								})}
							</ul>
						</div>
					</>
				);
		}
	};

	return <div>{statechanger(localStorage.getItem('operator'))}</div>;

}
