import React from "react";

export default function Returnmessage(props) {
	if (props.type === "success") {
		return (
			<div className="alert">

				<div className={"alert-" + props.type} >
					<span className="alert-success-message">
						{props.content}
					</span>
				</div>
			</div>
		);
	} else if (props.type === "error") {
		return (
			<div className="alert">
				<div className={"alert-" + props.type} >
					<span className="alert-error-message">
						{props.content}
					</span>
				</div>
			</div>
		);
	}
}
