import axios from 'axios';
import { cash5PurchaseApi, changeMsisdnApi, pick3PurchaseApi, registerApi, scratchTypesApi, ticketsApi, userApi, userAvatarApi, verifyMsisdnApi, wallet } from '../../Api';
import * as actionTypes from './actionTypes';
import i18next from "i18next";

//used to add spinner
export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};
export const buyStart = () => {
    return {
        type: actionTypes.BUY_START
    };
};

export const authVerStart = () => {
    return {
        type: actionTypes.VER_START
    };
};
export const authVerFail = (message) => {
    return {
        type: actionTypes.VER_FAIL,
        vererror: message
    };
};
export const regStart = () => {
    return {
        type: actionTypes.REG_START
    };
};
export const authLogout = (state, action) => {
    return {
        type: actionTypes.AUTH_LOGOUT
    };
};

export const displayWarning = (message) => {
    return {
        type: actionTypes.DISPLAY_WARNING,
        warning: message
    }
};

export const authSuccess = (token) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: token,
        isAuthenticated: true
    };
};
export const buyEnd = (message) => {
    return {
        type: actionTypes.BUY_END,
    };
};

export const updateMessage = (message, status) => {
    return {
        type: actionTypes.UPDATE_MESSAGE,
        ticketMessage: message,
        status: status
    };
};
export const setInstalotoLoading = (instaloading) => {
    return {
        type: actionTypes.SET_INSTA_LOADING,
        instaloading: instaloading,
    };
};
export const setPickLoading = (pickloading) => {
    console.log('pickloading', pickloading)
    return {
        type: actionTypes.PICK_LOADING,
        pickloading: pickloading,
    };
};
export const setCash5Loading = (cash5loading) => {
    console.log('cash5loading', cash5loading)
    return {
        type: actionTypes.CASH5_LOADING,
        cash5loading: cash5loading,
    };
};

export const update590Message = (message, status) => {
    return {
        type: actionTypes.UPDATE_590_MESSAGE,
        message590: message,
        status590: status
    };
};
export const verifySuccess = (token) => {
    return {
        type: actionTypes.VERIFY_SUCCESS,
        token: token
    };
};
export const registerSuccess = (verify) => {
    return {
        type: actionTypes.REGISTER_SUCCESS,
        verify: verify
    };
}

export const redirectVerify = (redirect) => {
    return {
        type: actionTypes.REDIRECT_VERIFY,
        redirect: redirect
    }
};
export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    };
};

export const authRegFail = (error) => {
    return {
        type: actionTypes.AUTH_REG_FAIL,
        regerror: error
    };
};
export const logout = () => {
    return dispatch => {
        let token = localStorage.getItem('token');
        let url = userApi(token);

        axios.delete(url)
            .then(response => {
                localStorage.removeItem('token');
                localStorage.removeItem('msisdn');
                localStorage.removeItem('avatar');
                localStorage.removeItem('Balance');
                localStorage.removeItem('balance');
                localStorage.removeItem('Promo_Balance');
                dispatch(authLogout());
                window.location.reload(false);
                window.location.href = '/';
            })
            .catch(err => {
                console.log(err);
                dispatch(authLogout());
            });
    };
};

export const auth = (username, password) => {
    return dispatch => {
        dispatch(authStart());
        const authData = {
            password: password,
            type: 1
        };
        let url = '/user-api/users/' + username;

        axios.post(url, authData)
            .then(response => {
                console.log('login', response);
                if (response.data.status === 1) {
                    localStorage.setItem('token', response.data.token);
                    localStorage.setItem('msisdn', response.data.user.mobileNumber.value);
                    if (response.data.user.avatar) {
                        localStorage.setItem('avatar', response.data.user.avatar);
                    }
                    dispatch(redirectVerify(false));
                    dispatch(authSuccess(response.data.token));
                    dispatch(getWallet());
                    const pathname = window.location.pathname
                    if (pathname == "/" || pathname== "/mojasports") window.location.reload(false);
                }

                else if (response.data.status === 3) {
                    localStorage.setItem('unverified_token', response.data.token);
                    localStorage.setItem('unverified_msisdn', response.data.user.mobileNumber.value);
                    dispatch(redirectVerify(true));
                }
                else {
                    dispatch(authFail(response.data.message));
                }

            })
            .catch(err => {
                console.log(err);
            });
    };
};
export const changeMsisdn = (msisdn) => {
    return dispatch => {
        const authData = {
            token: localStorage.getItem('token'),
            newMsisdn: msisdn,
        };
        let url = changeMsisdnApi;
        dispatch(authStart());
        axios.post(url, authData)
            .then(response => {
                if (response.data.status === 1) {
                    localStorage.setItem('msisdn', msisdn);
                    dispatch(redirectVerify(true));
                    dispatch(authSuccess());
                }
                else if (response.data.status === 0) {
                    dispatch(authFail(response.data.message));
                }

                else if (response.data.status === 3) {
                    dispatch(redirectVerify(true));
                }
                else if (response.data.status === 2) {
                    dispatch(displayWarning(response.data.message));
                }
            })
            .catch(err => {
                dispatch(authFail());
            });
    };
};

export const verifyMsisdn = (verificationCode) => {
    let token = localStorage.getItem('unverified_token');
    if (!token) {
        token = null;
    }
    let msisdn = localStorage.getItem('unverified_msisdn');
    return dispatch => {
        const authData = {
            mobileNumber: msisdn,
            code: verificationCode,
            type: 1,
        };
        let url = verifyMsisdnApi(token);
        dispatch(authVerStart());
        axios.patch(url, authData)
            .then(response => {
                console.log('veriy', response)
                if (response.data.status == 0) {
                    dispatch(authVerFail(response.data.message));
                } else {
                    if (response.data.status == 1) {

                        localStorage.removeItem('unverified_msisdn');
                        localStorage.removeItem('unverified_token');
                        localStorage.setItem('msisdn', msisdn);
                        localStorage.setItem('token', response.data.token);
                        dispatch(verifySuccess(response.data.token));
                        dispatch(authSuccess(response.data.token));
                        dispatch(getWallet());
                    }
                }
            })
            .catch(err => {
                console.log(err);
            });
    };
};
export const register = (mobile,
    password,
    confirmpassword,
    operator,
    age) => {
    return dispatch => {
        dispatch(regStart());

        const authData = {
            mobileNumber: '255' + mobile,
            email: null,
            password: password,
            confirmPassword: confirmpassword,
            operator: operator,
        };
        let url = registerApi;

        axios.post(url, authData)
            .then(response => {
                console.log('authdata', authData);
                console.log('register', response);
                if (response.data.status === 1) {

                    localStorage.setItem('unverified_msisdn', mobile);
                    localStorage.setItem('unverified_token', response.data.token);
                    dispatch(registerSuccess(true));
                }
                else {
                    dispatch(authRegFail(response.data.message));
                }

            })
            .catch(err => {
                console.log(err);
            });
    };
};
export const authCheckState = () => {
    console.log('called')
    return dispatch => {
        const token = localStorage.getItem('token');
        let url = userApi(token);
        let status = null;
        if (token) {
            axios.get(url)
                .then(response => {
                    if (response.data.status == 1) {
                        dispatch(authSuccess(token));
                    } else {
                        if (response.data.status == 0 && response.data.status == 4) {
                            dispatch(logout());
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
        else {
            // dispatch(logout());
        }
    };
};

export const updateCashMessage = (message, status) => {
    return {
        type: actionTypes.UPDATE_CASH_MESSAGE,
        cash5Message: message,
        cash5status: status
    };
};

export const updateWallet = (wallet, promoWallet) => {
    return {
        type: actionTypes.UPDATE_WALLET,
        wallet: wallet,
        promoWallet: promoWallet
    };
};
export const getWallet = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        const lang = localStorage.getItem(
            "i18nextLng"
        );
        let url = wallet(token, lang);

        axios.get(url)
            .then(response => {
                console.log('wallet', response);
                if (response.data.status == 1) {
                    localStorage.setItem('balance', response.data.amountFormatted)
                    dispatch(updateWallet(response.data.amountFormatted, response.data.promotionAmountFormatted));

                } else {
                    dispatch(updateMessage(response.data.message));
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };
};
export const updatePickMessage = (message, status, pick3Counter) => {
    console.log(status)
    return {
        type: actionTypes.UPDATE_PICK_MESSAGE,
        pick3Message: message,
        pick3Counter: pick3Counter,
        pick3status: status
    };
};
//the counter is added to fire the componentWillReceiveProps because if the response was similar to the one before it doesn't fire
export const purchasePick3 = (stake, pickNumbers, token, wallet, pick3Counter) => {
    return dispatch => {
        const authData = {
            stake: stake,
            numbers: pickNumbers,
            token: token,
        };
        dispatch(setPickLoading(true));
        let url = pick3PurchaseApi(wallet);
        axios.post(url, authData)
            .then(response => {
                if (response.data.status === 1) {
                    dispatch(updatePickMessage(response.data.message, 1, pick3Counter));
                    dispatch(getWallet());
                    dispatch(setPickLoading(false));
                } else {
                    console.log(response.data.message);
                    dispatch(updatePickMessage(response.data.message, 0, pick3Counter));
                    dispatch(setPickLoading(false));
                }

            })
    };
};

export const purchaseCash5 = (stake, numbers, numberOfPicks, isCombination) => {
    return dispatch => {
        const authData = {
            stake: stake,
            numbers: numbers,
            token: localStorage.getItem('token'),
            numberOfPicks: numberOfPicks,
            isCombination: isCombination,
        };
        dispatch(setCash5Loading(true));
        let url = cash5PurchaseApi(true);
        axios.post(url, authData)
            .then(response => {
                if (response.data.status === 1 || response.data.status === 'Success') {
                    dispatch(updateCashMessage(response.data.message, 1));
                    dispatch(getWallet());
                    dispatch(setCash5Loading(false));
                } else {
                    dispatch(updateCashMessage(response.data.message, 0));
                    dispatch(setCash5Loading(false));
                }
            })
            .catch(err => {
                // dispatch(authFail(err.message));
            });
    };
};

export const purchaseInstalotto = (ticketsNb, token, string, string2) => {
    return dispatch => {
        const authData = {
            numberOfTickets: ticketsNb,
            token: token,
            mobileNumber: string,
            currency: string2,
        };
        dispatch(setInstalotoLoading(true));
        let url = '/instalotto-api/tickets?IsWallet=true';
        axios.post(url, authData)
            .then(response => {
                console.log(response)
                if (response.data.status === 1) {
                    dispatch(updateMessage(response.data.message, 1));
                    dispatch(getWallet());
                    dispatch(setInstalotoLoading(false));
                } else {
                    dispatch(updateMessage(response.data.message, response.data.status));
                    dispatch(setInstalotoLoading(false));
                }
                // dispatch(authSuccess());
            })
            .catch(err => {
                dispatch(authFail(err.message));
            });
    };
};
export const setUnplayed = (ticketNb, gameId) => {
    switch (gameId) {
        case 6:
            return {
                type: actionTypes.SET_UNPLAYED,
                unplayed_ae: ticketNb,
            };

        case 2:
            return {
                type: actionTypes.SET_UNPLAYED,
                unplayed_af: ticketNb,
            };

        case 5:
            return {
                type: actionTypes.SET_UNPLAYED,
                unplayed_sw: ticketNb,
            };

        case 4:
            return {
                type: actionTypes.SET_UNPLAYED,
                unplayed_l7: ticketNb,
            };

    }

};
export const getUnplayed = (gameId) => {
    return dispatch => {
        console.log('ppp', gameId);
        let url = ticketsApi(localStorage.getItem('token'), gameId, 1000, '0&isFinished=0');
        axios.get(url)
            .then(response => {
                console.log('unplayed tick', response)
                if (response.data.status == 1 && response.data.tickets != null) {
                    dispatch(setUnplayed(response.data.numberOfTickets, gameId))
                }
                else {
                    dispatch(setUnplayed(0, gameId))
                }
            })
            .catch(err => {
                console.log(err.message);
            });
    }
};
export const setAfricanExplorer = (gameId, maxPrize, prizeValues, ticketPrice) => {
    console.log('prozzze', gameId)
    return {
        type: actionTypes.SET_AFRICAN_EXPLORER,
        aeid: gameId,
        ae_maxPrize: maxPrize,
        ae_prizeValues: prizeValues,
        ae_ticketPrice: ticketPrice
    };
};
export const setAfricanFruit = (gameId, maxPrize, prizeValues, ticketPrice) => {
    console.log('priceaf', ticketPrice)
    return {
        type: actionTypes.SET_AFRICAN_FRUIT,
        afid: gameId,
        af_maxPrize: maxPrize,
        af_prizeValues: prizeValues,
        af_ticketPrice: ticketPrice
    };
};
export const setSpinWin = (gameId, maxPrize, prizeValues, ticketPrice) => {
    console.log('prozzze', prizeValues)
    return {
        type: actionTypes.SET_SPIN_WIN,
        swid: gameId,
        sw_maxPrize: maxPrize,
        sw_prizeValues: prizeValues,
        sw_ticketPrice: ticketPrice
    };
};
export const setLucky7 = (gameId, maxPrize, prizeValues, ticketPrice) => {
    console.log('prozzze', prizeValues)
    return {
        type: actionTypes.SET_LUCKY_7,
        l7id: gameId,
        l7_maxPrize: maxPrize,
        l7_prizeValues: prizeValues,
        l7_ticketPrice: ticketPrice
    };
};
export const setInactiveAfricanFruit = (inactiveAf) => {
    return {
        type: actionTypes.SET_INACTIVE_AF,
        inactiveAf: inactiveAf
    };
}
export const setInactiveAfricanExplorer = (inactiveAe) => {
    return {
        type: actionTypes.SET_INACTIVE_AE,
        inactiveAe: inactiveAe
    };
}
export const setInactiveSpinWin = (inactiveSw) => {
    return {
        type: actionTypes.SET_INACTIVE_SW,
        inactiveSw: inactiveSw
    };
}
export const setInactiveLucky7 = (inactiveL7) => {
    return {
        type: actionTypes.SET_INACTIVE_L7,
        inactiveL7: inactiveL7
    };
}
export const getScratch = (gametype) => {
    return dispatch => {
        let url = scratchTypesApi(gametype, true, 1, 0);
        axios.get(url)
            .then(response => {
                console.log('getscratch', response);
                if (response.data.status == 1) {
                    switch (gametype) {
                        case 2:
                            dispatch(setAfricanFruit(response.data.id, response.data.maxPrize, response.data.ticketPrices, response.data.price));
                            dispatch(getUnplayed(gametype));
                            dispatch(setInactiveAfricanFruit(false));
                            break;
                        case 6:
                            dispatch(setAfricanExplorer(response.data.id, response.data.maxPrize, response.data.ticketPrices, response.data.price));
                            dispatch(getUnplayed(gametype));
                            dispatch(setInactiveAfricanExplorer(false));
                            break;
                        case 5:
                            dispatch(setSpinWin(response.data.id, response.data.maxPrize, response.data.ticketPrices, response.data.price));
                            dispatch(getUnplayed(gametype));
                            dispatch(setInactiveSpinWin(false));
                            break;
                        case 4:
                            dispatch(setLucky7(response.data.id, response.data.maxPrize, response.data.ticketPrices, response.data.price));
                            dispatch(getUnplayed(gametype));
                            dispatch(setInactiveLucky7(false));
                            break;
                    }
                }
                if (response.data.status == 10) {
                    switch (gametype) {
                        case 2:
                            dispatch(setInactiveAfricanFruit(true));
                            break;
                        case 6:
                            dispatch(setInactiveAfricanExplorer(true));
                            break;
                        case 5:
                            dispatch(setInactiveSpinWin(true));
                            break;
                        case 4:
                            dispatch(setInactiveLucky7(true));
                            break;
                    }
                }
            })
            .catch(err => {
                console.log(err);
            });
    }
};
export const updateMessageaf = (message, status) => {

    return {

        type: actionTypes.UPDATE_MESSAGE_AF,
        ticketMessageaf: message,
        statusaf: status
    };
};
export const updateMessageae = (message, status) => {
    console.log("hi")
    return {
        type: actionTypes.UPDATE_MESSAGE_AE,
        ticketMessageae: message,
        statusae: status
    };
};
export const updateMessagesw = (message, status) => {
    return {
        type: actionTypes.UPDATE_MESSAGE_SW,
        ticketMessagesw: message,
        statussw: status
    };
};
export const updateMessagel7 = (message, status) => {
    return {
        type: actionTypes.UPDATE_MESSAGE_L7,
        ticketMessagel7: message,
        statusl7: status
    };
};
export const buyScratch = (number, scratchType) => {
    console.log("buy");
    return dispatch => {

        const data = {
            token: localStorage.getItem('token'),
            type: scratchType,
            numberOfTickets: number,
            gameType: scratchType,
            mobileNumber: localStorage.getItem('msisdn'),
        };
        let url = `/scratch-api/tickets?isWallet=true`;
        dispatch(buyStart());
        axios.post(url, data)
            .then(response => {
                console.log('buyscratch', response)
                dispatch(getUnplayed(scratchType));
                switch (scratchType) {
                    case 2:
                        dispatch(updateMessageaf(response.data.message, response.data.status));
                        break;
                    case 6:
                        console.log("imhere")
                        dispatch(updateMessageae(response.data.message, response.data.status));
                        console.log("imherenoe", response.data.message)
                        break;
                    case 5:
                        console.log("hi i shouldn't be here")
                        dispatch(updateMessagesw(response.data.message, response.data.status));
                        break;
                    case 4:
                        dispatch(updateMessagel7(response.data.message, response.data.status));
                        break;
                }
                dispatch(getWallet());
                dispatch(buyEnd());
            })
            .catch(err => {
                dispatch(updateMessage(err.message, 0));
                dispatch(buyEnd());
            });
    }
};
export const playTicket = (gameId, ticketId, serialNumber, scratchType) => {
    console.log('Played', gameId);
    return dispatch => {
        let url = `/scratch-api/tickets`;
        let data = {
            token: localStorage.getItem('token'),
            ticketId: ticketId,
            gameType: scratchType,
        };
        axios.patch(url, data)
            .then(response => {
                console.log('play response', response);
                dispatch(getWallet());
                dispatch(getUnplayed(scratchType));
            })
            .catch(function (error) {
                console.log(error);
            });
    }
};
export const purchase590 = (stake, numbers, numberOfPicks, drawId, variation) => {
    return dispatch => {
        const authData = {
            drawId: drawId,
            set: "Original",
            variation: variation,
            mobileNumber: localStorage.getItem('msisdn'),
            stake: stake,
            numbers: numbers,
            token: localStorage.getItem('token'),
            numberOfPicks: numberOfPicks,
            operator: 'mtn'
        };
        let url = '/x-of-y-api/tickets?IsWallet=true';
        axios.post(url, authData)
            .then(response => {
                if (response.data.status === 1) {
                    dispatch(update590Message(response.data.message, 1));
                    dispatch(getWallet());
                } else {
                    console.log(response.data.message);
                    dispatch(update590Message(response.data.message, 0));
                }

            })
            .catch(err => {
                // dispatch(authFail(err.message));
            });
    };
};
export const updateAvatar = (avatar) => {
    return {
        type: actionTypes.UPDATE_AVATAR,
        avatar: avatar,
    };
};
export const changeAvatar = (avatar) => {
    return dispatch => {
        let url = userAvatarApi(localStorage.getItem('token'));
        const imageData = {
            avatar: avatar,
        };
        console.log('imageData = ', avatar);
        axios.post(url, imageData)
            .then(response => {
                console.log(response);
                if (response.data.status == 1) {
                    localStorage.setItem('avatar', response.data.avatar)
                    dispatch(updateAvatar(response.data.avatar));
                }
            })
            .catch(err => {
            });
    }
};
