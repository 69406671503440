import React, { Component, useContext } from "react";
import logo from '../../assets/images/logo.svg';
import HeaderMenu from './HeaderMenus/HeaderMenus';
import * as actions from '../../store/actions/index';
import { connect } from 'react-redux';
import { Link, Navigate } from "react-router-dom";
import axios from "axios";
import Register from "../Register/Register";
import { faUser, faBars, faTimes, faSpinner, faSignOut } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import avatar from '../../assets/images/user-image.png';
import Wallet from "../Wallet/Wallet";
import { ThemeSwitch } from "../ThemeSwitch/ThemeSwitch";
import { ThemeContext } from "../../ThemeContext";
import darkLogo from '../../assets/images/mojabet-dark-logo.svg';
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";

class HeaderOld extends Component {


    constructor() {
        super();
        this.state = {
            registerpopup: 'closed',
            mobilemenu: 'closed',
            loginmenu: 'closed',
            username: "",
            password: "",
            walletState: 'open',
            avatar: "",
            lang: i18n.language
        }
        this.openRegister = this.openRegister.bind(this);
        this.openmobilemenu = this.openmobilemenu.bind(this);
        this.openloginmenu = this.openloginmenu.bind(this);
        this.toggleWallet = this.toggleWallet.bind(this);
        this.signout = this.signout.bind(this);
    }

    static contextType = ThemeContext

    openRegister(event, closed = '') {
        if (event) {
            event.preventDefault();
        }
        if (this.state.registerpopup == 'closed') {
            this.setState({
                registerpopup: 'open'
            })
        } else {
            this.setState({
                registerpopup: 'closed'
            })
        }
        if (closed != '') {
            this.setState({
                registerpopup: 'closed'
            })
        }
    }
    toggleWallet(event) {
        event.preventDefault();

        if (this.state.walletState == 'closed') {
            this.setState({
                walletState: 'open'
            })
        } else {
            this.setState({
                walletState: 'closed'
            })
        }
    }
    openmobilemenu() {
        if (this.state.mobilemenu == 'closed') {
            this.setState({
                mobilemenu: 'open',
            })
        } else {
            this.setState({
                mobilemenu: 'closed',
            })
        }
    }
    openloginmenu() {
        if (this.state.loginmenu == 'closed') {
            this.setState({
                loginmenu: 'open'
            })
        } else {
            this.setState({
                loginmenu: 'closed'
            })
        }
    }
    submitLogin = async (event) => {
        event.preventDefault();
        await this.props.onAuth(this.state.username, this.state.password);
    };
    signout = (event) => {
        event.preventDefault();
        this.props.logout();
    };
    handleInputChange = (evt) => {
        this.setState({ [evt.target.name]: evt.target.value });
    };
    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.redirectVerify) {
            console.log("kkk");
            this.setState({
                redirect: (
                    <Navigate
                        to={{
                            pathname: "/verify",
                        }}
                    />
                ),
            });
        } else {
            this.setState({
                redirect: null,
            });
        }

    }
    componentWillMount = () => {
        this.props.getWallet();
        this.props.onTryAutoSignup();
        if (localStorage.getItem("avatar") != null) {
            this.setState({ avatar: localStorage.getItem("avatar") })
        } else
            this.setState({
                avatar: avatar
            })

    };

    render() {
        const { t, i18n } = this.props;

        const changeLanguage = (event) => {
            this.setState({
                lang: event.target.value
            });
            localStorage.setItem("lng", event.target.value);
            i18n.changeLanguage(event.target.value);
        };

        let loading = false;
        if (this.props.loading) {
            loading = (
                <div className="loading-container">
                    <FontAwesomeIcon className="icon" icon={faSpinner} />
                </div>
            );
        }
        let currTheme;
        let logoImage = logo;
        if (this.context.state.darkMode) {
            currTheme = 'dark';
        } else {
            currTheme = 'light';
            logoImage = darkLogo;
        }
        let loggedInHeader = (
            <div className={"header-login " + this.state.loginmenu}>
                <button onClick={this.openloginmenu} className="header-login__close">
                    <FontAwesomeIcon className="icon" icon={faTimes} />
                </button>
                <form onSubmit={this.submitLogin}>
                    <div className="header-login__block">
                        <label className={"header-login__label " + currTheme}>{t('Username')}</label>
                        <input type="text" name="username" className={"header-login__field " + currTheme} value={this.state.username}
                            onChange={this.handleInputChange} />
                        <Link to="/forgotpassword" className={"header-login__link " + currTheme}>{t("Forgot Password?")}</Link>
                    </div>
                    <div className="header-login__block">
                        <label className={"header-login__label " + currTheme}>{t('Password')}</label>
                        <input type="password" name="password" className={"header-login__field " + currTheme} value={this.state.password}
                            onChange={this.handleInputChange} />
                    </div>
                    <div className="header-login__block fullwidth">
                        <input type="submit" value={t("Login")} className="header-login__btn" />
                    </div>
                    <div className="header-login__block fullwidth">
                        <input type="submit" value={t("Register")} onClick={this.openRegister} className="header-login__btn bigger" />
                    </div>
                    {loading}

                    {this.props.error ?
                        <div className="header-login__error"><span>{this.props.error}</span></div>
                        : ''
                    }
                </form>
            </div>
        );
        let loginclass = '';
        
        let mobileLogin = <span className="header-login__mobile" onClick={this.openloginmenu}>{t('Login')}</span>;

        if (this.props.isAuthenticated && localStorage.getItem("token")) {
            loggedInHeader = (
                <div className="user-header">
                    <a href="#" className={"user-header__el wallet " + this.state.walletState} onClick={this.toggleWallet}>{process.env.REACT_APP_CURRENCY}  {t('Balance')}</a>
                    <Link to="/account" className="user-header__el">
                        <FontAwesomeIcon className="icon" icon={faUser} />
                        {localStorage.getItem('msisdn')}
                    </Link>
                    <a href="#" className="signout" onClick={this.signout}>
                        <FontAwesomeIcon className="icon" icon={faSignOut} />
                        <span className="tooltip">{t('Logout')}</span>
                    </a>
                </div>
            );
            mobileLogin = '';
            loginclass = 'loggedin'
        }


        return (
            <header className={"header " + loginclass}>
                <div className="mobile-header">
                    <div className="header__container">
                        <div className={"theme-switch " + currTheme}>
                            <label>{t(currTheme)}</label>
                            <ThemeSwitch isOn={this.context.state.darkMode} />
                        </div>

                        <div className="action">
                            <div className={"blc " + currTheme}>
                                {localStorage.getItem('balance')} {localStorage.getItem('balance') ? process.env.REACT_APP_CURRENCY : ""}
                            </div>
                            {this.props.isAuthenticated ?
                                <Link to="/account?page=deposit" className="btn">{t('Deposit')}</Link>
                                :
                                <>
                                    <a href="#" className="btn" onClick={this.openRegister}>{t('Register')}</a>
                                    <a href="#" className="btn" onClick={this.openloginmenu}>{t('Login')}</a>
                                </>
                            }
                        </div>
                        {/* <div className="header__langSwitch">
                            <select>
                                <option value="en">English</option>
                                <option value="sw">Swahili</option>
                            </select>
                        </div> */}
                    </div>
                </div>
                <div className="header__container">
                    <Link to="/" className="logo">
                        <img src={logoImage} alt="Mojabet Logo" />
                    </Link>
                    <div className="header-actions">
                        {this.state.redirect}
                        {loggedInHeader}
                        <div className="header__langSwitch">
                            <select value={this.state.lang} onChange={changeLanguage}>
                                <option value="en">English</option>
                                <option value="sw">Swahili</option>
                                <option value="fr">French</option>
                            </select>
                        </div>
                        <div className={"theme-switch " + currTheme}>
                            <label>{t(currTheme)}</label>
                            <ThemeSwitch isOn={this.context.state.darkMode} />
                        </div>
                        <a href="#" className="header__mobilemenuIcon" onClick={this.openmobilemenu}>
                            <FontAwesomeIcon className="icon" icon={faBars} />
                        </a>
                    </div>
                </div>
                {this.props.isAuthenticated ?
                    <div className={"header-wallet " + this.state.walletState}>
                        <Wallet balance={this.props.balance} promoBalance={this.props.promoBalance} fctclose={this.toggleWallet} />
                    </div>
                    : ''}

                <nav className={"header-nav " + this.state.mobilemenu}>
                    <a href="#" className="header__closeMenu" onClick={this.openmobilemenu}>
                        <FontAwesomeIcon className="icon" icon={faTimes} />
                    </a>
                    {this.props.isAuthenticated ?
                        <div className="header-nav__profile">
                            <Link to="/account" className="logo">
                                <img src={this.state.avatar} />
                            </Link>
                            <div>

                                <span className="header-nav__username">{localStorage.getItem('msisdn')}</span>
                                <span className="header-nav__balance">{t('Balance')}: <b>{localStorage.getItem('balance')} {process.env.REACT_APP_CURRENCY} </b></span>
                                <a href="#" className="signout" onClick={this.signout}>
                                    <FontAwesomeIcon className="icon" icon={faSignOut} /> {t('Logout')}
                                </a>
                                <Link to="/account" className="accountuser">
                                    <FontAwesomeIcon className="icon" icon={faUser} /> {t('Account')}
                                </Link>
                            </div>

                        </div>
                        :
                        ''
                    }
                    <ul className="header-nav__menu">
                        <HeaderMenu classes="sports" title="Mojasports" link="/mojasports" />
                        {/* <HeaderMenu classes="gbet" title="Virtuals" link="/virtuals"/> */}
                        <HeaderMenu classes="insta" title="InstaMoja" link="/instaloto" />
                        <HeaderMenu classes="pick" title="Pick 3" link="/mojaspesho" />
                        <HeaderMenu classes="cash5" title="Cash 5" link="/cash5" />
                        <HeaderMenu classes="chance" title={t("Chance Games")} link="/chance-games" />
                    </ul>
                </nav>
                <Register toggleRegister={this.openRegister} popup={this.state.registerpopup} />
            </header>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        isAuthenticated: state.auth.token !== null,
        warning: state.auth.warning,
        avatar: state.auth.avatar,
        balance: state.auth.wallet,
        promoBalance: state.auth.promoWallet,
        redirectVerify: state.auth.redirectVerify
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onTryAutoSignup: () => dispatch(actions.authCheckState()),
        onAuth: (username, password) => dispatch(actions.auth(username, password)),
        getWallet: () => dispatch(actions.getWallet()),
        logout: () => dispatch(actions.logout()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("translations")(HeaderOld));