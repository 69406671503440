
const Datafile = {
	instaloto: {
		playonline: [
			{
				string: "Buy your ticket"
			},
			{
				string: "Wait for the Countdown",
			},
			{
				string: "Check my winnings",
			}
		],
		playtigo: [
			{
				string: "Dial *150*01#"
			},
			{
				string: "Select pay bills",
			},
			{
				string: "Enter company number 123255",
			},
			{
				string: "Enter reference numbers (your 3 lucky numbers)",
			},
			{
				string: "Enter amount",
			}
		],
		playvodacom: [
			{
				string: "Dial *150*00#"
			},
			{
				string: "Pay for m-pesa",
			},
			{
				string: "Enter company number 123255",
			},
			{
				string: "Enter reference number 6652",
			},
			{
				string: "Enter amount",
			},
			{
				string: "Enter your mpesa pin",
			},
			{
				string: "Click 1 to confirm",
			}
		],
		playairtel: [
			{
				string: "Dial *150*60#"
			},
			{
				string: "Select pay bills",
			},
			{
				string: "Enter company number 123255",
			},
			{
				string: "Enter company number 123255",
			},
			{
				string: "Enter amount",
			},
			{
				string: "Enter reference numbers 6652",
			},
			{
				string: "Enter secret number",
			}
		],
		playhalotel: [
			{
				string: "Dial *150*88#"
			},
			{
				string: "Pay for halopesa",
			},
			{
				string: "Enter company number 123255",
			},
			{
				string: "Enter company number 123255",
			},
			{
				string: "Enter reference number 6652",
			},
			{
				string: "Enter amount",
			},
			{
				string: "Enter the secret code to verify",
			}
		],
	},
	pick3: {
		playonline: [
			{
				string: "Pick 3 numbers or click on the dice to choose randomly"
			},
			{
				string: "Wait for the Countdown",
			},
			{
				string: "Check my winnings",
			}
		],
		playtigo: [
			{
				string: "Dial *150*01#",
			},
			{
				string: "Select pay bills",
			},
			{
				string: "Enter company number 123255",
			},
			{
				string: "Enter reference numbers (your 3 lucky numbers)",
			},
			{
				string: "Enter amount",
			}
		],
		playvodacom: [
			{
				string: "Dial *150*00#",
			},
			{
				string: "Pay for m-pesa",
			},
			{
				string: "Enter company number 123255",
			},
			{
				string: "Enter reference numbers (your 3 lucky numbers)",
			},
			{
				string: "Enter amount",
			},
			{
				string: "Enter your mpesa pin",
			},
			{
				string: "Click 1 to confirm",
			}
		],
		playairtel: [
			{
				string: "Dial *150*60#",
			},
			{
				string: "Select pay bills",
			},
			{
				string: "Enter company number 123255",
			},
			{
				string: "Enter company number 123255",
			},
			{
				string: "Enter amount",
			},
			{
				string: "Enter reference numbers (your 3 lucky numbers)",
			},
			{
				string: "Enter secret number",
			}
		],
		playhalotel: [
			{
				string: "Dial *150*88#",
			},
			{
				string: "Pay for halopesa",
			},
			{
				string: "Enter company number 123255",
			},
			{
				string: "Enter reference number (your 3 lucky numbers)",
			},
			{
				string: "Enter amount",
			},
			{
				string: "Enter the secret code to verify",
			},
		],
	},
	africanexplorer: {
		playonline: [
			{
				string: "Just choose the game AFRICAN EXPLORER"
			},
			{
				string: "Then select how many tickets you want to buy",
			},
			{
				string: "You can scratch all to discover if you won or keep some for later",
			},
			{
				string: "Find 3 similar animals out of the 9 boxes - to win up to 2,000,000",
			}
		],
		playtigo: [
			{
				string: "Dial *150*01#",
			},
			{
				string: "Select lip bills",
			},
			{
				string: "Enter company number 123255",
			},
			{
				string: "Enter reference numbers 99",
			},
			{
				string: "Enter amount 99",
			}
		],
		playvodacom: [
			{
				string: "Dial *150*00#",
			},
			{
				string: "Pay for m-pesa",
			},
			{
				string: "Enter company number 123255",
			},
			{
				string: "Enter reference numbers 99",
			},
			{
				string: "Enter amount",
			},
			{
				string: "Enter your mpesa pin",
			},
			{
				string: "Click 1 to confirm",
			}
		],
		playairtel: [
			{
				string: "Dial *150*60#",
			},
			{
				string: "Select pay bills",
			},
			{
				string: "Enter company number 123255",
			},
			{
				string: "Enter amount",
			},
			{
				string: "Enter reference numbers 99",
			},
			{
				string: "Enter secret number",
			}
		],
		playhalotel: [
			{
				string: "Dial *150*88#",
			},
			{
				string: "Pay for halopesa",
			},
			{
				string: "Enter company number 123255",
			},
			{
				string: "Enter reference numbers 99",
			},
			{
				string: "Enter amount",
			},
			{
				string: "Enter the secret code to verify",
			},
		],
	},
	africanfruit: {
		playonline: [
			{
				string: "Buy basket (ticket)"
			},
			{
				string: "Reveal your cards (1 by 1 or all at once)",
			},
			{
				string: "Match 3 fruits and win! if you didn't, buy another and try again :)",
			}
		],
		playtigo: [
			{
				string: "Dial *150*01#",
			},
			{
				string: "Select lip bills",
			},
			{
				string: "Enter company number 123255",
			},
			{
				string: "Enter reference numbers 99",
			},
			{
				string: "Enter amount 99",
			}
		],
		playvodacom: [
			{
				string: "Dial *150*00#",
			},
			{
				string: "Pay for m-pesa",
			},
			{
				string: "Enter company number 123255",
			},
			{
				string: "Enter reference numbers 99",
			},
			{
				string: "Enter amount",
			},
			{
				string: "Enter your mpesa pin",
			},
			{
				string: "Click 1 to confirm",
			}
		],
		playairtel: [
			{
				string: "Dial *150*60#",
			},
			{
				string: "Select pay bills",
			},
			{
				string: "Enter company number 123255",
			},
			{
				string: "Enter amount",
			},
			{
				string: "Enter reference numbers 99",
			},
			{
				string: "Enter secret number",
			}
		],
		playhalotel: [
			{
				string: "Dial *150*88#",
			},
			{
				string: "Pay for halopesa",
			},
			{
				string: "Enter company number 123255",
			},
			{
				string: "Enter reference numbers 99",
			},
			{
				string: "Enter amount",
			},
			{
				string: "Enter the secret code to verify",
			},
		],
	},
	spinwin: {
		playonline: [
			{
				string: "Buy ticket"
			},
			{
				string: "Spin the wheel",
			},
			{
				string: "Hit any of the winning pictures and win if you didnt, buy another and try again :)",
			}
		],
		playtigo: [
			{
				string: "Dial *150*01#",
			},
			{
				string: "Select lip bills",
			},
			{
				string: "Enter company number 123255",
			},
			{
				string: "Enter reference numbers 99",
			},
			{
				string: "Enter amount 99",
			}
		],
		playvodacom: [
			{
				string: "Dial *150*00#",
			},
			{
				string: "Pay for m-pesa",
			},
			{
				string: "Enter company number 123255",
			},
			{
				string: "Enter reference numbers 99",
			},
			{
				string: "Enter amount",
			},
			{
				string: "Enter your mpesa pin",
			},
			{
				string: "Click 1 to confirm",
			}
		],
		playairtel: [
			{
				string: "Dial *150*60#",
			},
			{
				string: "Select pay bills",
			},
			{
				string: "Enter company number 123255",
			},
			{
				string: "Enter amount",
			},
			{
				string: "Enter reference numbers 99",
			},
			{
				string: "Enter secret number",
			}
		],
		playhalotel: [
			{
				string: "Dial *150*88#",
			},
			{
				string: "Pay for halopesa",
			},
			{
				string: "Enter company number 123255",
			},
			{
				string: "Enter reference numbers 99",
			},
			{
				string: "Enter amount",
			},
			{
				string: "Enter the secret code to verify",
			},
		],
	},
	cash5: {
		playonline: [
			{
				string: "Select your game type (cash 1, cash 2, cash3...)"
			},
			{
				string: "Select your number(s) depending on the game type selected from the box to the left or randomize your numbers  cash 1 (1 number)  cash 2 (2 numbers)  cash 3 (3 numbers)  cash 4 (4 numbers)  cash 5 (5 numbers)",
			},
			{
				string: "For the combinations:  for cash1: Use the combination option to pick two additional numbers and buy 3 tickets with 10% discount.  for cash2: Use the combination option to pick one additional number and buy 9 tickets with 10% discount.  for cash3: Use the combination option to pick one additional number and buy 64 tickets with 10% discount",
			},
			{
				string: "Set your stake amount",
			},
			{
				string: "The total price will be calculated based on your stake and the combination if you choose any",
			},
			{
				string: "Press play to purchase the ticket, wait for the game countdown to end then check your results",
			}
		],
		playtigo: [
			{
				string: "Dial *150*01#",
			},
			{
				string: "Select pay bills",
			},
			{
				string: "Enter company number 440088",
			},
			{
				string: "Enter numbers",
			},
			{
				string: "Enter amount",
			}
		],
		playvodacom: [
			{
				string: "Dial *150*00#",
			},
			{
				string: "Select pay bills",
			},
			{
				string: "Enter company number 440088",
			},
			{
				string: "Enter numbers",
			},
			{
				string: "Enter amount",
			}
		],
		playairtel: [
			{
				string: "Dial *150*60#",
			},
			{
				string: "Select pay bills",
			},
			{
				string: "Enter company number 440088",
			},
			{
				string: "Enter numbers",
			},
			{
				string: "Enter amount",
			}
		],
		playhalotel: [
			{
				string: "Dial *150*88#",
			},
			{
				string: "Select pay bills",
			},
			{
				string: "Enter company number 440088",
			},
			{
				string: "Enter numbers",
			},
			{
				string: "Enter amount",
			}
		],
	},
	// instahowtowin: {
	// 	howtowin: [
	// 		{
	// 			string: "This is instaloto data",
	// 		},
	// 		{
	// 			string: "Get 3 numbers in order and win 100 times your stake",
	// 		},
	// 		{
	// 			string: "Get 2 numbers in any order and win 4 times your stake",
	// 		},
	// 		{
	// 			string: "Enter reference number (your 3 lucky numbers)",
	// 		},
	// 		{
	// 			string: "Get 1 number and win 2 time your stake",
	// 		},
	// 	],
	// },
	instahowtowin: {
		howtowin: [
			{
				string: "Instant lottery game, where players' bets are redistributed in different amounts with each draw",
			},
			{
				string: "Just buy tickets to participate in the draws every 10 minutes",
			},
			{
				string: "If your phone number is drawn by the generator, you can win up to 1,000,000",
			}
		],
	},
	pick3howtowin: {
		howtowin: [
			{
				string: "Enter reference number (your 3 lucky numbers)",
			},
			{
				string: "Get 3 numbers in order and win 100 times your stake",
			},
			{
				string: "Get 3 numbers in any order and win 30 times your stake",
			},
			{
				string: "Get 2 numbers in any order and win 4 times your stake",
			},
			{
				string: "Get 1 number and win 2 time your stake",
			},
		],
	},
	operatordeposit: {
		vodacom: [
			{
				string: "Dial *150*00#",
			},
			{
				string: "Select pay by m-pesa",
			},
			{
				string: "Select enter business number",
			},
			{
				string: "Enter business number - 123255",
			},
			{
				string: "Enter reference number - 1",
			},
			{
				string: "Enter amount",
			},
			{
				string: "Enter pin to confirm",
			},
		],
		airtel: [
			{
				string: "Dial *150*60#",
			},
			{
				string: "Select make payment",
			},
			{
				string: "Select enter business number",
			},
			{
				string: "Enter business number - 123255",
			},
			{
				string: "Enter amount",
			},
			{
				string: "Enter reference number - 1",
			},
			{
				string: "Enter pin to confirm",
			},
		],
		halotel: [
			{
				string: "Dial *150*88#",
			},
			{
				string: "Select pay bill",
			},
			{
				string: "Select enter business number",
			},
			{
				string: "Enter business number - 123255",
			},
			{
				string: "Enter reference number - 1",
			},
			{
				string: "Enter amount",
			},
			{
				string: "Enter pin to confirm",
			},
		],
	}
};

export default Datafile;
