import React, { useState, useEffect } from 'react';
import { userApi, operatorsApi } from '../../Api';
import axios from 'axios';
import Returnmessage from '../Returnmessage/Returnmessage';
import Loader from '../Loader/Loader';
import { useTranslation } from "react-i18next";

export default function Profile(props) {

    const { t } = useTranslation('translations');

    const [operators, setOperators] = useState([]);
    const [status, setStatus] = useState(null);
    const [loader, setLoader] = useState(null);
    const [responseMessage, setResponseMessage] = useState(null);

    const [profileInfo, setProfileInfo] = useState({
        firstName: "",
        lastName: "",
        dateOfBirth: "",
        city: "",
        operator: ""
    });

    // Populates Operator's Dropdown
    useEffect(async () => {
        const response = await axios(operatorsApi);
        setOperators(response.data.operators);
    }, []);

    // Fill profile's inputs
    useEffect(() => {
        setStatus(null);
        if (props.userInfo) {
            setProfileInfo({
                firstName: props.userInfo.firstName,
                lastName: props.userInfo.lastName,
                dateOfBirth: props.userInfo.dateOfBirth,
                city: props.userInfo.city,
                operator: props.userInfo.mobileNumber.operator
            });
        }
    }, []);

    function handleChange(event) {
        const { name, value } = event.target;
        setProfileInfo({
            ...profileInfo,
            [name]: value,
        });
    }

    function submitForm(event) {
        setStatus(null);

        if (event) {
            event.preventDefault();
        }
        const userData = {
            firstName: profileInfo.firstName,
            lastName: profileInfo.lastName,
            dateOfBirth: profileInfo.dateOfBirth,
            city: profileInfo.city,
            operator: profileInfo.operator
        };
        let url = userApi(localStorage.getItem('token'));
        setLoader(<Loader />);
        console.log('user Data =', userData);
        axios.patch(url, userData)
            .then(response => {
                if (response.data.status == 1) {
                    setProfileInfo({
                        firstName: response.data.user.firstName,
                        lastName: response.data.user.lastName,
                        dateOfBirth: response.data.user.dateOfBirth,
                        city: response.data.user.city,
                        operator: response.data.user.mobileNumber.operator
                    })
                    localStorage.setItem('operator', response.data.user.mobileNumber.operator)

                    console.log('RESPONSE.DATA.USER ===========', response.data.user);
                    setStatus(1);
                }
                else {
                    setStatus(0);
                }
                setResponseMessage(response.data.message);
                setLoader(null);
            })
            .catch(err => {
                setResponseMessage(err.message);
                setStatus(0);
                setLoader(null);
            });

    }

    return (
        <div className='account-profile'>
            <form className="account-profile__form" onSubmit={submitForm}>

                <div className='account-profile__col'>
                    <div className='account-profile__inputGroup'>
                        <label htmlFor='firstName' className='account-profile__inputLabel'>{t("First Name")}</label>
                        <input
                            required
                            type="text"
                            name="firstName"
                            value={profileInfo.firstName}
                            onChange={handleChange}
                            className="account-profile__inputField"
                        />
                    </div>
                    <div className='account-profile__inputGroup'>
                        <label htmlFor='lastName' className='account-profile__inputLabel'>{t("Last Name")}</label>
                        <input
                            required
                            type="text"
                            name="lastName"
                            value={profileInfo.lastName}
                            onChange={handleChange}
                            className="account-profile__inputField"
                        />
                    </div>
                    <div className='account-profile__inputGroup'>
                        <label htmlFor='dateOfBirth' className='account-profile__inputLabel'>{t("D.O.B")}</label>
                        <input
                            required
                            type="date"
                            name="dateOfBirth"
                            value={profileInfo.dateOfBirth}
                            onChange={handleChange}
                            className="account-profile__inputField"
                        />
                    </div>
                </div>
                <div className='account-profile__col'>
                    <div className='account-profile__inputGroup'>
                        <label htmlFor='city' className='account-profile__inputLabel'>{t("City")}</label>
                        <input
                            required
                            type="text"
                            name="city"
                            value={profileInfo.city}
                            onChange={handleChange}
                            className="account-profile__inputField"
                        />
                    </div>
                    <div className='account-profile__inputGroup'>
                        <label htmlFor='operator' className='account-profile__inputLabel'>{t("Operator")}</label>
                        <select className="account-profile__inputField" name="operator" value={profileInfo.operator} onChange={handleChange} required>
                            {
                                operators.map((value, key) => {
                                    return (<option key={key} value={value.name}>{value.name}</option>)
                                })
                            }
                        </select>
                    </div>
                    <div className='account-profile__inputGroup'>
                        <a href="/" className="account-profile__btnCancel">{t("Cancel")}</a>
                        <button className="account-profile__btnSave" type='submit'>{t("Save")}</button>
                    </div>
                </div>
            </form>
            {loader}
            {status && status == 1 ?
                <Returnmessage content={responseMessage} type={"success"} />
                : status == 0 ?
                    <Returnmessage content={responseMessage} type={"error"} />
                    : ''
            }

        </div>
    );
}
