import Header from '../../components/Header/Header-old';
import Footer from "../../components/Footer/Footer";
import GameList from "../../components/Homepage/GameList/GameList";
import { useSelector } from "react-redux";
import USerStat from "../../components/Homepage/UserStat/USerStat";
import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import ReactGA from "react-ga";
import {useEffectOnce} from "../../components/Functions";

export default function Homepage(props) {
    const isAuthenticated = useSelector(state => state.auth.token);
    const [returnUrl, setReturnUrl] = useState(null);
    const [returnToken, setReturnToken] = useState(null);
    // const [windowSize, setWindowSize] = useState(window.innerWidth);

    useEffectOnce(() => {
        console.log('kiussa');
        window.scrollTo(0, 0);
        ReactGA.pageview('/mojasports');
        let url = '/digitain-api/sessions?language=en';
        let token = localStorage.getItem('token');
        if (token == null) {
            token = 'notloggedin'
        }
        let data = {
            token: token,
        };
        // let device = 'd';
        // if (windowSize < 993) {
        //     device = 'm'
        // }
        axios.post(url, data)
            .then(response => {
                setReturnUrl(response.data.redirectUrl)
                setReturnToken(JSON.parse(response.config.data).token);

                // let res = (response.data.redirectUrl).split("token=");

                // if (windowSize < 993 && device == "m")
                //     window.initMobileApp && window.initMobileApp("mobile-app-container", res[1], device);
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [isAuthenticated])

    console.log('isAuth', isAuthenticated);

    return (
        <>
            <Header />
            <div className="homepage">
                {/* start Old homepage*/}
                {/*<section className="hero">*/}
                {/*    <img src={mojasport} alt="Mojasports logo" className="hero__logo"/>*/}
                {/*    <Link className="hero__btn" to="/mojasports">Play Now!</Link>*/}
                {/*    <img src={soccer} alt="Soccer ball" className="soccer-ball"/>*/}
                {/*</section>*/}
                {/*<div className="container">*/}
                {/*    {isAuthenticated ? <USerStat/> : <GameList/> }*/}
                {/*</div>*/}
                {/*<div className="container">*/}
                {/*    <section className="gb-banner">*/}
                {/*        <img src={gbet} className="gb-banner__logo" alt="Global bet logo"/>*/}
                {/*        <p>*/}
                {/*            Bet and play virtually!<br/>*/}
                {/*            Basketball, football, grayhounds, horse racing<br/> and much more*/}
                {/*        </p>*/}
                {/*        <Link to="/virtuals" className="gb-banner__btn">Play Now</Link>*/}
                {/*    </section>*/}
                {/*    <CasinoScratch/>*/}
                {/*</div>*/}
                {/*<div className="container">*/}

                {/*    <section className="widgets">*/}
                {/*        <InstaWidget/>*/}
                {/*        <PickWidget/>*/}
                {/*    </section>*/}
                {/*</div>*/}
                {/*End old homepage*/}
                {!props.hiddenSlider ?
                    <div className="homepage__userStat">
                        <USerStat/>
                    </div>
                    : ''
                }
                <div className="homepage__sports">
                    <iframe src={returnUrl} width="100%" allowFullScreen={true}
                            webkitallowfullscreen="true"
                            mozallowfullscreen="true" />
                </div>
            </div>
            <Footer />
        </>
    )
}
