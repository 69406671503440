import './App.scss';
import { connect } from 'react-redux';
import React, { useContext, useEffect } from "react";
import { ThemeContext } from "./ThemeContext";
import WebFont from 'webfontloader';
import Homepage from "./containers/Homepage/Homepage";
import Instaloto from "./containers/Instaloto/Instaloto";
import About from "./containers/pages/About";
import Globalbet from "./containers/GlobalBet/GlobalBet";
import Contact from "./containers/pages/Contact";
import Terms from "./containers/pages/Termsconditions";
import Pick3 from "./containers/Pick3/Pick3";
import AfricanExplorer from "./containers/AfricanExplorer/AfricanExplorer";
import AfricanFruit from "./containers/AfricanFruit/AfricanFruit";
import Forgotpassword from "./containers/Userpages/Forgotpassword";
import Resetpass from "./containers/Userpages/Resetpassword";
import Verifyphonenmbr from "./containers/Userpages/Verifyphonenmbr";
import { Route, Routes } from "react-router-dom";
import AccountDashboard from './containers/Account/AccountDashboard';
import SpinWin from './containers/SpinWin/SpinWin';
import ChanceGames from './containers/ChanceGames/ChanceGames';
import Lucky7 from "./containers/Lucky7/Lucky7";
import Cash5 from './containers/Cash5/Cash5';
import Privacy from './containers/pages/Privacy';
// import SportsBetting from "./containers/SportsBetting/SportsBetting";
import ReactGA from 'react-ga';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from "../src/store/actions/index";
function App(props) {
    const theme = useContext(ThemeContext);
    const darkMode = theme.state.darkMode;

    useEffect(() => {
        props.onTryAutoSignup();
    }, [props.isAuthenticated]);


    useEffect(() => {
        ReactGA.initialize('UA-140441215-1');
        ReactGA.pageview('/');
    }, [])

    WebFont.load({
        google: {
            families: ["Inter:300,500,600", "sans-serif"],
        },
    });
    return (
        <div className={`bg ${darkMode ? "bg-dark" : "bg-light"}`}>
            <Routes>
                <Route path="/terms" element={<Terms />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/aboutus" element={<About />} />
                <Route path="/instaloto" element={<Instaloto />} />
                <Route path="/" element={<Homepage />} />
                {/* <Route path="/virtuals" element={<Globalbet/>}/> */}
                <Route path="/cash5" element={<Cash5 />} />
                <Route path="/pick3" element={<Pick3 />} />
                <Route path="/mojaspesho" element={<Pick3 />} />
                <Route path="/forgotpassword" element={<Forgotpassword />} />
                <Route path="/resetpassword" element={<Resetpass />} />
                <Route path="/verify" element={<Verifyphonenmbr />} />
                <Route path="/africanExplorer" element={<AfricanExplorer />} />
                <Route path="/africanFruit" element={<AfricanFruit />} />
                <Route path="/spinwin" element={<SpinWin />} />
                <Route path="/chance-games" element={<ChanceGames />} />
                <Route path="/mojasports" element={<Homepage hiddenSlider={true} />} />
                <Route path="/lucky7" element={<Lucky7 />} />
                <Route path="/privacy" element={<Privacy />} />
                {props.isAuthenticated ?
                    <Route path="/account" element={<AccountDashboard />} /> : ''
                }

            </Routes>
        </div>
    );
}
const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onTryAutoSignup: () => dispatch(actions.authCheckState()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);