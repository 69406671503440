import React from "react";

export default function Staticpages(props) {
    return (
        <section className="staticpage-container">
            <div className="staticpage-container-content">
                <h1 className="staticpage-container-content-title">{props.title}</h1>
                <div className="staticpage-container-content-text">{props.text}</div>
            </div>
        </section>
    );
}
