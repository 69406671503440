import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    token: null,
    error: null,
    error2: null,
    regerror: null,
    vererror: null,
    loading: false,
    loading2: false,
    verloading: false,
    verify: false,
    hpRedirect: false,
    warning: null,
    redirectVerify: false,
    successMessage: null,
    wallet: null,
    promoWallet: null,
    ticketMessage: null,
    pick3Message: null,
    cash5Message: null,
    pick3status: null,
    cash5status: null,
    pick3Counter:null,
    unplayed_ae: null,
    unplayed_af: null,
    unplayed_sw: null,
    unplayed_l7: null,
    ticketMessageaf:null,
    ticketMessageae:null,
    ticketMessagesw:null,
    ticketMessagel7:null,
    statussw:null,
    statusaf:null,
    statusae:null,
    statusl7:null,
    message590: null,
    status590: null,
    avatar: null,
    instaloading: false,
    pickloading: false,
    cash5loading: false,
    aeid:null,
    ae_maxPrize:null,
    ae_prizeValues:null,
    ae_ticketPrice:null,
    afid:null,
    af_maxPrize:null,
    af_prizeValues:null,
    af_ticketPrice:null,
    swid:null,
    sw_maxPrize:null,
    sw_prizeValues:null,
    sw_ticketPrice:null,
    l7id:null,
    l7_maxPrize:null,
    l7_prizeValues:null,
    l7_ticketPrice:null,
    loadingScratchBuy: false,
    inactiveAf: false,
    inactiveAe: false,
    inactiveSw: false,
    inactiveL7: false,
};

const authStart = ( state, action ) => {
    return updateObject( state, { error: null, loading: true } );
};
const buyStart = ( state, action ) => {
    return updateObject( state, { loadingScratchBuy: true } );
};
const buyEnd = ( state, action ) => {
    return updateObject( state, { loadingScratchBuy: false } );
};
const regStart = ( state, action ) => {
    return updateObject( state, { regerror: null, regloading: true } );
};
const updateMessage = ( state, action ) => {
    return updateObject( state, { ticketMessage: action.ticketMessage, status: action.status } );
};
const displayWarning = ( state, action ) => {
    return updateObject( state, { warning: action.message, loading: false } );
};
const displaySuccess = ( state, action ) => {
    return updateObject( state, { successMessage: action.message, loading: false } );
};
const authSuccess = (state, action) => {
    return updateObject( state, {
        token: action.token,
        error: null,
        loading: false,
        loading2: false,
     } );
};
const verifySuccess = (state, action) => {
    return updateObject( state, {
        token: action.token,
        error: null,
        loading: false,
        hpRedirect: true
    } );
};

const redirectVerify = (state, action) => {
    return updateObject( state, {
        redirectVerify: action.redirect,
        loading: false,
    } );
};
const registerSuccess  = (state, action) => {
    return updateObject( state, {
        verify: true,
        regloading: false,
    } );
};
const verifyMsisdn  = (state, action) => {
    return updateObject( state, {
        token: action.token,
        verifyloading: false,
        hpRedirect: true
    } );
};

const authRegister = (state, action) => {
    return updateObject( state, {
        regerror: null,
        regloading: false
    } );
};

const authFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
};
const authFail2 = (state, action) => {
    return updateObject( state, {
        error2: action.error2,
        loading2: false
    });
};
const authRegFail = (state, action) => {
    return updateObject( state, {
        regerror: action.regerror,
        regloading: false
    });
};
const authVerStart = (state, action) => {
    return updateObject( state, {
        verloading: true
    });
};
const authVerFail = (state, action) => {
    return updateObject( state, {
        vererror: action.vererror,
        verloading: false
    });
};
const authLogout = (state, action) => {
    return updateObject(state, { token: null });
};
const updateWallet = (state, action) => {
    return updateObject( state, {
        wallet: action.wallet,
        promoWallet: action.promoWallet,
    } );
};
const setAfricanExplorer = (state, action) => {
    return updateObject(state, {
        aeid:action.aeid,
        ae_maxPrize:action.ae_maxPrize,
        ae_prizeValues: action.ae_prizeValues,
        ae_ticketPrice: action.ae_ticketPrice,
    });
};
const setAfricanFruit = (state, action) => {
    console.log("actionpriceaf",action.af_ticketPrice)
    return updateObject(state, {
        afid:action.afid,
        af_maxPrize:action.af_maxPrize,
        af_prizeValues: action.af_prizeValues,
        af_ticketPrice: action.af_ticketPrice,
    });
};
const setSpinWin = (state, action) => {
    return updateObject(state, {
        swid:action.swid,
        sw_maxPrize:action.sw_maxPrize,
        sw_prizeValues: action.sw_prizeValues,
        sw_ticketPrice: action.sw_ticketPrice,
    });
};
const setLucky7 = (state, action) => {
    return updateObject(state, {
        l7id:action.l7id,
        l7_maxPrize:action.l7_maxPrize,
        l7_prizeValues: action.l7_prizeValues,
        l7_ticketPrice: action.l7_ticketPrice,
    });
};
const setUnplayed = (state, action) => {
    return updateObject(state, {
        unplayed_ae: action.unplayed_ae,
        unplayed_af: action.unplayed_af,
        unplayed_sw: action.unplayed_sw,
        unplayed_l7: action.unplayed_l7,
    });

};
const updateMessageaf = ( state, action ) => {
    return updateObject( state, { 
        ticketMessageaf: action.ticketMessageaf, 
        statusaf: action.statusaf 
    });
};
const updateMessageae = ( state, action ) => {
    return updateObject( state, { 
        ticketMessageae: action.ticketMessageae, 
        statusae: action.statusae 
    });
};
const updateMessagesw = ( state, action ) => {
    return updateObject( state, { 
        ticketMessagesw: action.ticketMessagesw, 
        statussw: action.statussw 
    });
};
const updateMessagel7 = ( state, action ) => {
    return updateObject( state, { 
        ticketMessagel7: action.ticketMessagel7, 
        statusl7: action.statusl7 
    });
};
const updatePickMessage = ( state, action ) => {
    return updateObject( state, { pick3Message: action.pick3Message, pick3status: action.pick3status, pick3Counter:action.pick3Counter } );
};
const updateCashMessage = ( state, action ) => {
    return updateObject( state, { cash5Message: action.cash5Message, cash5status: action.cash5status } );
};
const update590message = ( state, action ) => {
    return updateObject( state, { message590: action.message590, status590: action.status590 } );
};
const updateavatar = ( state, action ) => {
    return updateObject( state, { avatar: action.avatar } );
};
const setInstalotoLoading = ( state, action ) => {
    return updateObject( state, { instaloading: action.instaloading } );
};
const setPickLoading = ( state, action ) => {
    return updateObject( state, { pickloading: action.pickloading } );
};
const setCash5Loading = ( state, action ) => {
    return updateObject( state, { cash5loading: action.cash5loading } );
};
const setInactiveAfricanExplorer = ( state, action ) => {
    return updateObject( state, { inactiveAe: action.inactiveAe } );
};
const setInactiveAfricanFruit = ( state, action ) => {
    return updateObject( state, { inactiveAf: action.inactiveAf } );
};
const setInactiveSpinWin = ( state, action ) => {
    return updateObject( state, { inactiveSw: action.inactiveSw } );
};
const setInactiveLucky7 = ( state, action ) => {
    return updateObject( state, { inactiveL7: action.inactiveL7 } );
};
const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.AUTH_START: return authStart(state, action);
        case actionTypes.VER_START: return authVerStart(state, action);
        case actionTypes.VER_FAIL: return authVerFail(state, action);
        case actionTypes.REG_START: return regStart(state, action);
        case actionTypes.AUTH_REGISTER: return authRegister(state, action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.AUTH_FAIL: return authFail(state, action);
        case actionTypes.AUTH_REG_FAIL: return authRegFail(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
        case actionTypes.REGISTER_SUCCESS: return registerSuccess(state, action);
        case actionTypes.AUTH_VERIFY_MSISDN: return verifyMsisdn(state, action);
        case actionTypes.VERIFY_SUCCESS: return verifySuccess(state, action);
        case actionTypes.REDIRECT_VERIFY: return redirectVerify(state, action);
        case actionTypes.DISPLAY_WARNING: return displayWarning(state, action);
        case actionTypes.DISPLAY_SUCCESS: return displaySuccess(state, action);
        case actionTypes.UPDATE_MESSAGE: return updateMessage(state, action);
        case actionTypes.UPDATE_WALLET: return updateWallet(state, action);
        case actionTypes.UPDATE_PICK_MESSAGE: return updatePickMessage(state, action);
        case actionTypes.UPDATE_CASH_MESSAGE: return updateCashMessage(state, action);
        case actionTypes.SET_AFRICAN_EXPLORER: return setAfricanExplorer(state, action);
        case actionTypes.SET_AFRICAN_FRUIT: return setAfricanFruit(state, action);
        case actionTypes.SET_SPIN_WIN: return setSpinWin(state, action);
        case actionTypes.SET_LUCKY_7: return setLucky7(state, action);
        case actionTypes.SET_UNPLAYED: return setUnplayed(state, action);
        case actionTypes.UPDATE_MESSAGE_AF: return updateMessageaf(state, action);
        case actionTypes.UPDATE_MESSAGE_AE: return updateMessageae(state, action);
        case actionTypes.UPDATE_MESSAGE_SW: return updateMessagesw(state, action);
        case actionTypes.UPDATE_MESSAGE_L7: return updateMessagel7(state, action);
        case actionTypes.UPDATE_590_MESSAGE: return update590message(state, action);
        case actionTypes.UPDATE_AVATAR: return updateavatar(state, action);
        case actionTypes.SET_INSTA_LOADING: return setInstalotoLoading(state, action);
        case actionTypes.PICK_LOADING: return setPickLoading(state, action);
        case actionTypes.CASH5_LOADING: return setCash5Loading(state, action);
        case actionTypes.BUY_END: return buyEnd(state, action);
        case actionTypes.BUY_START: return buyStart(state, action);
        case actionTypes.SET_INACTIVE_AE: return setInactiveAfricanExplorer(state, action);
        case actionTypes.SET_INACTIVE_AF: return setInactiveAfricanFruit(state, action);
        case actionTypes.SET_INACTIVE_SW: return setInactiveSpinWin(state, action);
        case actionTypes.SET_INACTIVE_L7: return setInactiveLucky7(state, action);
        default:
            return state;
    }
};

export default reducer;