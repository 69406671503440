import React, { useState, useEffect } from "react";
import instaloto from "../../assets/images/instalogo.png";
import { useSelector, useDispatch } from 'react-redux';
import * as actions from "../../store/actions/index";
import Loader from "../../components/Loader/Loader";
import Nogame from "../../components/Nogame/Nogame";
import Returnmessage from "../../components/Returnmessage/Returnmessage";
import axios from "axios";
import { instalottoDraws } from "../../Api";
import { useTranslation } from "react-i18next";

export default function Play(props) {

    const { t } = useTranslation('translations');

    const [counter, setCounter] = useState(1);
    const [priceloto, setpriceloto] = useState(0);

    const [ticketPrice, setTicketPrice] = useState(0);

    const dispatch = useDispatch();

    useEffect(async () => {
        await axios(instalottoDraws(true, 0, 1)).then((res) => {
            if (res.data.status == 1) {
                setTicketPrice(res.data.draw.ticketPrice);
                setpriceloto(res.data.draw.ticketPrice);
            }
        })
    }, []);

    const decreaseStake = () => {
        if (counter <= 0) {
            return;
        } else {
            setCounter(counter - 1);
        }
        for (var i = 0; i <= counter; i++) {
            setpriceloto(priceloto - ticketPrice);
        }
    };
    const increaseStake = () => {
        setCounter(counter + 1);
        for (var i = 0; i <= counter; i++) {
            setpriceloto(priceloto + ticketPrice);
        }
    };

    const ticketMessage = useSelector(state => state.auth.ticketMessage);
    const statuscall = useSelector((state) => state.auth.status);
    const isAuthenticated = useSelector(state => state.auth.token);
    const instaloading = useSelector(state => state.auth.instaloading);

    const btnpurchaseInstaLotto = (event) => {
        event.preventDefault();
        dispatch(actions.purchaseInstalotto(counter, localStorage.getItem('token'), localStorage.getItem('msisdn'), "TZS"));
    }
    let loader = false;
    if (instaloading) {
        loader = <Loader />;
    }
    return (
        <>
            <div className="instagame-wrapper">
                <div className="instagame-wrapper__head">
                    <img src={instaloto} alt={t("insta loto")} className="instagame-wrapper__head-logo" />
                    <p className="instagame-wrapper__head-desc">{t("Try your luck for a chance to win")}</p>
                </div>
                {isAuthenticated ?
                    <>
                        <div className="instagame-wrapper__counter">
                            <div
                                className="instagame-wrapper__counter-container playcounter"
                                onClick={decreaseStake}
                            >
                                -
                            </div>
                            <div className="instagame-wrapper__counter-container counterbox">{counter}</div>
                            <div
                                className="instagame-wrapper__counter-container playcounter"
                                onClick={increaseStake}
                            >
                                +
                            </div>
                        </div>

                        <div className="instagame-wrapper__price">
                            <p className="instagame-wrapper__price-preinput">{t("Payment Price")}</p>
                            <div className="instagame-wrapper__price-labelprice">{priceloto} {process.env.REACT_APP_CURRENCY}</div>
                        </div>
                        <button className="instagame-wrapper__purchasebtn" onClick={btnpurchaseInstaLotto}>
                            {t("Purchase")}
                        </button>
                        {loader}
                        {statuscall && statuscall === 1 ?
                            <Returnmessage content={ticketMessage} type={"success"} />
                            : statuscall === 0 ?
                                <Returnmessage content={ticketMessage} type={"error"} />
                                : ''
                        }
                    </>
                    : <Nogame />}
            </div>

        </>
    );
}
