import React, { useEffect, useState } from "react";
import { scratchTypesApi } from "../../Api";
import axios from "axios";

export default function Scratchwincriteria(props) {
	const [scratchphoto, setscratchphoto] = useState([]);
	useEffect(() => {
		console.log("his");
		axios
			.get(scratchTypesApi(props.gameid, "", 100, 0))
			.then((response) => {
				console.log("stats", response);
				setscratchphoto(response.data.ticketPrices);
				console.log("images", response.data.ticketPrices);
			})
			.catch(function (error) {
				console.log(error);
			});
	}, []);
	return (
		<>
			<div className="scratchgame-howtowin">
				<div className="scratchgame-howtowin-list">
					{scratchphoto.map((image, index) => (
						<li className="scratchgame-howtowin-list-info">
							<i className="list-info-tag">{index + 1}</i>
							<div className="scratchgame-howtowin-list-info-position">
								<>
									<span className="scratchgame-howtowin-list-info-htwphoto">
										<img
											className="scratchgames-photos-modifier"
											src={image.imageUrl}
										/>
									</span>
								</>
								<span className="scratchgame-howtowin-list-info-pricewinner">
									{image.prize}
								</span>
							</div>
						</li>
					))}

				</div>
			</div>
		</>
	);
}
