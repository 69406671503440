import logo from '../../assets/images/logo.svg';
import darkLogo from '../../assets/images/mojabet-dark-logo.svg';
import { useSelector } from 'react-redux';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebookSquare, faInstagram, faYoutube} from "@fortawesome/free-brands-svg-icons";
import {Link} from 'react-router-dom';
import gamingBoard from '../../assets/images/gaming-board.svg';
import lock from '../../assets/images/lock.svg';
import agerest from '../../assets/images/agr-rest.svg';
import React, { useContext } from "react";
import { ThemeContext } from "../../ThemeContext";
import { useTranslation } from "react-i18next";

export default function Footer() {
    
	const { t } = useTranslation('translations');
    
    const isAuthenticated = useSelector(state => state.auth.token);
    const theme = useContext(ThemeContext);
    const darkMode = theme.state.darkMode;
    let logoImage = logo;
    if (!theme.state.darkMode) {
        logoImage = darkLogo;
    }
    console.log('footer theme', darkMode)
    return (
        <footer className="footer">
            <div className="footer__container">
                <div className="footer__info">
                    <img src={logoImage} alt="mojabet logo" className="footer__logo"/>
                    <div className="footer__social">
                        <FontAwesomeIcon className="icon" icon={faFacebookSquare} />
                        <FontAwesomeIcon className="icon" icon={faInstagram} />
                        <FontAwesomeIcon className="icon" icon={faYoutube} />
                    </div>
                </div>
                <div className="footer__menu">
                    <ul>
                        <li className="footer__navSection">{t('Games')}</li>
                        <li className="footer__navItem">
                            <Link to="/mojasports">{t('Mojasports')}</Link>
                        </li>
                        {/* <li className="footer__navItem">
                            <Link to="/virtuals">Virtuals</Link>
                        </li> */}
                        <li className="footer__navItem">
                            <Link to="/instaloto">{t('InstaMoja')}</Link>
                        </li>
                        <li className="footer__navItem">
                            <Link to="/mojaspesho">{t('Mojaspesho')}</Link>
                        </li>
                        <li className="footer__navItem">
                            <Link to="/chance-games">{t('Chance Games')}</Link>
                        </li>
                    </ul>
                </div>
                {isAuthenticated ?
                <div className="footer__menu">
                    <ul>
                        <li className="footer__navSection">{t('Account')}</li>
                        <li className="footer__navItem">
                            <Link to="/account">{t('Account Dashboard')}</Link>
                        </li>
                        <li className="footer__navItem">
                            <Link to="/account?page=deposit">{t('Deposit')}</Link>
                        </li>
                        <li className="footer__navItem">
                            <Link to="/account?page=cashout">{t('Cashout')}</Link>
                        </li>
                        <li className="footer__navItem">
                            <Link to="/account?page=tickets">{t('Tickets')}</Link>
                        </li>
                    </ul>
                </div>
                : <></> }
                <div className="footer__menu">
                    <ul>
                        <li className="footer__navSection">{t('Company')}</li>
                        <li className="footer__navItem">
                            <Link to="/aboutus">{t('About Us')}</Link>
                        </li>
                        <li className="footer__navItem">
                            <Link to="/contact">{t('Contact Us')}</Link>
                        </li>
                    </ul>
                </div>
                <div className="footer__icons">
                    <img src={gamingBoard} alt="Gaming board"/>
                    <img src={lock} alt="Lock"/>
                    <img src={agerest} alt="18+"/>
                </div>
                <div className="footer__extra">
                    <div className="footer__copyright">
                        <span> ©{(new Date().getFullYear())} {t('Mojabet all rights reserved')} </span>
                    </div>
                    <div className="footer__links">
                        <Link  className="footer__link" to="/terms">{t('Terms and conditions')}</Link>
                        <Link  className="footer__link" to="/privacy/">{t('Privacy Policy')}</Link>
                        <span  className="footer__link">{t('Play responsible')}</span>
                    </div>
                </div>
            </div>
        </footer>
    )
}
