import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";

function Sidemenu(props) {
    let pages = props.pages;

    const { t } = useTranslation('translations');

    const [open, openStatus] = useState(' ');
    const [menuOpen, isMenuOpen] = useState(false);
    const [gameType, setGameType] = useState('flip');
    const [activePage, setActivePage] = useState(pages[0].content);
    const [activePageTitle, setActivePageTitle] = useState(null);

    const items = document.getElementsByClassName("sidemenu__item");
    const isAuthenticated = useSelector(state => state.auth.token);
    const [searchParams, setSearchParams] = useSearchParams({});

    useEffect(() => {
        selectActive(activePageTitle);
    }, [isAuthenticated, activePageTitle]);

    useEffect(() => {
        if (props.page) {
            setActivePage(props.page);
        }
    }, [props.page]);

    useEffect(() => {
        if (props.gameType) { setGameType(props.gameType); }
    }, [props.gameType]);

    function openSidemenu() {
        if (!menuOpen) {
            openStatus(' open ');
            isMenuOpen(true);
        }
        else {
            openStatus(' ');
            isMenuOpen(false);
        }
    }

    function selectActive(title) {
        for (var i = 0; i < items.length; ++i) {
            items[i].className = "sidemenu__item";
            items[i].style.removeProperty('background-color');
        }
        if (title)
            document.getElementsByName(title)[0].classList.add("active");
        else
            items[0].classList.add("active");
    }

    function openPage(page, title) {
        setActivePage(page);
        setActivePageTitle(title);
        setSearchParams({});
        console.log(searchParams);
        openSidemenu();

    }

    return (
        <div>
            <div className={"sidemenu__btn" + open} onClick={openSidemenu}>
                <span className='sidemenu__burger' />
            </div>
            <nav className={"sidemenu__drawer" + open + gameType}>
                <ul>
                    {isAuthenticated ?
                        pages.map((page, key) => {
                            let title = page.title;
                            let content = page.content;
                            return (<li key={key}><a id={key} key={key} name={title} className="sidemenu__item"
                                href="#" onClick={() => openPage(content, title)} >{t(title)}</a></li>)
                        }) :
                        pages.filter(item => item.name !== 'purchases' && item.name !== 'winnings' && (gameType == 'scratch' ? item.name !== 'play' : item.name !== ''))
                            .map((page, key) => {
                                let title = page.title;
                                let content = page.content;
                                return (<li key={key}><a id={key} key={key} name={title} className="sidemenu__item"
                                    href="#" onClick={() => openPage(content, title)} >{title}</a></li>)
                            })
                    }
                </ul>
            </nav>

            {activePage}
        </div>

    );
}

export default Sidemenu;
