import React, { useState, useEffect } from "react";
import Datafile from "../../data";
import Wincriteria from "../../components/Typeofplay/Wincriteria";
import { useTranslation } from "react-i18next";

export default function HowToWin() {

	const { t } = useTranslation('translations');

	const [text, setText] = useState({});

	useEffect(() => {
		Object.keys(Datafile.instahowtowin).forEach(function (key) {
			console.log(key)
			var value = Datafile.instahowtowin[key];
			setText(value)
			console.log("props1", value);
		});
	}, [])

	return (
		<>
			<div className="howtowin-container">
				<div className="howtowin-container-title">
					{t("How to win")}!
				</div>
				<div className="howtowin-container-subtitle">
					{t("You get more than one chance to win in instaloto")}!
				</div>
			</div>
			<Wincriteria text={text} />
		</>
	);
}