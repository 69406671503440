import React, { useEffect, useState } from "react";
import aelogo from "../../../assets/images/ae-logo.png";
import aflogo from "../../../assets/images/af-logo.png";
import l7logo from "../../../assets/images/l7-logo.png";
import swlogo from "../../../assets/images/sw-logo.png";
import { Link } from "react-router-dom";
import avatar2 from '../../../assets/images/user-image.png';
import { getUserStats } from "../../../Api";
import axios from "axios";
import { useSelector } from "react-redux";
import Register from "../../Register/Register";
import HomepageSlider from "../HomepageSlider/HomepageSlider";
import mojasportlogo from "../../../assets/images/mojasport-logo-banner.png"
import { useTranslation } from "react-i18next";

export default function UserStat() {

	const { t } = useTranslation('translations');

	const [pendingTickets, setPendingTickets] = useState([]);
	const [avatar, setavatar] = useState(avatar2);
	const [winningTickets, setWinningTickets] = useState([]);
	const [prizeSummary, setPrizeSummary] = useState([]);
	const isAuthenticated = useSelector(state => state.auth.token);

	let winticket = null;
	if (winningTickets) {
		winticket = winningTickets
			.sort(() => Math.random() - Math.random())
			.find(() => true);
	}
	let prizeSummaries = null;
	if (prizeSummary) {
		prizeSummaries = prizeSummary.sort(() => Math.random() - Math.random());
		console.log("prises", prizeSummaries);
	}
	useEffect(() => {
		if (localStorage.getItem("avatar") != null) {
			setavatar(localStorage.getItem("avatar"));
		} else
			setavatar(avatar2);
	}, [avatar])

	useEffect(() => {
		console.log("his");
		axios
			.get(getUserStats(localStorage.getItem("token"), 30))
			.then((response) => {
				console.log("stats", response);
				setPendingTickets(response.data.pendingTicket);
				setWinningTickets(response.data.winningTicket);
				setPrizeSummary(response.data.prizeSummary);
				console.log('priiii', prizeSummary);
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [isAuthenticated]);
	let compactClass = "";
	if (pendingTickets && pendingTickets.length > 2) {
		compactClass = "compact";
	}
	return (
		<section className="game-list userstat">
			<div className="game-list__column sponsored">
				{isAuthenticated ?
					<div className="userblock">
						<div className="userblock__image">
							<img className="userblock__image iconn" src={avatar} />
							<h2>
								{t("Welcome Back")}!
							</h2>
						</div>
						<div className="userblock__info">
							<span>
							{t("player")} <br /> {localStorage.getItem("msisdn")}
							</span>
						</div>
					</div>
					:
					<div className="sponsored__game ">
						<img src={aelogo} />
						<div className="sponsored__actions">
							<Link to="/africanExplorer" className="game-list__link">
							{t("Play Now")}
							</Link>
							<Link to="/africanExplorer?page=demo" className="game-list__link">
							{t("Play for fun")}
							</Link>
						</div>
					</div>
				}
				{isAuthenticated && winningTickets && winningTickets.length > 0 ? (
					<div className="sponsored__game winnings">
						<img src={winticket.image} />
						<label>{winticket.gameName}</label>
						<div className="tickts-won">
							<span>{t("You have") + " "} <big>{winticket.totalTickets}</big> {t("winning tickets")}</span>
						</div>
						<div className="sponsored__actions">
							<Link to={winticket.gameUrl} className="game-list__link">
							{t("Play Now")}
							</Link>
							<Link to={winticket.gameUrl + "?page=demo"} className="game-list__link">
							{t("Play for fun")}
							</Link>
						</div>
					</div>) :
					<div className="sponsored__game ">
						<img src={aflogo} />
						<div className="sponsored__actions">
							<Link to="/africanFruit" className="game-list__link">
							{t("Play Now")}
							</Link>
							<Link to="/africanFruit?page=demo" className="game-list__link">
							{t("Play for fun")}
							</Link>
						</div>
					</div>
				}
			</div>
			{isAuthenticated ?
				pendingTickets && pendingTickets.length > 0 ?
					<div className={"game-list__column unplayed " + compactClass}>
						<div className="unplayed__el">
							{pendingTickets.map((tickets, index) => (
								<>
									<div className="unplayed-container">
										<div className="unplayed__el__title">
											<img src={tickets.image} />
										</div>
										<div className="unplayed__el__tickets">
											<p>{tickets.gameName}</p>
											<span>
											{t("You have")} <span className="big">{tickets.totalTickets}</span> {t("unplayed tickets")}

											</span>
										</div>
									</div>
									<div className="sponsored__actions">
										<Link to="/africanFruit" className="game-list__link">
										{t("Play Now")}
										</Link>
										<Link to="/africanFruit?page=demo" className="game-list__link">
										{t("Play for fun")}
										</Link>
									</div>
								</>
							))}
						</div>
					</div>
					:
					''
				:
				<div className="game-list__column register">
					<Register />
				</div>
			}
			{isAuthenticated && prizeSummary && prizeSummary.length > 0 ? (
				<div className="game-list__column sponsored">
					{prizeSummaries && prizeSummaries.length > 2 ? (
						<>
							<div className="sponsored__game tcktNbr">
								<div className="sponsored__badge">
									<span>{t("Game of the day")}</span>
								</div>
								<img src={prizeSummaries[0].image} />
								<div className="sponsored__stat">
									<p>{t("Tickets won by users around the globe")}</p>
									<span>{prizeSummaries[0].totalTickets}</span>
								</div>
								<div className="sponsored__actions">
									<Link
										to={prizeSummaries[0].gameUrl}
										className="game-list__link"
									>
										{t("Play Now")}
									</Link>
									<Link to={prizeSummaries[0].gameUrl + '?page=demo'} className="game-list__link">
									{t("Play for fun")}
									</Link>
								</div>
							</div>
							<div className="sponsored__game mojasportfield">
								<div className="labelmojasport">{t("Try our sports betting")}!</div>
								<img src={mojasportlogo} className="sponsored__game logomojasports" />
								<div className="sponsored__actions">
									<Link to="/mojasports" className="game-list__link mojasports">
									{t("Play Now")}
									</Link>
								</div>
							</div>{" "}
						</>
					) : (
						<>
							<div className="sponsored__game comp">
								<div className="sponsored__badge">
									<span>{t("Game of the day")}</span>
								</div>
								<img src={prizeSummaries[0].image} />
								<div className="sponsored__stat">
									<p>{t("Tickets won by users around the globe")}</p>
									<span>{prizeSummaries[0].totalTickets}</span>
								</div>
								<div className="sponsored__actions">
									<Link
										to={prizeSummaries[0].gameUrl}
										className="game-list__link"
									>
										{t("Play Now")}
									</Link>
									<Link to={prizeSummaries[0].gameUrl + '?page=demo'} className="game-list__link">
									{t("Play for fun")}
									</Link>
								</div>
							</div>
							<div className="sponsored__game mojasportfield">
								<div className="labelmojasport">{t("Try our sports betting")}!</div>
								<img src={mojasportlogo} className="sponsored__game logomojasports" />
								<div className="sponsored__actions">
									<Link to="/mojasports" className="game-list__link mojasports">
									{t("Play Now")}
									</Link>
								</div>
							</div>
						</>
					)}
				</div>
			) : (
				<div className="game-list__column sponsored">
					<div className="sponsored__game gameofday">
						<div className="sponsored__badge">
							<span>{t("Game of the day")}</span>
						</div>
						<img src={l7logo} />
						<div className="sponsored__actions">
							<Link to="/lucky7" className="game-list__link">
							{t("Play Now")}
							</Link>
							<Link to="/lucky7?page=demo" className="game-list__link">
							{t("Play for fun")}
							</Link>
						</div>
					</div>
					<div className="sponsored__game mojasportfield">
						<div className="labelmojasport">{t("Try our sports betting")}!</div>
						<img src={mojasportlogo} className="sponsored__game logomojasports" />
						<div className="sponsored__actions">
							<Link to="/mojasports" className="game-list__link mojasports">
							{t("Play Now")}
							</Link>
						</div>
					</div>
				</div>
			)}
			<div className="game-list__column usersarea">
				<HomepageSlider />
			</div>
		</section>
	);
}
