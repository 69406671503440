import React, { useEffect, useState } from "react";
import axios from "axios";
import { contactUsApi } from "../../Api";
import HeaderOld from "../../components/Header/Header-old";
import Footer from "../../components/Footer/Footer";
import Loader from "../../components/Loader/Loader";
import Returnmessage from "../../components/Returnmessage/Returnmessage";
import Staticpages from "../pages/Staticpages";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";

export default function Contact(props) {

	const { t } = useTranslation('translations');

	const [contactMessage, setcontactMessage] = useState({
		name: "",
		mobile: "",
		email: "",
		message: "",
		loading: false,
	});

	useEffect(() => {
		ReactGA.pageview('/contactus');
	}, [])

	const handlechange = (e) => {
		const { name, value } = e.target;
		setcontactMessage({
			...contactMessage,
			[name]: value,
		});
		console.log(value);
	};

	const submitForm = (e) => {
		e.preventDefault();
		setcontactMessage({
			loading: true,
		});
		const contactData = {
			fullName: contactMessage.name,
			mobileNumber: contactMessage.mobile,
			email: contactMessage.email,
			message: contactMessage.message,
		};
		let url = contactUsApi;
		setcontactMessage({
			loading: true,
		});
		axios
			.post(url, contactData)
			.then((response) => {
				if (response.data.status === 1) {
					setcontactMessage({
						name: "",
						mobile: "",
						email: "",
						message: "",
						loading: false,
						status: 1,
						responseMessage: response.data.message,
					});
				} else if (response.data.status === 0) {
					setcontactMessage({
						responseMessage: response.data.message,
						status: 0,
						loading: false,
					});
				}
			})
			.catch((err) => {
				setcontactMessage({
					responseMessage: err.message,
					status: 0,
					loading: false,
				});
			});
	};
	let message = null;
	if (contactMessage.status === 1) {
		message = (
			<Returnmessage content={contactMessage.responseMessage} type={"success"} />
		);
	} else if (contactMessage.status === 0) {
		message = (
			<Returnmessage content={contactMessage.responseMessage} type={"error"} />
		);
	}
	let loading = false;
	if (contactMessage.loading) {
		loading = <Loader />;
	}
	return (
		<div className="staticpages-backgroundColor">
			<HeaderOld />
			<section className="contactus-container">
				<h1 className="contactus-container-title">{t("Contact Us")}</h1>
				<form className="contactus-form" onSubmit={submitForm}>
					<div className="contactus-inputfields">
						<h5 className="contactus-inputfields-subtitle">{t("We are here!")}</h5>

						<div className="contactus-inputfields-firstrow">
							<div className="contactus-inputfields-firstrow-block">
								<label className="contactus-inputfields-preinput">
									{t("Full Name")}
								</label>
								<br />
								<input
									required
									type="text"
									name="name"
									value={contactMessage.name}
									onChange={handlechange}
									className="contactus-inputfields-enames abtinput"
									placeholder="Full Name"
								/>
							</div>

							<div className="contactus-inputfields-firstrow-block">
								<label className="contactus-inputfields-preinput">
									{t("Phone number")}
								</label>
								<br />
								<input
									required
									type="number"
									name="mobile"
									value={contactMessage.mobile}
									onChange={handlechange}
									className="contactus-inputfields-enames abtinput"
									placeholder="Phone Number"
								/>
							</div>
						</div>

						<label className="contactus-inputfields-preinput">{t("Email")}</label>
						<br />
						<input
							required
							type="email"
							name="email"
							value={contactMessage.email}
							onChange={handlechange}
							className="contactus-inputfields-email abtinput"
							placeholder="Email"
						/>
						<label className="contactus-inputfields-preinput">{t("Message")}</label>
						<br />
						<textarea
							required
							name="message"
							value={contactMessage.message}
							onChange={handlechange}
							className="contactus-inputfields-message abtinput"
							placeholder="Message"
						/>
						<button type="submit" className="contactus-inputfields-btn">
							{t("Send")}
						</button>
					</div>
				</form>
				{loading}
				{message}
				<div className="contactus-info">
					<div className="contactus-info-support">
						<h5 className="contactus-info-support-title">{t("Call or Whatsapp")}</h5>
						<p className="contactus-info-support-number">+255 677 123 255</p>
						<p className="contactus-info-support-number">+255 677 255 115</p>
					</div>
					<div className="contactus-info-support">
						<h5 className="contactus-info-support-title">{t("Working Hours")}</h5>
						<p className="contactus-info-support-number">02:00 am - 4:00 pm</p>
						<p className="contactus-info-support-number">02:00 am - 4:00 pm</p>
					</div>
				</div>
			</section>
			<Footer />
		</div>
	);
}
