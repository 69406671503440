import React, { useState } from "react";
import Typeofplay from "../../components/Typeofplay/Typeofplay";
import Datafile from "../../data";
import { useTranslation } from "react-i18next";

export default function Howtoplay() {

	const { t } = useTranslation('translations');

	const [visible, setVisible] = useState(null);
	const [showmenu, setShowmenu] = useState(true);
	const [text, setText] = useState({});

	const handleClick = (pagestate) => {
		if (visible == null) {
			setVisible(pagestate);
			setShowmenu(false);
		} else {
			setVisible(null);
			setShowmenu(true);
		}
		Object.keys(Datafile.spinwin).forEach(function (key) {
			if (key === pagestate) {
				var value = Datafile.spinwin[key];
				setText(value)
			}
		});
	};

	return (
		<>
			<div className={showmenu ? "htpcontainer" : "nohtpcontainer"} >
				<a
					className="htpcontainer-link spinwinn"
					href="# "
					onClick={() => handleClick("playonline")}
				>
					{t("Play Online")}
				</a>

				<a
					className="htpcontainer-link spinwinn"
					href="# "
					onClick={() => handleClick("playtigo")}
				>
					{t("Play with Tigo")}
				</a>

				<a
					className="htpcontainer-link spinwinn"
					href="# "
					onClick={() => handleClick("playvodacom")}
				>
					{t("Play with Vodacom")}
				</a>

				<a
					className="htpcontainer-link spinwinn"
					href="# "
					onClick={() => handleClick("playairtel")}
				>
					{t("Play with Airtel")}
				</a>

				<a
					className="htpcontainer-link spinwinn"
					href="# "
					onClick={() => handleClick("playhalotel")}
				>
					{t("Play with Halotel")}
				</a>

			</div>
			<div className={showmenu ? 'nohtpcontainer' : ''}>
				<Typeofplay handleClick={handleClick} visible={visible} text={text} />
			</div>

		</>
	);
}