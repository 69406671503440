import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "../../components/Header/Header-old";
import Footer from "../../components/Footer/Footer";
import Staticpages from "./Staticpages";
import { pages_user } from "../../Api";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";
import i18n from '../../i18n';

export default function Terms() {

	const { t } = useTranslation('translations');

	const [termscondition, settermscondition] = useState(null);
	useEffect(() => {

		ReactGA.pageview('/terms');
		axios.get(pages_user("terms_and_conditions")).then((response) => {
			console.log('TRANSS', response);
			settermscondition(response.data.items[0].content);
		});
	}, [i18n.language]);

	return (
		<>
			<div className="staticpages-backgroundColor">
				<Header />
				<Staticpages
					title={t("Terms and conditions")}
					text={<div dangerouslySetInnerHTML={{ __html: termscondition }} />}
				/>
				<Footer />
			</div>
		</>
	);
}
