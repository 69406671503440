import React, { useEffect, useState } from "react";
import mojaspeeshologo from '../../assets/images/mojaspeesho-logo.png'
import { useSelector, useDispatch } from 'react-redux';
import * as actions from "../../store/actions/index";
import Loader from "../../components/Loader/Loader";
import Nogame from "../../components/Nogame/Nogame";
import axios from "axios";
import { pick3Draws } from "../../Api";
import Returnmessage from "../../components/Returnmessage/Returnmessage";
import { useTranslation } from "react-i18next";

function Pick3Game() {

    const { t } = useTranslation('translations');

    const [number1, setNumber1] = useState('');
    const [number2, setNumber2] = useState('');
    const [number3, setNumber3] = useState('');
    const [stake, setStake] = useState(0);
    const [minStake, setMinStake] = useState(0);
    const [maxStake, setMaxStake] = useState(0);

    const [message, setMessage] = useState(null);

    const dispatch = useDispatch();

    const ticketMessage = useSelector(state => state.auth.pick3Message);
    const statuscall = useSelector((state) => state.auth.pick3status);
    const isAuthenticated = useSelector(state => state.auth.token);
    const pickloading = useSelector(state => state.auth.pickloading);

    useEffect(async () => {
        await axios(pick3Draws(1, 0, false)).then((res) => {
            if (res.data.status == 1) {
                setMinStake(res.data.draw.minStake);
                setMaxStake(res.data.draw.maxStake);
                setStake(res.data.draw.minStake);
            }
        })
    }, []);

    useEffect(() => {
        return (setMessage(null));
    }, []);

    const decreaseStake = () => {
        if (stake <= minStake) {
            return;
        } else {
            setStake(stake - minStake);
        }
    };

    const increaseStake = () => {
        if (stake + minStake <= maxStake)
            setStake(stake + minStake);
    };

    const re = /^[0-9\b]+$/;

    function handleChangeNumber(event, number, next) {

        var value = event.target.value;
        const maxLength = 1;

        if (value.length > maxLength) {
            re.test(value[value.length - 1]) ?
                value = value[value.length - 1]
                :
                value = value.substr(0, maxLength);
        }

        if (value === '' || re.test(value)) {
            if (number === 'number1') { setNumber1(value); }
            if (number === 'number2') { setNumber2(value); }
            if (number === 'number3') { setNumber3(value); }
            if (next !== "" && value !== '') {
                document.getElementById(next).focus();
            }
        }
    }

    function btnPurchasePick3(event) {
        event.preventDefault();
        let numbers = number1 + ',' + number2 + ',' + number3;
        dispatch(actions.purchasePick3(stake, numbers, localStorage.getItem('token'), true));
    }

    useEffect(() => {
        setMessage(ticketMessage)
    }, [ticketMessage])

    function generateRandom(event) {
        event.preventDefault();
        const min = 0;
        const max = 9;
        const rand1 = min + Math.random() * (max - min);
        const rand2 = min + Math.random() * (max - min);
        const rand3 = min + Math.random() * (max - min);

        setNumber1(Math.round(rand1));
        setNumber2(Math.round(rand2));
        setNumber3(Math.round(rand3));
    }

    let loader = false;
    if (pickloading) {
        loader = <Loader />;
    }

    return (
        <>

            <div className="instagame-wrapper">
                <div className="instagame-wrapper__head">
                    <img className="instagame-wrapper__head-logo" src={mojaspeeshologo} alt='mojaspeesho logo' />
                    <p className="instagame-wrapper__head-desc">
                        {t("Win up to 100x your stake")}<br></br>{t("if you get 1, 2 or 3 numbers right")}!
                    </p>
                </div>

                {isAuthenticated ?
                    <>
                        <div className="instagame-wrapper__inputFields">
                            <input className="instagame-wrapper__input" autoComplete="off" required onChange={(e) => handleChangeNumber(e, "number1", "num2")} value={number1} id="num1" min="0" max="9" />
                            <input className="instagame-wrapper__input" autoComplete="off" required onChange={(e) => handleChangeNumber(e, "number2", "num3")} value={number2} id="num2" min="0" max="9" />
                            <input className="instagame-wrapper__input" autoComplete="off" required onChange={(e) => handleChangeNumber(e, "number3", "")} value={number3} id="num3" min="0" max="9" />
                        </div>
                        <div className="instagame-wrapper__randomnbr">
                            <a onClick={generateRandom} href="#" className="instagame-wrapper__randomLabel"><span>{t("Choose Random")}</span></a>
                        </div>
                        <div className="instagame-wrapper__price pick3preinput">
                            <p className="instagame-wrapper__price-preinput">{t("Your stake")}</p>
                        </div>
                        <div className="instagame-wrapper__counter pick3counter">
                            <div
                                className="instagame-wrapper__counter-container playcounter"
                                onClick={decreaseStake}
                            >
                                -
                            </div>
                            <div className="instagame-wrapper__counter-container  pick3stake">{stake} {process.env.REACT_APP_CURRENCY}</div>
                            <div
                                className="instagame-wrapper__counter-container playcounter"
                                onClick={increaseStake}
                            >
                                +
                            </div>
                        </div>
                        <button className="instagame-wrapper__purchasebtn pick3btn" onClick={btnPurchasePick3}>{t("Purchase")}</button>
                        {loader}
                        {statuscall && statuscall === 1 ?
                            <Returnmessage content={message} type={"success"} />
                            : statuscall === 0 ?
                                <Returnmessage content={message} type={"error"} />
                                : ''
                        }
                    </>
                    : <Nogame />}
            </div>
        </>
    );
}

export default Pick3Game;
