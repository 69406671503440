import React, { useEffect, useState } from "react";
import winbox from '../../assets/images/winbox.png';
import openwinbox from '../../assets/images/openwinbox.png';
import { scratchTypesApi } from "../../Api";
import axios from "axios";
import { useTranslation } from "react-i18next";

export default function HowToWinBox(props) {

	const { t } = useTranslation('translations');

	const [scratchphoto, setscratchphoto] = useState([]);
	const [active, setActive] = useState("");

	useEffect(() => {
		console.log("props.gameid", props.gameid);
		axios
			.get(scratchTypesApi(props.gameid, "", 100, 0))
			.then((response) => {
				console.log("stats", response);
				setscratchphoto(response.data.ticketPrices);
				console.log("images", response.data.ticketPrices);
			})
			.catch(function (error) {
				console.log(error);
			});
	}, []);

	return (
		<div className="winbox-container" onClick={() => { console.log('ACTIVE = ', active); active == '' ? setActive('active') : setActive('') }}>
			{console.log('ACTIVE = ', active)}
			<div className={"winbox " + active}>
				<img src={winbox} className="winbox__closed" />
				<img src={openwinbox} className="winbox__open" />
				<span className="winbox__tooltip">{t("How to win")}</span>
			</div>
			<div className="winbox__table">
				<h3>{props.titledisplay}</h3>
				{scratchphoto.map((image, index) => (
					<div>
						<span><img src={image.imageUrl} className={props.class ? props.class : ''} /></span>
						<span className="winbox__table-spann">{image.prize}</span>
					</div>
				))}
			</div>
		</div>
	)
}
