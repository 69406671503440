import React, { useState } from "react";
import axios from "axios";
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { walletTransactionAmountApi } from "../../Api";
import Loader from "../Loader/Loader";
import Returnmessage from "../../components/Returnmessage/Returnmessage";
import * as actions from "../../store/actions/index";
import Balanceamount from "../Balanceamount/Balanceamount";
import { useTranslation } from "react-i18next";

export default function Cashout() {

    const { t } = useTranslation('translations');
    const Navigate = useNavigate();
    const [walletcashout, setwalletcashout] = useState({
        cashoutAmount: null,
        message: null,
        loading: null,
        status: "",
    });

    const dispatch = useDispatch();

    const handlechange = (e) => {
        const { name, value } = e.target;
        setwalletcashout({
            ...walletcashout,
            [name]: value,
        });
    }

    const Cashoutamountfct = (e) => {
        e.preventDefault();
        const cashoutData = {
            token: localStorage.getItem('token'),
            amount: parseFloat(walletcashout.cashoutAmount),
        };
        setwalletcashout({
            ...walletcashout,
            loading: <Loader />
        });
        axios.patch(walletTransactionAmountApi, cashoutData)
            .then(response => {
                setwalletcashout({
                    ...walletcashout,
                    loading: null,
                });
                dispatch(actions.updateWallet());
                if (response.data.status === 1) {
                    setwalletcashout({
                        ...walletcashout,
                        message: response.data.message,
                        loading: null,
                        status: 1,
                    });
                } else {
                    setwalletcashout({
                        ...walletcashout,
                        message: response.data.message,
                        loading: null,
                        status: 0,
                    });
                }
            })
            .catch(fresponse => {
                setwalletcashout({
                    ...walletcashout,
                    message: fresponse.data.message,
                    loading: null,
                });
            });
    };
    let messagee = null;
    if (walletcashout.status === 1) {
        messagee = <Returnmessage content={walletcashout.message} type={"success"} />;
    } else {
        messagee = <Returnmessage content={walletcashout.message} type={"error"} />;
    }

    return (
        <div className="wallet-container">
            <div className="wallet-container-title">{t('Cashout')}</div>
            <form onSubmit={Cashoutamountfct}>
                <input
                    name="cashoutAmount"
                    value={walletcashout.cashoutAmount}
                    onChange={handlechange}
                    required
                    className="wallet-container-cashout"
                    type="number"
                    placeholder={"0.00 " + process.env.REACT_APP_CURRENCY}
                />
                <div className="wallet-container-actionbtn">
                    <button className="wallet-container-actionbtn-cancel" type="button" onClick={() => Navigate("/")}>{t('Cancel')}</button>
                    <button className="wallet-container-actionbtn-save" type="submit">{t('save')}</button>
                </div>
            </form>
            {walletcashout.loading}
            {messagee}
        </div>
    );
}
