import React, { useState } from "react";
import Confetti from 'react-confetti';
import l7Logo from "../../assets/images/l7-logo.png";
import { useTranslation } from "react-i18next";

let option;
let random;
let previousRandom;

export default function DemoPlay() {

    const { t } = useTranslation('translations');

    const [winning, setWinning] = useState(false);
    const [winningValue, setWinningValue] = useState(0);
    const [winningValueTemp, setWinningValueTemp] = useState(0);
    const [firstAttempt, setFirstAttempt] = useState(0);
    const [disable, setDisable] = useState(null);
    const [winningExtra, setWinningExtra] = useState(false);
    const [winningExtraTemp, setWinningExtraTemp] = useState(false);
    const [played, setPlayed] = useState(false);

    const L7options = [
        { winningValue: 'L7-1', prize: '1,000.00' },
        { winningValue: 'L7-2', prize: '1,900.00' },
        { winningValue: 'L7-3', prize: '2,800.00' },
        { winningValue: 'L7-4', prize: '3,700.00' },
        { winningValue: 'L7-5', prize: '4,600.00' },
        { winningValue: 'L7-6', prize: '5,500.00' },
        { winningValue: 'losing', prize: '0' },
        { winningValue: 'losing-1', prize: '0' },
        { winningValue: 'losing-2', prize: '0' },
        { winningValue: 'losing-3', prize: '0' }];

    const getTicket = () => {
        while (random === previousRandom) {
            random = Math.floor(Math.random() * L7options.length);
        }
        option = L7options[random];
        previousRandom = random;

        setWinningValue(option.winningValue);

        if (option.winningValue == winningValue) {
            setWinningExtraTemp(!winningExtra)
        }
        if (option.prize != '0') {
            setWinning(true);
            setWinningValueTemp(option.winningValue);
        }
        else {
            setWinning(false);
            setWinningValueTemp('losing');
        }
    }

    const spinWheel = (event) => {
        event.preventDefault();

        setDisable('disable')
        setWinningValue(winningValueTemp);
        setWinningExtra(winningExtraTemp);
        setTimeout(() => (setDisable(null), setPlayed(true)), 6000);
        setFirstAttempt(1)
        getTicket();
    }


    let extra = '';
    if (winningExtra) {
        extra = 'extra';
    }
    let winningValues = '';
    if (firstAttempt != 0) {
        winningValues = winningValue;
    }

    return (
        <div className="flip lucky7">

            <h3 className="flip__subtitle">{t("Play for fun")}</h3>
            <div className="flip__logo">
                <img src={l7Logo} alt="Spin and win logo" />
            </div>
            <div className="flip__container">
                <div className="slot-container">
                    <div className="slot-machine">
                        {/* {console.log('OPTION = ', option)}
                        {console.log('WINNING VALUES = ', winningValues)}
                        {console.log('EXTRA = ', extra)} */}
                        <div className={winningValues + ' slot-1 ' + extra}></div>
                        <div className={winningValues + ' slot-2 ' + extra}></div>
                        <div className={winningValues + ' slot-3 ' + extra}></div>
                    </div>
                </div>
                {played && !disable && winning ?
                    <>
                        <div className="flip__winnings">
                            <label className="flip__winningLabel demo">{t("You won")}</label>
                        </div>
                        <Confetti />
                    </>
                    : played && !disable && !winning ?
                        <div className="flip__losing">
                            <label className="flip__losingLabel">{t("Try your luck the next time")}!</label>
                        </div>
                        : ''
                }
                <div className="btn-container">
                    <button className="flip__btn" disable={disable ? "disable" : ''} onClick={spinWheel}>{t("Play")}</button>
                </div>
            </div>
            <div className="flip__ticketsInfo">
                <a className="flip__btnreal" href="/lucky7">{t("Play for real")}</a>
            </div>
        </div>
    );
}

