import React, { useState } from "react";
import Typeofplay from "../../components/Typeofplay/Typeofplay";
import Datafile from "../../data";
import { useTranslation } from "react-i18next";

export default function HowToPlay() {

    const { t } = useTranslation('translations');

    const [visible, setVisible] = useState(null);
    const [showmenu, setShowmenu] = useState(true);
    const [text, setText] = useState({});

    const handleClick = (pagestate) => {
        if (visible == null) {
            setVisible(pagestate);
            setShowmenu(false);
        } else {
            setVisible(null);
            setShowmenu(true);
        }
        Object.keys(Datafile.cash5).forEach(function (key) {
            console.log(key)

            if (key === pagestate) {
                var value = Datafile.cash5[key];
                setText(value)
            }
        });
    };

    return (
        <>
            <div className={showmenu ? "htpcontainer" : "nohtpcontainer"} >
                <a
                    className="htpcontainer-link cash5"
                    href="# "
                    onClick={() => handleClick("playonline")}
                >
                    {t("Play Online")}
                </a>

                <a
                    className="htpcontainer-link cash5"
                    href="# "
                    onClick={() => handleClick("playtigo")}
                >
                    {t("Play with Tigo")}
                </a>

                <a
                    className="htpcontainer-link cash5"
                    href="# "
                    onClick={() => handleClick("playvodacom")}
                >
                    {t("Play with Vodacom")}
                </a>

                <a
                    className="htpcontainer-link cash5"
                    href="# "
                    onClick={() => handleClick("playairtel")}
                >
                    {t("Play with Airtel")}
                </a>

                <a
                    className="htpcontainer-link cash5"
                    href="# "
                    onClick={() => handleClick("playhalotel")}
                >
                    {t("Play with Halotel")}
                </a>

            </div>
            <div className={showmenu ? 'nohtpcontainer' : ''}>
                <Typeofplay handleClick={handleClick} visible={visible} text={text} />
            </div>

        </>
    );
}
