export const AUTH_START = 'AUTH_START';
export const AUTH_START2 = 'AUTH_START2';
export const VER_START = 'VER_START';
export const VER_FAIL = 'VER_FAIL';
export const REG_START = 'REG_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const AUTH_REGISTER = 'AUTH_REGISTER';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_FAIL2 = 'AUTH_FAIL2';
export const AUTH_REG_FAIL = 'AUTH_REG_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_VERIFY_MSISDN = 'AUTH_VERIFY_MSISDN';
export const VERIFY_SUCCESS = 'VERIFY_SUCCESS';
export const REDIRECT_VERIFY = 'REDIRECT_VERIFY';
export const DISPLAY_WARNING = 'DISPLAY_WARNING';
export const DISPLAY_SUCCESS = 'DISPLAY_SUCCESS';
export const UPDATE_MESSAGE = 'UPDATE_MESSAGE';
export const UPDATE_WALLET = 'UPDATE_WALLET';
export const UPDATE_PICK_MESSAGE = 'UPDATE_PICK_MESSAGE';
export const UPDATE_CASH_MESSAGE = 'UPDATE_CASH_MESSAGE';
export const SET_UNPLAYED = 'SET_UNPLAYED';
export const UPDATE_590_MESSAGE = 'UPDATE_590_MESSAGE';
export const UPDATE_MESSAGE_AF = 'UPDATE_MESSAGE_AF';
export const UPDATE_MESSAGE_AE = 'UPDATE_MESSAGE_AE';
export const UPDATE_MESSAGE_SW = 'UPDATE_MESSAGE_SW';
export const UPDATE_MESSAGE_L7 = 'UPDATE_MESSAGE_L7';
export const UPDATE_AVATAR = 'UPDATE_AVATAR';
export const PICK_LOADING = 'PICK_LOADING';
export const CASH5_LOADING = 'CASH5_LOADING';
export const SET_INSTA_LOADING = 'SET_INSTA_LOADING';
export const SET_AFRICAN_EXPLORER ='SET_AFRICAN_EXPLORER';
export const SET_AFRICAN_FRUIT ='SET_AFRICAN_FRUIT';
export const SET_SPIN_WIN ='SET_SPIN_WIN';
export const SET_LUCKY_7 ='SET_LUCKY_7';
export const BUY_START ='BUY_START';
export const BUY_END ='BUY_END';
export const SET_INACTIVE_L7 ='SET_INACTIVE_L7';
export const SET_INACTIVE_SW ='SET_INACTIVE_SW';
export const SET_INACTIVE_AE ='SET_INACTIVE_AE';
export const SET_INACTIVE_AF ='SET_INACTIVE_AF';
