import React from "react";
import { useTranslation } from "react-i18next";

export default function HowToWin() {

	const { t } = useTranslation('translations');

    return (
        <div className="cash5-howtowin tickets-container">
            <div className="table-container">
                <h2 className="cash5-howtowin-header">{t("How to win")}!</h2>
                <table className="filterable-table">
                    <thead>
                    <tr>
                        <th></th>
                        <th>Cash 1</th>
                        <th>Cash 2</th>
                        <th>Cash 3</th>
                        <th>Cash 4</th>
                        <th>Cash 5</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>1 {t("in order")}</td>
                        <td>5x</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>2 {t("in order")}</td>
                        <td></td>
                        <td>40x</td>
                        <td>8x</td>
                        <td>5x</td>
                        <td>3x</td>
                    </tr>
                    <tr>
                        <td>3 {t("in order")}</td>
                        <td></td>
                        <td></td>
                        <td>250x</td>
                        <td>40x</td>
                        <td>10x</td>
                    </tr>
                    <tr>
                        <td>4 {t("in order")}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>1000x</td>
                        <td>200x</td>
                    </tr>
                    <tr>
                        <td>5 {t("in order")}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>10000x</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}
