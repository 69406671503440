import React from "react";
import Scratchwincriteria from "../../components/Typeofplay/Scratchwincriteria";
import { useTranslation } from "react-i18next";

const gameid = 6;

function HowToWin() {

	const { t } = useTranslation('translations');

	return (
		<>
			<div className="howtowin-container">
				<div className="howtowin-container-title">
					{t("Find your animal")}!
				</div>
				<div className="howtowin-container-subtitle">
					{t("Get 3 of the same animal and win")}
				</div>
			</div>
			<Scratchwincriteria gameid={gameid} />
		</>
	);
}

export default HowToWin;