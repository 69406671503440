import React, { useEffect, useState } from "react";
import aelogo from "../../assets/images/ae-full-logo.png";
import Flippy, { FrontSide, BackSide } from "react-flippy";
import Confetti from "react-confetti";
import pic1 from "../../assets/images/african/1.png";
import pic2 from "../../assets/images/african/2.png";
import pic3 from "../../assets/images/african/3.png";
import pic4 from "../../assets/images/african/4.png";
import pic5 from "../../assets/images/african/5.png";
import pic6 from "../../assets/images/african/6.png";
import pic7 from "../../assets/images/african/7.png";
import pic8 from "../../assets/images/african/8.png";
import pic9 from "../../assets/images/african/9.png";
import { useTranslation } from "react-i18next";

function Funplay() {

	const { t } = useTranslation('translations');

	const [flipped, setFlipped] = useState([false, false, false, false, false, false, false, false, false,]);
	const [allflipped, setAllflipped] = useState(false);

	const shuffle = (arr) => [...arr].sort(() => Math.random() - 0.5);
	const [iswinning, setiswinning] = useState(Math.random() > 0.5 ? true : false);

	const [images, setimages] = useState([
		{ photo: pic1, value: "ae1" },
		{ photo: pic2, value: "ae2" },
		{ photo: pic3, value: "ae3" },
		{ photo: pic4, value: "ae4" },
		{ photo: pic5, value: "ae5" },
		{ photo: pic6, value: "ae6" },
		{ photo: pic7, value: "ae7" },
		{ photo: pic8, value: "ae8" },
		{ photo: pic9, value: "ae9" },
	]);

	const [data, setData] = useState(images);
	var j = 0;
	const [items, setitems] = useState(images[0]);
	const winningcase1 = images.filter((obj) => obj.photo !== items.photo);


	useEffect(() => {
		const itemss = images[Math.floor(Math.random() * images.length)];
		setitems(itemss);
		const array = [];
		if (!iswinning) {
			setData(shuffle(images));
		}
		else if (iswinning) {
			for (let i = 0; i < 3; i++) {
				array.push(items);
			}
			for (let i = 3; i < 9; i++) {
				array[i] = winningcase1[j];
				j++;
			}

			setData(shuffle(array))
		}
	}, [iswinning, items]);

	const flipall = () => {
		setFlipped([true, true, true, true, true, true, true, true, true]);
		setAllflipped(true);
	};

	const flipIndex = (index) => {
		const updatedIndexs = [...flipped];
		updatedIndexs[index] = true;
		setFlipped(updatedIndexs);
	};

	const playAgain = () => {
		setFlipped([false, false, false, false, false, false, false, false, false]);
		setAllflipped(false);
		setTimeout(() => {
			setiswinning(Math.random() > 0.5 ? true : false);
			setitems(images[Math.floor(Math.random() * images.length)]);
		}, 500);

	};

	useEffect(() => {
		let checker = (arr) => arr.every((v) => v === true);
		if (checker(flipped)) {
			setAllflipped(true);
		}
	}, [flipped]);

	return (
		<>
			<div className="flip">
				<div className="flip__logo">
					<img src={aelogo} alt="African Explorer logo" />
				</div>
				<h3 className="flip__subtitle">{t("Play for fun")}</h3>
				<div className="flip">


					<div className="flip__container">
						<div className={allflipped ? "flip__table winning " : "flip__table"}>
							{data.map((item, index) => (
								<div
									className={iswinning && items.value == item.value ? "flip__item winningItem" : "flip__item"}
									onClick={() => flipIndex(index)}
								>
									<Flippy flipDirection="horizontal" isFlipped={flipped[index]}>
										<FrontSide> </FrontSide>
										<BackSide>
											<img src={item.photo} />
										</BackSide>
									</Flippy>
								</div>
							))}
						</div>

						{allflipped && iswinning == true ? (
							<>
								<div className="flip__winnings">
									<label className="flip__winningLabel demo">{t("You won")}</label>
								</div>
								<Confetti />
							</>
						) : (
							""
						)}
						{allflipped && iswinning == false ? (
							<div className="flip__losing">
								<label className="flip__losingLabel">
									{t("Try your luck the next time")}!
								</label>
							</div>
						) : (
							""
						)}
						{allflipped ? (
							<div className="btn-container">
								<button className="flip__btn" onClick={playAgain}>
									{t("Play")}
								</button>
							</div>
						) : (
							<div className="btn-container">
								<button className="flip__btn" onClick={flipall}>
									{t("Flip all")}
								</button>
							</div>
						)}

						<div className="flip__ticketsInfo">
							<a className="flip__btnreal" href="/africanexplorer">{t("Play for real")}</a>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Funplay;
