import React, { Component } from "react";
import * as actions from "../../store/actions";
import { connect } from "react-redux";
import { ticketsApi } from "../../Api";
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import Confetti from "react-confetti";
import HowToWinBox from "../../components/HowToWinBox/HowToWinBox";
import swLogo from "../../assets/images/sw-logo.png";
import wheel from "../../assets/images/wheel.png";
import DemoPlay from "./DemoPlay";
import { withTranslation } from "react-i18next";

class Play extends Component {

	constructor(props) {
		super(props);

		this.state = {
			ticketId: null,
			serialNumber: null,
			ticketValues: [],
			winning: false,
			celerate: false,
			prize: null,
			loading: false,
			buyloading: false,
			firstAttempt: 0,
			winningExtra: false,
			disable: '',
			winningValue: 0,
			winningprize: 0,
			played: false,
			tcktNbr: 0,
			gameid: 5,
			ticketMessage: null,
			statussw: this.props.statussw,
			buyingms: null
		};

		this.spinWheel = this.spinWheel.bind(this);
		this.addmapfct = this.addmapfct.bind(this);
		this.reducemapfct = this.reducemapfct.bind(this);
		this.getTicket = this.getTicket.bind(this);
	}

	componentDidMount() {
		this.setState({
			buyingms: null
		})
		this.props.onGetScratch(5);

		let url = ticketsApi(localStorage.getItem('token'), 5, 1000, '0&isFinished=0');
		axios.get(url)
			.then(response => {
				if (response.data.status === 1) {
					console.log('ticket info', response)
					this.setState({
						ticketId: response.data.tickets[0].ticketId,
						serialNumber: response.data.tickets[0].serialNumber,
						ticketValues: response.data.tickets[0].ticketValues,
						winningValue: response.data.tickets[0].winningValue,
						winningprize: response.data.tickets[0].prize,
					});
				}
				else {
					this.setState({
						loading: false,
					});
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	addmapfct() {
		this.setState({
			tcktNbr: this.state.tcktNbr + 1,
		});
	}

	reducemapfct() {
		if (this.state.tcktNbr === 0) return;
		else
			this.setState({
				tcktNbr: this.state.tcktNbr - 1,
			});
	}

	buyScratch(event) {
		event.preventDefault();
		this.setState({
			buyingms: null,
			played: false,
			buyloading: true,
			tcktNbr: 0,
		});
		this.props.onBuyScratch(parseFloat(this.state.tcktNbr), 5);
	}

	getTicket() {
		var losingClasses = new Array("losing1", "losing2", "losing3");
		var random = losingClasses[Math.floor(Math.random() * losingClasses.length)];
		let url = ticketsApi(localStorage.getItem('token'), 5, 1000, '0&isFinished=0');
		axios.get(url)
			.then(response => {
				this.setState({
					ticketId: response.data.tickets[0].ticketId,
					serialNumber: response.data.tickets[0].serialNumber,
					ticketValues: response.data.tickets[0].ticketValues,
					winningValue: response.data.tickets[0].winningValue,
					winningprize: response.data.tickets[0].prize,
				});

				if (response.data.tickets[0].winningValue == this.state.winningValue) {
					this.setState({
						winningExtra: !this.state.winningExtra,
					})

				}
				if (this.state.winningValue != 'losing') {
					this.setState({
						value: response.data.tickets[0].winningValue,
						celebrate: 1,
						prize: response.data.tickets[0].prize,
						winning: true
					})
				} else {
					this.setState({
						value: random,
						celebrate: 2,
						prize: null,
						winning: false
					})

				}

			})
			.catch(function (error) {
				console.log(error);
			});
		clearInterval()
	}

	spinWheel(event) {
		event.preventDefault();
		this.props.onUpdateMessagesw(null, 0)
		this.setState({
			buyingms: null,
			celebrate: null,
			disable: 'disable',

		});

		setTimeout(() => this.setState({ played: true, disable: '' }), 4000);
		this.props.onPlayTicket(this.props.gameId, this.state.ticketId, this.state.serialNumber, 5);

		this.setState({
			value: this.state.winningValue,
			firstAttempt: 1,
		});
		this.getTicket();
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.ticketMessageScratch !== prevState.ticketMessageScratch) {
			return { buyingms: nextProps.ticketMessageScratch };
		}
		else return null;
	}

	componentDidUpdate(prevProps) {
		if (prevProps.isAuthenticated !== this.props.isAuthenticated) {
			this.props.onGetScratch(5);
		}
	}

	componentWillUnmount() {
		this.setState({
			buyingms: null,
			loading: null
		});
		this.props.onUpdateMessagesw(null, 0)
	}

	render() {
		const { t } = this.props;
		let extraRotate = "";
		if (this.state.winningExtra) {
			extraRotate = "extra";
		}

		return (
			<div className="flip spinwin">
				{this.props.isAuthenticated ? (
					<>
						<div className="flip__unplayed">
							<span>{t("Available Tickets")}</span>
							<span className="flip__unplayedNbr">{this.props.unplayedTickets}</span>
						</div>
						<div className="flip__logo">
							<img src={swLogo} alt={t("Spin and win logo")} />
						</div>
						<h3 className="flip__subtitle">
							{t("Get lucky and hit the diamond to win big")}!
						</h3>
						{!this.props.inactiveSw ?
							<div className="flip__unplayed">
								<span>{t("Available Tickets")}</span>
								<span className="flip__unplayedNbr">{this.props.unplayedTickets}</span>
							</div> :
							''}
						{this.props.inactiveSw ?
							<>
								<h3 className="flip__subtitle">{t("Game unavailable at the moment")}!</h3>
								<br />
								<h3 className="flip__subtitle">{t("Sorry for the inconvenience, Please try again later")}!</h3>
								<a className="flip__btnfun" href="/lucky7?page=demoplay">
									{t("Play for fun")}
								</a>
							</>
							:
							<div className="flip__container">
								<HowToWinBox
									gameid={this.state.gameid}
									titledisplay={t("Get lucky and hit the diamond to win big") + "!"}
									class="spinwin"
								/>
								<div className="wheel-container">
									<div className="weel-circle">
										<img src={wheel} className={this.state.value + " " + extraRotate} />
									</div>
									<div className="weel-base"></div>
								</div>
								{this.state.played && !this.state.disable && this.state.winning ? (
									<>
										<div className="flip__winnings">
											<label className="flip__winningLabel">{t("You won")}</label>
											<span className="flip__winningValue">
												{this.state.prize} {process.env.REACT_APP_CURRENCY}
											</span>
										</div>
										<Confetti />
									</>
								) : this.state.played && !this.state.disable && !this.state.winning ? (
									<div className="flip__losing">
										<label className="flip__losingLabel">
											{t("Try your luck the next time")}!
										</label>
									</div>
								) : (
									<div className="flip__losing-hidden">
										<label className="flip__losingLabel" />
									</div>
								)}
								{this.props.unplayedTickets !== 0 ? (
									<div className="btn-container">
										<button
											className="flip__btn"
											disabled={this.state.disable ? "disable" : ""}
											onClick={this.spinWheel}
										>
											{t("Play")}
										</button>
									</div>
								) : (
									<span className="flip__purchase">
										{t("You have 0 tickets, purchase to play again")}
									</span>
								)}
								<div className="flip__ticketsInfo">
									<div className="flip__ticketPrice">
										<label>{t("Ticket Price")}</label>
										<span>
											{this.props.ticketPrice} {process.env.REACT_APP_CURRENCY}{" "}
										</span>
									</div>
									<div className="flip__buy">
										<div className="flip__buy__buymapbtn" onClick={this.reducemapfct}>
											-
										</div>
										<input
											type="number"
											name="tcktNbr"
											value={this.state.tcktNbr}
											onChange={e => this.setState({ tcktNbr: e.target.value })}
											placeholder="0"
											className="flip__buy__input"
										/>
										<div className="flip__buy__buymapbtn" onClick={this.addmapfct}>
											+
										</div>
									</div>
									<button
										className="flip__buy__btn"
										onClick={(event) => this.buyScratch(event)}
									>
										{t("Buy")}
									</button>
									{this.props.loadingScratchBuy ? <Loader /> : ""}
									{this.state.buyingms && !this.props.loadingScratchBuy ?
										<span className="flip__buy__msg">{this.state.buyingms}</span> : ''}
									<a className="flip__btnfun" href="/spinwin?page=funplay">
										{t("Play for fun")}
									</a>
								</div>
							</div>
						}
					</>
				) : (
					<DemoPlay />
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		isAuthenticated: state.auth.token !== null,
		wallet: state.auth.wallet,
		gameId: state.auth.swid,
		maxPrize: state.auth.sw_maxPrize,
		unplayedTickets: state.auth.unplayed_sw,
		ticketMessageScratch: state.auth.ticketMessagesw,
		statussw: state.auth.statussw,
		ticketPrice: state.auth.sw_ticketPrice,
		scratch_prizeValues: state.auth.sw_prizeValues,
		loadingScratchBuy: state.auth.loadingScratchBuy,
		inactiveSw: state.auth.inactiveSw
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onTryAutoSignup: () => dispatch(actions.authCheckState()),
		onGetScratch: (gametype) => dispatch(actions.getScratch(gametype)),
		onBuyScratch: (ticketNb, scratchType) => dispatch(actions.buyScratch(ticketNb, scratchType)),
		onPlayTicket: (gameId, ticketId, serialNumber, scratchType) => dispatch(actions.playTicket(gameId, ticketId, serialNumber, scratchType)),
		onUpdateMessagesw: (message, status) => dispatch(actions.updateMessagesw(message, status)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("translations")(Play));