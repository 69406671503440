import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Sidemenu from '../../components/Sidemenu/Sidemenu';
import Pick3Game from './Pick3Game';
import HowToPlay from './Howtoplay';
import Howtowin from "./Howtowin";
import Countdown from '../../components/Countdown/Countdown';
import Tickets from '../../components/Tickets/Tickets';
import Footer from '../../components/Footer/Footer';
import { pick3Draws } from '../../Api';
import HeaderOld from '../../components/Header/Header-old';
import Nogame from '../../components/Nogame/Nogame';
import ReactGA from "react-ga";

const gameType = 'pick3';

const pages = [{ title: 'play', name: 'play', content: <Pick3Game /> },
{ title: 'how to play', name: 'howtoplay', content: <HowToPlay /> },
{ title: 'how to win', name: 'howtowin', content: <Howtowin /> },
{ title: 'purchases', name: 'purchases', content: <Tickets gameType={gameType} pageName="purchases" /> },
{ title: 'my winnings', name: 'winnings', content: <Tickets gameType={gameType} pageName="winnings" /> },
{ title: 'winners', name: 'winners', content: <Tickets gameType={gameType} pageName="winners" /> }];

export default function Pick3() {

    useEffect(() => {
        ReactGA.pageview('/mojaspesho');
    }, [])
    
    const isAuthenticated = useSelector(state => state.auth.token);
    let page;
    let [searchParams, setSearchParams] = useSearchParams();
    let tab = searchParams.get('page')

    if (tab) {
        switch (tab) {
            case 'play':
                page = <Pick3Game />;
                break;
            case 'howtoplay':
                page = <HowToPlay />;
                break;
            case 'howtowin':
                page = <Howtowin />;
                break;
            case 'purchases':
                {
                    if (isAuthenticated != null) {
                        page = <Tickets gameType={gameType} pageName="purchases" />;
                    }
                    else {
                        page = <Nogame />;
                    }
                }
                break;
            case 'winnings':
                if (isAuthenticated != null) {
                    page = <Tickets gameType={gameType} pageName="winnings" />;
                }
                else {
                    page = <Nogame />;
                }
                break;
            case 'winners':
                page = <Tickets gameType={gameType} pageName="winners" />;
                break;
            default:
                page = <Pick3Game />;
        }
    }

    return (
        <>
            <HeaderOld />
            <div className="gameinsta-container pick3bgcontainer">
                <Countdown url={pick3Draws(1, 0, false)} gameType={gameType} />
                <Sidemenu page={page} pages={pages} gameType={gameType} />
            </div>
            <Footer />
        </>
    );
}
