import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header-old";
import Footer from "../../components/Footer/Footer";
import Staticpages from "./Staticpages";
import ReactGA from "react-ga";
import { pages_user } from "../../Api";
import axios from "axios";
import { useTranslation } from "react-i18next";

export default function About() {

	const { t } = useTranslation('translations');

	const [aboutus, setaboutus] = useState(null);

	useEffect(() => {
		ReactGA.pageview('/aboutus');
		axios.get(pages_user("about_us")).then((response) => {
			setaboutus(response.data.items[0].content);
		});
	}, []);

	return (
		<>
			<div className="staticpages-backgroundColor">
				<Header />
				<Staticpages
					title={t("About Us")}
					text={<div dangerouslySetInnerHTML={{ __html: aboutus }} />}
				/>
				<Footer />
			</div>
		</>
	);
}
