import React from "react";
import Tickets from "../Tickets/Tickets";
import Balanceamount from "../Balanceamount/Balanceamount";

export default function Transactions() {
    
    return (
        <>
            <Balanceamount />
            <Tickets pageName="transaction" headerVisible={false} />
        </>
    );
}
