import React, { useState, useEffect } from "react";
import axios from "axios";
import { pick3TicketsApi, pick3Draws, instalottoWinners, instalottoTicketsApi, promoWalletApi, walletticketsApi, walletTransactionApi, scratchticketapi, cash5TicketsApi, cash5Draws } from "../../Api";
import Balanceamount from "../Balanceamount/Balanceamount";
import Loader from "../Loader/Loader";
import { useTranslation } from "react-i18next";

const FilterableTable = require('react-filterable-table');

export default function Tickets(props) {

    const { t } = useTranslation('translations');

    const [data, setData] = useState([]);
    const [fields, setFields] = useState([]);
    const [headerVisible, setHeaderVisible] = useState(true);
    const [loading, setLoading] = useState(true)

    let gameType = props.gameType;
    let res;

    useEffect(() => {
        setHeaderVisible(props.headerVisible);
    }, [props.headerVisible]);

    useEffect(() => {
        getTickets(props.pageName);
    }, [props.pageName, props.gameType]);

    async function getTickets(pageName) {
        setLoading(true);
        switch (gameType) {
            case 'pick3':
                if (pageName) {
                    switch (pageName) {
                        case 'purchases':
                            res = await axios(pick3TicketsApi(localStorage.getItem('token'), 100, 0, ''));
                            if (res.data.tickets) {
                                setData(await res.data.tickets);
                                setLoading(false)
                            }

                            setFields([
                                { name: 'drawId', displayName: t("Draw Id"), inputFilterable: true, sortable: true },
                                { name: 'date', displayName: t("Date"), inputFilterable: true, exactFilterable: true, sortable: true },
                                { name: 'numbers', displayName: t("Numbers"), inputFilterable: true, exactFilterable: true, sortable: true }
                            ]);
                            break;
                        case 'winnings':
                            res = await axios(pick3TicketsApi(localStorage.getItem('token'), 100, 0, true));
                            if (res.data.tickets) {
                                setData(await res.data.tickets);
                                setLoading(false)
                            }
                            setFields([
                                { name: 'drawId', displayName: t("Draw Id"), inputFilterable: true, sortable: true },
                                { name: 'date', displayName: t("Date"), inputFilterable: true, exactFilterable: true, sortable: true },
                                { name: 'numbers', displayName: t("Numbers"), inputFilterable: true, exactFilterable: true, sortable: true }
                            ]);
                            break;
                        case 'winners':
                            res = await axios(pick3Draws(100, 0, true));
                            if (res.data.draws) {
                                setData(await res.data.draws);
                                setLoading(false)
                            }
                            setFields([
                                { name: 'id', displayName: t("Draw Id"), inputFilterable: true, sortable: true },
                                { name: 'date', displayName: t("Date"), inputFilterable: true, exactFilterable: true, sortable: true },
                                { name: 'winningNumbers', displayName: t("Numbers"), inputFilterable: true, exactFilterable: true, sortable: true }
                            ]);
                            break;
                    }
                }
                break;

            case 'Instaloto':
                if (pageName) {
                    switch (pageName) {
                        case 'purchases':
                            res = await axios(instalottoTicketsApi(localStorage.getItem('token'), 100, 0, ''));
                            if (res.data.tickets) {
                                setData(await res.data.tickets);
                                setLoading(false)
                            }
                            setFields([
                                { name: 'ticketId', displayName: t("Ticket Id"), inputFilterable: true, sortable: true },
                                { name: 'date', displayName: t("Date"), inputFilterable: true, exactFilterable: true, sortable: true },
                                { name: 'winnings', displayName: t("Winnings"), inputFilterable: true, exactFilterable: true, sortable: true }
                            ]);
                            break;
                        case 'winnings':
                            res = await axios(instalottoTicketsApi(localStorage.getItem('token'), 100, 0, true));
                            if (res.data.tickets) {
                                setData(await res.data.tickets);
                                setLoading(false)
                            }
                            setFields([
                                { name: 'ticketId', displayName: t("Ticket Id"), inputFilterable: true, sortable: true },
                                { name: 'date', displayName: t("Date"), inputFilterable: true, exactFilterable: true, sortable: true },
                                { name: 'winnings', displayName: t("Winnings"), inputFilterable: true, exactFilterable: true, sortable: true }
                            ]);
                            break;
                        case 'winners':
                            res = await axios(instalottoWinners(true, 0, 100));
                            if (res.data.tickets) {
                                setData(await res.data.tickets);
                                setLoading(false)
                            }
                            setFields([
                                { name: 'ticketId', displayName: t("Ticket Id"), inputFilterable: true, sortable: true },
                                { name: 'date', displayName: t("Date"), inputFilterable: true, exactFilterable: true, sortable: true },
                                { name: 'winnings', displayName: t("Winnings"), inputFilterable: true, exactFilterable: true, sortable: true }
                            ]);
                            break;
                    }
                }
                break;

            case 'scratch':
                if (pageName) {
                    switch (pageName) {
                        case 'purchases':
                            res = await axios(scratchticketapi(localStorage.getItem('token'), props.gameId, '', 0, 100));
                            if (res.data.tickets) {
                                setData(await res.data.tickets);
                                setLoading(false)
                            }
                            setFields([
                                { name: 'id', displayName: t("id"), inputFilterable: true, sortable: true },
                                { name: 'purchaseDate', displayName: t("purchaseDate"), inputFilterable: true, exactFilterable: true, sortable: true },
                                { name: 'price', displayName: t("price"), inputFilterable: true, exactFilterable: true, sortable: true },
                            ]);
                            break;
                        case 'winnings':
                            res = await axios(scratchticketapi(localStorage.getItem('token'), props.gameId, true, 0, 100));
                            if (res.data.tickets) {
                                setData(await res.data.tickets);
                                setLoading(false)
                            }
                            setFields([
                                { name: 'id', displayName: t("id"), inputFilterable: true, sortable: true },
                                { name: 'purchaseDate', displayName: t("purchaseDate"), inputFilterable: true, exactFilterable: true, sortable: true },
                                { name: 'price', displayName: t("price"), inputFilterable: true, exactFilterable: true, sortable: true },
                                { name: 'prize', displayName: t("prize"), inputFilterable: true, exactFilterable: true, sortable: true }
                            ]);
                            break;
                    }
                }
                break;

            case 'cash5':
                if (pageName) {
                    switch (pageName) {
                        case 'purchases':
                            res = await axios(cash5TicketsApi(localStorage.getItem('token'), 1000, 0, ''));
                            if (res.data.tickets) {
                                setData(await res.data.tickets);
                                setLoading(false)
                            }
                            setFields([
                                { name: 'ticketId', displayName: t("Reference"), inputFilterable: true, sortable: true },
                                { name: 'date', displayName: t("Date"), inputFilterable: true, exactFilterable: true, sortable: true },
                                { name: 'status', displayName: t("Status"), inputFilterable: true, exactFilterable: true, sortable: true }
                            ]);
                            break;
                        case 'winnings':
                            res = await axios(cash5TicketsApi(localStorage.getItem('token'), 1000, 0, true));
                            if (res.data.tickets) {
                                setData(await res.data.tickets);
                                setLoading(false)
                            }
                            setFields([
                                { name: 'ticketId', displayName: t("Reference"), inputFilterable: true, sortable: true },
                                { name: 'date', displayName: t("Date"), inputFilterable: true, exactFilterable: true, sortable: true }
                            ]);
                            break;
                        case 'winners':
                            res = await axios(cash5Draws(1000, 0, true));
                            if (res.data.draws) {
                                setData(await res.data.draws);
                                setLoading(false)
                            }
                            setFields([
                                { name: 'id', displayName: t("Draw Id"), inputFilterable: true, sortable: true },
                                { name: 'date', displayName: t("Date"), inputFilterable: true, exactFilterable: true, sortable: true },
                                { name: 'winningNumbers', displayName: t("Winning Numbers"), inputFilterable: true, exactFilterable: true, sortable: true }
                            ]);
                            break;
                    }
                }
                break;


            default:
                if (pageName) {
                    switch (pageName) {
                        case 'promo':
                            res = await axios(promoWalletApi(localStorage.getItem('token')));
                            if (res.data.transactions) {
                                setData(await res.data.transactions);
                                setLoading(false)
                            }
                            setFields([
                                { name: 'promotionId', displayName: t("Promotion Id"), inputFilterable: true, sortable: true },
                                { name: 'type', displayName: t("Type"), inputFilterable: true, exactFilterable: true, sortable: true },
                                { name: 'amount', displayName: t("Amount"), inputFilterable: true, exactFilterable: true, sortable: true },
                                { name: 'game', displayName: t("Game"), inputFilterable: true, exactFilterable: true, sortable: true },
                                { name: 'creationDate', displayName: t("Date"), inputFilterable: true, exactFilterable: true, sortable: true }
                            ]);
                            props.setBalance(res.data.balance);
                            props.setTime(res.data.reamainingTime);
                            props.setAmount(res.data.remainingAmount);
                            break;

                        case 'tickets':
                            <Balanceamount />
                            res = await axios(walletticketsApi(localStorage.getItem('token'), 1000, 0))
                            if (res.data.tickets) {
                                setData(await res.data.tickets);
                                setLoading(false)
                            }
                            setFields([
                                { name: 'id', displayName: t("Transaction id"), inputFilterable: true, sortable: true },
                                { name: 'amount', displayName: t("Amount staked"), inputFilterable: true, exactFilterable: true, sortable: true },
                                { name: 'ticketType', displayName: t("Type"), inputFilterable: true, exactFilterable: true, sortable: true },
                                { name: 'date', displayName: t("Date"), inputFilterable: true, exactFilterable: true, sortable: true },
                            ]);
                            break;
                        case 'transaction':
                            res = await axios(walletTransactionApi(localStorage.getItem('token'), 1000, 0))
                            if (res.data.transactions) {
                                setData(await res.data.transactions);
                                res.data.transactions.map((transaction) => {
                                    switch(transaction.type) {
                                        case "ticket_payout":
                                            transaction.type = t("Ticket Payout");
                                            break;
                                        case "ticket_purchase":
                                            transaction.type = t("Ticket Purchase");
                                            break;
                                        case "credit":
                                            transaction.type = t("Credit");
                                            break;
                                        case "cashout":
                                            transaction.type = t("Cashout");
                                            break;
                                        default:
                                            break;
                                    }
                                });
                                setData(res.data.transactions);
                                setLoading(false);
                            }
                            setFields([
                                { name: 'id', displayName: t("Id"), inputFilterable: true, sortable: true },
                                { name: 'amount', displayName: t("Amount"), inputFilterable: true, exactFilterable: true, sortable: true },
                                { name: 'type', displayName: t("Type"), inputFilterable: true, exactFilterable: true, sortable: true },
                                { name: 'date', displayName: t("Date"), inputFilterable: true, exactFilterable: true, sortable: true }
                            ]);
                            break;
                    }
                }
                break;

        }
    }

    return (
        <>
            {props.pageName === 'tickets' ?
                <Balanceamount />
                : ''}
            <div className="tickets-container ">
                {loading ? <Loader /> :
                    <FilterableTable
                        namespace="Tickets"
                        data={data}
                        fields={fields}
                        noRecordsMessage={t("There are no tickets to display")}
                        noFilteredRecordsMessage={t("No tickets match your filters!")}
                        pagerTitles={{ first: t("First"), last: t("Last") }}
                        headerVisible={headerVisible}
                        topPagerVisible={false}
                        pageSize={"20"}
                        recordCountName={t("Result")}
                        recordCountNamePlural={t("Results")} />
                }
            </div>
        </>
    );
}
