import React, { useContext } from "react";
import { ThemeContext } from "../../ThemeContext";

export const ThemeSwitch = ({ isOn, handleToggle }) => {
	const theme = useContext(ThemeContext);
	const darkMode = theme.state.darkMode;

	const changeTheme = () => {
		if (darkMode) {
			theme.dispatch({ type: "LIGHTMODE" });
		} else {
			theme.dispatch({ type: "DARKMODE" });
		}
	};

	return (
		<>
			<input
				checked={isOn}
				onChange={changeTheme}
				className="react-switch-checkbox"
				id={`react-switch-new`}
				type="checkbox"
			/>
			<label
				className="react-switch-label"
				htmlFor={`react-switch-new`}
			>
				<span className={`react-switch-button`} />
			</label>
		</>
	);
}
