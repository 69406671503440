import React from "react";
import { NavLink } from "react-router-dom";

const headerMenu = (props) => {
  return (
    <li className={"header-nav__menuitem "}>
      <NavLink
        to={props.link}
        activeClassName={"header-nav__menulink__active " }
        className={"header-nav__menulink " + props.classes}
      >
        <span>{props.title}</span>
      </NavLink>
    </li>
  );
};

export default headerMenu;
