import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShuffle, faXmark } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import * as actions from "../../store/actions/index";
import Loader from "../../components/Loader/Loader";
import Returnmessage from "../../components/Returnmessage/Returnmessage";
import { getCash5Prices, cash5Draws } from "../../Api";
import Nogame from "../../components/Nogame/Nogame";
import { useTranslation } from "react-i18next";
import i18n from '../../i18n';

export default function Cash5() {

    const { t } = useTranslation('translations');

    const [gameNb, setGameNb] = useState(1);
    const [selectedNumbers, setSelectedNumbers] = useState([]);
    const [isComb, setIsComb] = useState(false);
    const [comb, setComb] = useState(null);
    const [combSentence, setCombSentence] = useState(null);
    const [numsAdded, setNumsAdded] = useState(0);
    const [stake, setStake] = useState(0);
    const [minStake, setMinStake] = useState(0);
    const [maxStake, setMaxStake] = useState(0);
    const [price, setPrice] = useState(0);

    const [games] = useState(['active', '', '', '', '']);

    const dispatch = useDispatch();

    const ticketMessage = useSelector(state => state.auth.cash5Message);
    const statuscall = useSelector((state) => state.auth.cash5status);
    const isAuthenticated = useSelector(state => state.auth.token);
    const cash5loading = useSelector(state => state.auth.cash5loading);

    const setStakeDefaults = async () => {
        await axios(cash5Draws(1, 0, false)).then((res) => {
            if (res.data.status == 1) {
                setMinStake(res.data.draw.minStake);
                setMaxStake(res.data.draw.maxStake);
                setStake(res.data.draw.minStake);
                setPrice(res.data.draw.minStake);
            }
        })
    }

    const decreaseStake = () => {
        if (stake <= minStake) {
            return;
        } else {
            setStake(stake - minStake);
        }
    };

    const increaseStake = () => {
        if (stake + minStake <= maxStake)
            setStake(stake + minStake);
    };

    const selectGame = (gameNb) => {
        setGameNb(gameNb);
        setSelectedNumbers([]);
        setComb(null);
        setCombSentence(null);
        setIsComb(false);
        setNumsAdded(gameNb);

        // Set scss of active game
        games.map((game, key) => {
            key == gameNb - 1 ? games[key] = 'active' : games[key] = '';
        });
    }

    const clearSelection = (event) => {
        event.preventDefault();
        setSelectedNumbers([]);
        setComb(null);
        setCombSentence(null);
        setIsComb(false);
        setNumsAdded(gameNb);
        // loader = null;

        setStakeDefaults();
        setCombSentence('');
    }

    const addNumber = (num) => {
        const list = [];
        if (!isComb) {
            if (selectedNumbers.length < gameNb) {
                list.push(num);
                setSelectedNumbers([...selectedNumbers, list]);
            }
        } else {
            if ((gameNb == 1 && selectedNumbers.length < 3) ||
                (gameNb == 2 && selectedNumbers.length < 3) ||
                (gameNb == 3 && selectedNumbers.length < 4)) {
                list.push(num);
                setSelectedNumbers([...selectedNumbers, list]);
            }
        }
    }

    const generateRandom = (event) => {
        event.preventDefault();
        setSelectedNumbers(Array(numsAdded).fill().map(() => Math.round(Math.random() * 9)));
    }

    const addCombination = () => {
        let addLimit = 0;

        if (gameNb < 4) {
            setIsComb(true);
            gameNb > 1 ? addLimit = 1 : addLimit = 2;
        }

        setNumsAdded(gameNb + addLimit)
    }

    const purchase = (event) => {
        event.preventDefault();
        console.log('i18n.language ====', i18n.language);
        // loader = <Loader/>;
        let numbers = selectedNumbers.join(',');
        dispatch(actions.purchaseCash5(stake, numbers, gameNb, isComb));
        // loader = null;
    }

    // Set stake and price defaults
    useEffect(async () => {
        await setStakeDefaults();
    }, [gameNb]);

    // Set the price
    useEffect(async () => {
        let numbers = selectedNumbers.join(',');
        let url = getCash5Prices(gameNb, stake, numbers, isComb);
        axios.get(url)
            .then(response => {
                if (response.data.status == 1) {
                    setPrice(response.data.price);
                }
            })
            .catch(err => {
                console.log(err);
            });
    }, [stake, gameNb, selectedNumbers]);

    // Add combination
    useEffect(() => {
        let listItems = null;
        let initial = [];
        let unselected = null;
        let addition = null;

        // Add chosen numbers to combination
        listItems = selectedNumbers.map((number, index) =>
            <span className="cash5-game__selected-number" key={index}>{number}</span>
        );

        // Add initial '?' to combination
        let initialRemaining = gameNb - selectedNumbers.length;
        for (var i = 0; i < initialRemaining; i++) {
            initial.push(<span className="cash5-game__unselected-number">?</span>);
        }

        // Add extra '?' to combination based on chosen game and numbers
        if (isComb) {
            let remaining = 0;
            remaining = numsAdded - selectedNumbers.length;
            switch (remaining) {
                case 2:
                    unselected = <span>
                        <span className="cash5-game__unselected-number">?</span>
                        <span className="cash5-game__unselected-number">?</span>
                    </span>;
                    break;
                case 1:
                    unselected = <span className="cash5-game__unselected-number">?</span>;
                    break;
                case 0:
                    unselected = null;
                    break;
            }
        }
        else {
            // Add '+' to combination
            if (gameNb < 4 && selectedNumbers.length == gameNb) {
                addition = <span className="cash5-game__add-combination" onClick={addCombination}>+</span>;
            }
        }
        // Set the full combination
        setComb(<>{listItems}{addition}{initial}{unselected}</>)

    }, [selectedNumbers, isComb]);

    // Sets the comb sentence
    useEffect(() => {
        if (isComb && (gameNb < 4)) {
            if (gameNb > 1) {
                setCombSentence(<span className="cash5-game__comb-sentence">{t("Add 1 number")}</span>);
            }
            else {
                setCombSentence(<span className="cash5-game__comb-sentence">{t("Add up to 2 numbers")}</span>);
            }
        }
    }, [comb]);


    let loader = false;
    if (cash5loading) {
        loader = <Loader />;
    }

    return (
        <div className="cash5-game">
            {isAuthenticated ?
                <>
                    <h2 className="cash5-game__title">{t("Select your super numbers and win")}</h2>
                    <div className="cash5-game__type-label">{t("Select game type")}</div>
                    <div className="cash5-game__type-choices">
                        <button onClick={() => selectGame(1)} className={"cash5-game__choice-button " + games[0]}>Cash 1</button>
                        <button onClick={() => selectGame(2)} className={"cash5-game__choice-button " + games[1]}>Cash 2</button>
                        <button onClick={() => selectGame(3)} className={"cash5-game__choice-button " + games[2]}>Cash 3</button>
                        <button onClick={() => selectGame(4)} className={"cash5-game__choice-button " + games[3]}>Cash 4</button>
                        <button onClick={() => selectGame(5)} className={"cash5-game__choice-button " + games[4]}>Cash 5</button>
                    </div>

                    <div className="cash5-game__selection-section">

                        <h4 className="cash5-game__selection-title">{t("Select")} {gameNb} {gameNb === 1 ? t("number") : t("numbers")} <span>{combSentence}</span></h4>
                        <span className="cash5-game__selection-label">({t("From")} 0-9)</span>
                        <div className="cash5-game__selection-pad">
                            {
                                [...Array(10)].map((elem, index) => <button className="cash5-game__pad-num" onClick={() => addNumber(index)}>{index}</button>)
                            }
                        </div>
                        <div className="cash5-game__chosen-section">
                            <a href="#" onClick={(event) => generateRandom(event)} className="cash5-game__random"><FontAwesomeIcon className="icon" icon={faShuffle} /><span>{t("Choose Randomly")}</span></a>
                            <a href="#" onClick={(event) => clearSelection(event)} className="cash5-game__clear-filter"><FontAwesomeIcon className="icon" icon={faXmark} /><span>{t("Clear")}</span></a>
                            <div className="cash5-game__chosen-numbers">
                                {comb}
                            </div>
                        </div>

                    </div>

                    <div className="cash5-game__stake-section">
                        <span className="cash5-game__stake-title">{t("Your stake")}</span>
                        <span className="cash5-game__stake-change" onClick={decreaseStake}>
                            -
                        </span>
                        <div className="cash5-game__stake-value">{stake} {process.env.REACT_APP_CURRENCY}</div>
                        <span className="cash5-game__stake-change" onClick={increaseStake}>
                            +
                        </span>
                        <span className="cash5-game__price-title">{t("Total price")}</span>
                        <div className="cash5-game__total-price">{price} {process.env.REACT_APP_CURRENCY}</div>
                        <div className="cash5-game__price-note">{t("Total price includes your stake and combination if available")}</div>
                    </div>

                    <button className="cash5-game__button-purchase" onClick={purchase}>{t("Purchase")}</button>
                    {loader}
                    {statuscall && statuscall == 1 ?
                        <Returnmessage content={ticketMessage} type={"success"} />
                        : statuscall == 0 ?
                            <Returnmessage content={ticketMessage} type={"error"} />
                            : ''
                    }
                </>
                : <Nogame />}
        </div>
    );
}
