import React, { useEffect } from "react";
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from "../../components/Header/Header-old";
import Footer from "../../components/Footer/Footer";
import Sidemenu from "../../components/Sidemenu/Sidemenu";
import Countdown from "../../components/Countdown/Countdown";
import Play from "./Play";
import Howtoplay from "./Howtoplay";
import Howtowin from "./Howtowin";
import Tickets from "../../components/Tickets/Tickets";
import { instalottoDraws } from "../../Api";
import Nogame from "../../components/Nogame/Nogame";
import ReactGA from "react-ga";

const gameType = 'Instaloto';

const pages = [{ title: 'play', name: 'play', content: <Play /> },
{ title: 'how to play', name: 'howtoplay', content: <Howtoplay /> },
{ title: 'How to win', name: 'How to win', content: <Howtowin /> },
{ title: 'purchases', name: 'purchases', content: <Tickets gameType={gameType} pageName="purchases" /> },
{ title: 'my winnings', name: 'winnings', content: <Tickets gameType={gameType} pageName="winnings" /> },
{ title: 'winners', name: 'winners', content: <Tickets gameType={gameType} pageName="winners" /> }];

export default function Instaloto() {
    useEffect(() => {
        ReactGA.pageview('/instaMoja');
    }, [])
    const isAuthenticated = useSelector(state => state.auth.token);
    let page;
    let [searchParams, setSearchParams] = useSearchParams();
    let tab = searchParams.get('page')

    if (tab) {
        switch (tab) {
            case 'play':
                page = <Play />;
                break;
            case 'howtoplay':
                page = <Howtoplay />;
                break;
            case 'howtowin':
                page = <Howtowin />;
                break;
            case 'purchases':
                {
                    if (isAuthenticated != null) {
                        page = <Tickets gameType={gameType} pageName="purchases" />;
                    }
                    else {
                        page = <Nogame />;
                    }
                }
                break;
            case 'winnings':
                if (isAuthenticated != null) {
                    page = <Tickets gameType={gameType} pageName="winnings" />;
                }
                else {
                    page = <Nogame />;
                }
                break;
            case 'winners':
                page = <Tickets gameType={gameType} pageName="winners" />;
                break;
            default:
                page = <Play />;
        }
    }

    return (
        <>
            <Header />
            <div className="gameinsta-container">
                <Countdown url={instalottoDraws(false, 0, 1)} gameType={gameType} />
                <Sidemenu page={page} pages={pages} gameType={gameType} />
            </div>
            <Footer />
        </>
    );
}
