import React, { useState } from 'react';
import Tickets from '../Tickets/Tickets';
import { useTranslation } from "react-i18next";

export default function PromoWallet() {

    const { t } = useTranslation('translations');

    const [balance, setBalance] = useState(0);
    const [time, setTime] = useState('');
    const [amount, setAmount] = useState(0);

    return (
        <div className='account-promo'>
            <div className='account-promo-container'>
                <div className='account-promo__title'>{t("Promo Wallet")}</div>
                <div className='account-promo__balance'>{balance.toFixed(2)}TZ</div>
                <div className='account-promo__terms'>{t("Terms & Conditions Apply")}</div>
                <div className='account-promo__amount-container'>
                    <div className='account-promo__amountLabel'>{t("Amount to spend from wallet")}</div>
                    <div className='account-promo__amountValue'>{amount}</div>
                </div>
                <Tickets pageName='promo' setBalance={setBalance} setTime={setTime} setAmount={setAmount} headerVisible={false} />
            </div>
        </div>
    );
}