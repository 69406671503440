import React, { useState, useEffect } from "react";
import axios from "axios";
import HeaderOld from "../../components/Header/Header-old";
import Footer from "../../components/Footer/Footer";
import Staticpages from "../pages/Staticpages";
import Returnmessage from "../../components/Returnmessage/Returnmessage";
import Loader from "../../components/Loader/Loader";
import { resetPasswordApi } from "../../Api";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";

export default function Resetpass() {

	const { t } = useTranslation('translations');

	const [oldpassword, setoldpassword] = useState("");
	const [newpassword, setnewpassword] = useState("");
	const [confirmpassword, setconfirmpassword] = useState("");
	const [loading, setloading] = useState(null);
	const [status, setstatus] = useState("");
	const [responseMessage, setresponseMessage] = useState("");

	useEffect(() => {
		ReactGA.pageview('/resetpassword');
	}, [])

	useEffect(() => {
		if (responseMessage) {
			setloading(null);
		}
	}, [status])

	const oldpasschange = (e) => {
		e.preventDefault();
		const { name, value } = e.target;
		setoldpassword(e.target.value);
	};

	const newpasschange = (e) => {
		e.preventDefault();
		const { name, value } = e.target;
		setnewpassword(e.target.value);
	};

	const confirmpasschange = (e) => {
		e.preventDefault();
		const { name, value } = e.target;
		setconfirmpassword(e.target.value);
	};

	const Resetpass = (e) => {
		e.preventDefault();
		setloading(<Loader />);

		const authData = {
			oldPassword: oldpassword,
			newPassword: newpassword,
			confirmPassword: confirmpassword,
		};
		let url = resetPasswordApi(localStorage.getItem("token"));

		axios
			.patch(url, authData)
			.then((response) => {

				setloading(null);
				if (response.data.status === 1) {
					setloading(null);
					setstatus(response.data.status);
					setresponseMessage(response.data.message);
				} else {
					setloading(null);
					setstatus(response.data.status);
					setresponseMessage(response.data.message);
				}
			})
			.catch((err) => {
				setloading(null);
				setstatus(0);
				setresponseMessage(err.message);
			});
	};


	let resetpassword = (
		<section className="userpage-container">
			<form onSubmit={Resetpass}>
				<div></div>
				<h5 className="userpage-container-title">{t("Reset Password")}</h5>
				<label className="userpage-container-subtitle">
					{t("Old Password")}
				</label>
				<br />
				<input
					name="oldpassword"
					value={oldpassword}
					onChange={oldpasschange}
					required
					type="password"
					className="userpage-container-inputfield"
				/>
				<label className="userpage-container-subtitle">
					{t("New Password")}
				</label>
				<br />
				<input
					name="newpassword"
					value={newpassword}
					onChange={newpasschange}
					required
					type="password"
					className="userpage-container-inputfield"
				/>
				<label className="userpage-container-subtitle">
					{t("Confirm Password")}
				</label>
				<br />
				<input
					name="confirmpassword"
					value={confirmpassword}
					onChange={confirmpasschange}
					required
					type="password"
					className="userpage-container-inputfield"
				/>
				<button className="form-forgetbtn-submit" type="submit">
					{t("Submit")}
				</button>
			</form>
			{loading}
			{status && status == 1 ?
				<Returnmessage content={responseMessage} type={"success"} />
				: status == 0 ?
					<Returnmessage content={responseMessage} type={"error"} />
					: ''
			}
		</section>
	);

	return (
		<div className="staticpages-backgroundColor">
			<HeaderOld />
			<Staticpages title="account" text={resetpassword} />
			<Footer />
		</div>
	);
}
