import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector, useDispatch } from 'react-redux';
import * as actions from "../../store/actions/index";
import HeaderOld from "../../components/Header/Header-old";
import Footer from "../../components/Footer/Footer";
import Staticpages from "../pages/Staticpages";
import Returnmessage from "../../components/Returnmessage/Returnmessage";
import Loader from "../../components/Loader/Loader";
import { Navigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { resendVerificationCode } from "../../Api";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";

export default function Verifyphonenmbr() {

	const { t } = useTranslation('translations');

	const [phonenumber, setphonenumber] = useState({
		code: null,
	});
	const [redirect, setRedirect] = useState(false);
	const dispatch = useDispatch();
	const [resendMessage, setResendMessage] = useState('');
	const [resendLoading, setResendLoading] = useState(false);

	const handlechange = (e) => {
		const { name, value } = e.target;
		setphonenumber({
			...phonenumber,
			[name]: value,
		});
	}
	const verifymessage = useSelector(state => state.auth.regerror);
	const verifyloading = useSelector(state => state.auth.verloading);
	const hpRedirect = useSelector(state => state.auth.hpRedirect);

	useEffect(() => {
		ReactGA.pageview('/verify');
	}, [])

	function resendCode(event) {
		event.preventDefault();
		console.log('resend');
		setResendLoading(true);
		let msisdn = localStorage.getItem('unverified_msisdn');
		let token = localStorage.getItem('unverified_token');
		axios.put(resendVerificationCode(token, msisdn))
			.then(response => {
				setResendLoading(false);
				setResendMessage(<div className="alert success">{response.data.message}</div>)
			})
			.catch(err => {

				setResendLoading(false);
				setResendMessage(<div className="alert warning">{err.message}</div>)

			});
	};

	const Verifyphone = (e) => {
		e.preventDefault();
		dispatch(actions.verifyMsisdn(phonenumber.code));
	}

	let message = null;
	if (verifymessage) {
		message = <Returnmessage content={verifymessage} type={"error"} />;
	}

	let loading = false;
	if (verifyloading) {
		loading = <Loader />;
	}
	useEffect(() => {
		if (hpRedirect) {
			setRedirect(true);
		}
	}, [hpRedirect]);

	let userphone = (
		<section className="userpage-container">
			<form onSubmit={Verifyphone}>
				<div></div>
				<h5 className="userpage-container-title">{t("Verify phone number")}</h5>
				<label className="userpage-container-subtitle">
					{t("Phone number")}
				</label>
				<br />
				<input
					name="code"
					value={phonenumber.code}
					onChange={handlechange}
					required
					type="number"
					className="userpage-container-inputfield"
				/>
				<div className="resendpin">
					<a href="#" className="register-popup__link" onClick={resendCode}>{t("Resend pin")}</a>
					{resendLoading ? <div className="loading-container">
						<FontAwesomeIcon className="icon" icon={faSpinner} spin />
					</div> : null}
					{resendMessage ? resendMessage : null}
				</div>
				<button className="form-forgetbtn-submit" type="submit">
					{t("Submit")}
				</button>
			</form>
			<br />
			{loading}
			{message}
		</section>
	);
	return (
		<div className="staticpages-backgroundColor">
			<HeaderOld />
			<Staticpages title="account" text={userphone} />
			{redirect ? <Navigate
				to={{
					pathname: "/",
				}}
			/>
				: ''}
			<Footer />
		</div>
	);
}
